import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'
import { AjaxError } from 'rxjs/ajax'
import { saveAs } from 'file-saver'
import intersection from 'lodash/intersection'
import { parse, isValid } from 'date-fns'
import { cross, rollups } from 'd3-array'
import Graph from 'graphology'
import { UNSET_KEY } from './consts'

/**
 * Get a translated value according to default language given
 *
 * @param value {Record<string, any> | undefined}
 * @param defaultLang {'en' | 'ar'}
 * @returns any
 */
export function getInLang(value, defaultLang = 'en') {
  if (!value) {
    return null
  }
  const keys = Object.keys(value)
  const otherLanguages = keys.filter((d) => d !== 'en' && d !== 'ar')
  const fallbackLanguage = otherLanguages.length ? otherLanguages[0] : keys[0]

  if (defaultLang === 'en') {
    return value['en'] ?? value[fallbackLanguage]
  }
  return value['ar'] ?? value[fallbackLanguage]
}

export function invertName(label, language) {
  const chunks =
    language === 'ar'
      ? label.split('،').map((d) => d.trim())
      : label.split(',').map((d) => d.trim())

  const name = chunks.slice(0, 2).reverse().join(' ')

  const rest =
    language === 'ar'
      ? chunks.slice(2, chunks.length).join('، ')
      : chunks.slice(2, chunks.length).join(', ')

  return language === 'ar' ? name + '، ' + rest : name + ', ' + rest
}

/**
 * @param {Record<string, string>} obj
 * @param {string} prop
 * @param {'en' | 'ar'} language
 * @returns string
 */
export function getStrPropInLang(obj, prop, language = 'en') {
  if (!obj) {
    return null
  }
  if (language === 'en') {
    return (obj[`${prop}_en`] || obj[`${prop}_ar`]) ?? null
  } else {
    return (obj[`${prop}_ar`] || obj[`${prop}_en`]) ?? null
  }
}

const DATE_FORMATS = ['dd/MM/yyyy', 'MM/yyyy', 'yyyy']

/**
 * @param {string} rawDate
 * @returns {Date|null}
 */
export function multiDateParse(rawDate) {
  if (!rawDate) {
    return null
  }
  for (let i = 0; i < DATE_FORMATS.length; i++) {
    const date = parse(rawDate, DATE_FORMATS[i], new Date())
    if (isValid(date)) {
      return date
    }
  }
  return null
}

/**
 * @param {string} value
 * @param {number[]} range
 */
function inYearsRange(value, range) {
  const date = multiDateParse(value)
  if (date === null) {
    return false
  }
  const year = date.getFullYear()
  return year >= range[0] && year <= range[1]
}

/**
 * @param records {any[]}
 * @param filters {Record<string, string[] | number[]>}
 * @returns any[]
 */
export function filterRecords(records, filters) {
  const keys = Object.keys(filters)
  return records.filter((record) => {
    return !keys.some((field) => {
      const search = filters[field]
      if (search.length === 0) {
        return false
      }
      if (search.includes(UNSET_KEY)) {
        const value = getInLang(record.data[field])
        const isUnset = !value
        if (search.length > 1) {
          // To pass the condition is NOT
          // or the value is unset or there are an intersection
          // for others part of filter
          return !(
            isUnset ||
            intersection(
              value,
              search.filter((k) => k !== UNSET_KEY)
            ).length > 0
          )
        } else {
          // Only "unset" filter check only for NOT unset
          return !isUnset
        }
      }
      if (typeof search[0] === 'string') {
        return intersection(getInLang(record.data[field]), search).length === 0
      }
      return !inYearsRange(getInLang(record.data[field]), search)
    })
  })
}

export function stackeEvents(records, START_YEAR, END_YEAR) {
  const lanesData = []
  let stack = []
  records.slice().forEach((e) => {
    const lane = stack.findIndex(
      (s) =>
        s[END_YEAR].getTime() <= e[START_YEAR].getTime() &&
        s[START_YEAR].getTime() < e[START_YEAR].getTime()
    )
    const yIndex = lane === -1 ? stack.length : lane
    lanesData.push({
      ...e,
      yIndex,
    })
    stack[yIndex] = e
  })
  return lanesData
}

export function makeNetwork(records, options, fieldsInfo, language) {
  const fieldsDict = {
    network: 'expanded_relations',
    facet: 'data',
    geo: 'data',
    text: 'data',
  }

  const filtered = records
    .map((d) => {
      let sources
      if (fieldsInfo.connectField !== 'network') {
        let s = getInLang(
          d[fieldsDict[fieldsInfo.connectField]][options.connectField]
        )

        s = Array.isArray(s) ? s : [s]

        sources = s.map((d) => {
          return { id: d, label: { en: d } }
        })
      } else {
        sources =
          d[fieldsDict[fieldsInfo.connectField]][options.connectField].results
      }

      let targets
      if (fieldsInfo.targetField !== 'network') {
        let s = getInLang(
          d[fieldsDict[fieldsInfo.targetField]][options.targetField],
          language
        )

        s = Array.isArray(s) ? s : [s]

        targets = s.map((d) => {
          return { id: d, label: { [language]: d } }
        })
      } else {
        targets =
          d[fieldsDict[fieldsInfo.targetField]][options.targetField].results
      }

      return { sources, targets, label: getInLang(d.label, language) }
    })
    .filter(
      (d) =>
        d.sources && d.targets && d.sources.length > 0 && d.targets.length > 0
    )

  const edges = filtered
    .map((d) => {
      const edge = cross(d.sources, d.targets)
      const out = edge.map((e) => [...e, d.label])
      return out
    })
    .flat()

  const groupsEdges = rollups(
    edges,
    (v) => {
      return { weight: v.length, label: v.map((d) => d[2]).join('<br>') }
    },
    (d) => d[0].id + '_' + d[1].id
  ).map((d) => {
    const [source, target] = d[0].split('_')
    return {
      source: source,
      target: target,
      attributes: d[1],
    }
  })

  const nodesDict = {}

  filtered.forEach((d) => {
    d.sources.forEach((s) => {
      nodesDict[s.id] = { ...s, type: options.connectField }
    })
    d.targets.forEach((s) => {
      nodesDict[s.id] = { ...s, type: options.targetField }
    })
  })

  const nodes = Object.entries(nodesDict).map((d) => {
    let label = getInLang(d[1].label, language)
    if (d[1].item === 'Person') {
      label =
        language === 'ar'
          ? label.split(' ،').reverse().join(' ')
          : label.split(', ').reverse().join(' ')
    }
    return {
      key: d[1].id,
      attributes: { ...d[1], label: label },
    }
  })

  const graph = new Graph({ multi: true })

  graph.import({
    attributes: { name: 'graph' },
    nodes: nodes,
    edges: groupsEdges,
  })

  const nodesAnnotated = graph.nodes().map((node) => {
    return {
      id: node,
      data: graph.getNodeAttributes(node),
      inDegree: graph.inDegree(node),
      degree: graph.degree(node),
      outDegree: graph.outDegree(node),
      neighbors: graph.neighbors(node),
    }
  })

  //console.log(nodes)

  return {
    nodes: nodesAnnotated,
    links: groupsEdges,
  }
}

export default function saveSvgAsImage(svgElement, fileName) {
  const svgString = new XMLSerializer().serializeToString(svgElement)
  const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
  saveAs(svg, fileName)
}

export const arrayze = (a) => (Array.isArray(a) ? a : [a])

export const transformErrorForForm = (error) => {
  let errorData
  if (error instanceof AjaxError && isPlainObject(error.response)) {
    errorData = error.response
  } else if (isPlainObject(get(error, 'response.body'))) {
    errorData = error.response.body
  }
  // TODO: Better joins of errors...
  if (errorData) {
    return mapValues(errorData, (listOfErrors) =>
      arrayze(listOfErrors).join(',')
    )
  }
  return {}
}
