import { Field } from 'formik'
import * as yup from 'yup'
import CheckField from '../../Form/CheckField'
import InputField from '../../Form/InputField'
import ColorField from '../../Form/ColorField'

export const defaultValues = {
  show_contextual_timeline: false,
  compact_view: false,
  group_by_event_type: false,
  items_color: '#4f7c4c',
  active_filters: [],
}

export const ValidationSchema = {
  items_color: yup.string().required().label('Items Color'),
  show_contextual_timeline: yup.boolean(),
  group_by_event_type: yup.boolean(),
}

/**
 * @param {{ ns: string }} props
 */
export function FieldSet({ ns }) {
  return (
    <div>
      <Field
        name={`${ns}.items_color`}
        component={ColorField}
        label="Items Color"
        className="mb-3"
      />

      <Field
        name={`${ns}.group_by_event_type`}
        component={CheckField}
        label="Group by event type"
        className="mb-3"
      />
      <Field
        name={`${ns}.compact_view`}
        component={CheckField}
        label="Compact view"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_contextual_timeline`}
        component={CheckField}
        label="Show contextual timeline"
        className="mb-3"
      />
    </div>
  )
}
