import {
  memo,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import MapGL, {
  Marker,
  Popup,
  NavigationControl,
  MapContext,
} from '@urbica/react-map-gl'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import Cluster from '@urbica/react-map-gl-cluster'
import { scaleLinear, scaleSqrt } from 'd3-scale'
import { max } from 'd3-array'
import ItemsWidget from '../../Widget/ItemsWidget'
import FilterFieldPickerWidget from '../../Widget/FilterFieldPickerWidget'
import FilterFacetFieldsWidget from '../../Widget/FilterFacetFieldsWidget'
import { LngLatBounds } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { filterRecords, getInLang } from '../../../utils'
import FilterTimeFieldsWidget from '../../Widget/FilterTimeFieldsWidget'
import {
  DEFAULT_MAP_CENTER,
  MAPBOX_ACCESS_TOKEN,
  MAP_STYLES,
} from '../../../consts'
import { useVizFilters } from '../../../hooks/filters'
import MapPopupContent, { LIMIT_RECORDS_IN_POPUP } from '../MapPopupContent'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import styles from './MapViz.module.scss'
import { useAuthState } from 'use-eazy-auth'
import logo from '../../../assets/PalREAD_watermark.png'

const markerStyle = {
  width: 10,
  height: 10,
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  cursor: 'pointer',
}

const markerClusterStyle = {
  cursor: 'pointer',
  // width: 20,
  // height: 20,
  color: '#fff',
  borderRadius: '100%',
  textAlign: 'center',
  border: '1px solid white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'visible',
  transition: 'all 0.2s linear',
}

/**
 * @typedef {{
 *    items_color: string
 *    cluster_items: boolean
 *    show_contextual_timeline: boolean
 *    map_style: string
 *    active_filters: string[]
 * }} MapVizOptions
 */

/**
 * @param {{
 *  records: any[]
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 *  query: QueryEditorState
 *  options: MapVizOptions
 *  setOptions?(options: MapVizOptions | ((options: MapVizOptions) => MapVizOptions)): void
 *  language: 'en' | 'ar'
 * }} props
 */
function MapViz(
  { records, options, meta, language, query, title, setOptions },
  ref
) {
  const clusterRef = useRef()
  const mapRef = useRef()

  const geoField = useMemo(() => {
    const fields = query.fields
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (meta.fields[field] === 'geo') {
        return field
      }
    }
    // NOTE: In the case of no fields in meta (no rows)
    // avoid throw the error
    if (Object.keys(meta.fields).length > 0) {
      throw new Error('Bad configutation for MapViz no geo field found.')
    }
  }, [meta.fields, query.fields])

  const [
    { filters, filterableFields },
    { setFilters, addFilter, removeFilter },
  ] = useVizFilters(meta.fields, options, setOptions)

  const filteredRecords = useMemo(
    () => filterRecords(records, filters),
    [records, filters]
  )

  const [viewport, setViewport] = useState({
    latitude: DEFAULT_MAP_CENTER.latitude,
    longitude: DEFAULT_MAP_CENTER.longitude,
    zoom: 4,
  })

  const [maxRadius, setMaxRadius] = useState(20)

  const radiusScale = useMemo(() => {
    return scaleSqrt().domain([1, maxRadius]).range([20, 40])
  }, [maxRadius])

  const changeMaxRadius = useCallback(() => {
    if (!options.cluster_items) return
    const cluster = clusterRef.current.getCluster()
    const map = mapRef.current.getMap()
    const bounds = map.getBounds()
    const zoom = map.getZoom()
    const geojsonCluster = cluster.getClusters(
      [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat],
      Math.round(zoom)
    )
    setMaxRadius(max(geojsonCluster, (d) => d.properties.point_count))
  }, [options.cluster_items])

  const onClickCluster = (cluster) => {
    const { clusterId, longitude, latitude } = cluster

    const supercluster = clusterRef.current.getCluster()
    const zoom = supercluster.getClusterExpansionZoom(clusterId)

    setViewport({
      latitude,
      longitude,
      zoom,
    })
  }

  const togglePopup = useCallback((payload) => {
    setPopup((p) => (p?.id === payload.id ? null : payload))
  }, [])

  const [popup, setPopup] = useState(null)

  const ClusterMarker = ({ longitude, latitude, pointCount, clusterId }) => (
    <Marker longitude={longitude} latitude={latitude}>
      <div
        onClick={() => {
          const cluster = clusterRef.current.getCluster()
          const records = cluster
            .getLeaves(clusterId, LIMIT_RECORDS_IN_POPUP)
            .map((l) => l.properties.props.record)
          togglePopup({
            id: clusterId,
            latitude,
            longitude,
            records,
            offsetY: radiusScale(records.length) / 2,
          })
        }}
        style={{
          background: options.items_color,
          width: radiusScale(pointCount) ? radiusScale(pointCount) : 0,
          height: radiusScale(pointCount) ? radiusScale(pointCount) : 0,
        }}
        className={styles.cluster}
      >
        {pointCount}
      </div>
    </Marker>
  )

  const markersElements = useMemo(
    () =>
      filteredRecords.map((record) => (
        <Marker
          onClick={() => {
            togglePopup({
              latitude: record.geo[geoField].lat,
              longitude: record.geo[geoField].lng,
              records: [record],
              id: record.id,
              offsetY: 5,
            })
          }}
          key={record.id}
          record={record}
          longitude={record.geo[geoField].lng}
          latitude={record.geo[geoField].lat}
        >
          <div
            style={{
              ...markerStyle,
              background: options.items_color,
            }}
          />
        </Marker>
      )),
    [filteredRecords, geoField, options.items_color, togglePopup]
  )

  useEffect(() => {
    if (records.length === 0) {
      return
    }
    const bounds = new LngLatBounds()
    records.forEach((record) => {
      bounds.extend([record.geo[geoField].lng, record.geo[geoField].lat])
    })
    const map = mapRef.current.getMap()
    map.once('load', () => {
      map.fitBounds(bounds, { padding: 100 })
      changeMaxRadius()
    })
  }, [changeMaxRadius, geoField, records])

  useImperativeHandle(ref, () => ({
    exportViz: () => {
      const container = mapRef.current._container.current
      html2canvas(container, {
        ignoreElements: (element) => {
          const klist = element.classList
          return (
            klist.contains('mapboxgl-ctrl') || klist.contains('pal-viz-widgets')
          )
        },
      }).then((canvas) => {
        const logoWidth = 80
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.onload = () => {
          ctx.globalAlpha = 0.5
          ctx.drawImage(
            image,
            container.offsetLeft + 20,
            container.offsetTop +
              canvas.height / window.devicePixelRatio -
              (logoWidth * image.height) / image.width -
              20,
            logoWidth,
            (logoWidth * image.height) / image.width
          )

          canvas.toBlob((blob) => {
            saveAs(
              blob,
              getInLang(title, language)
                ? getInLang(title, language) + '.png'
                : 'map.png'
            )
          })
        }
        image.src = logo
      })
    },
  }))

  const { authenticated } = useAuthState()

  const legacyMapStyle = function (value) {
    if (value === 'light' || value === 'dark') {
      return `mapbox://styles/mapbox/${value}-v10`
    } else {
      return MAP_STYLES[value]
    }
  }

  return (
    <div className="w-100 h-100 d-flex bg-background-pr">
      <div className="flex-grow-1 flex-shrink-1 d-flex flex-column">
        <div className={classNames(styles.evolutionContainer)}>
          <MapGL
            preserveDrawingBuffer={true}
            style={{ width: '100%', height: '100%' }}
            ref={mapRef}
            mapStyle={legacyMapStyle(options.map_style)}
            accessToken={MAPBOX_ACCESS_TOKEN}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            maxZoom={16}
            viewportChangeMethod={'easeTo'}
            onViewportChange={(viewport) => {
              changeMaxRadius()
              setViewport(viewport)
              setPopup(null)
            }}
          >
            {popup && (
              <Popup
                longitude={popup.longitude}
                latitude={popup.latitude}
                closeButton={false}
                closeOnClick={false}
                offset={popup.offsetY}
              >
                <MapPopupContent
                  showItemLink={authenticated}
                  records={popup.records}
                  language={language}
                  field={query.fields[0]}
                />
              </Popup>
            )}
            {options.cluster_items ? (
              <Cluster
                ref={clusterRef}
                key={options.items_color}
                radius={40}
                extent={512}
                nodeSize={64}
                component={(cluster) => (
                  <ClusterMarker {...cluster}></ClusterMarker>
                )}
              >
                {markersElements}
              </Cluster>
            ) : (
              markersElements
            )}
            <NavigationControl
              data-html2canvas-ignore="1"
              visualizePitch={true}
              showZoom={true}
              showCompass={true}
              position="top-left"
            />
          </MapGL>
        </div>
        <div className="flex-grow-0 flex-shrink-0">
          <FilterTimeFieldsWidget
            meta={meta}
            records={records}
            filters={filters}
            onFiltersChange={setFilters}
            onFilterRemoved={setOptions ? removeFilter : null}
          />
        </div>
      </div>
      <div className={classNames(styles.widgetContainerRight)}>
        {setOptions && (
          <FilterFieldPickerWidget
            fields={filterableFields}
            filters={filters}
            onFilterPicked={addFilter}
            onFiltersChange={setFilters}
          />
        )}
        <ItemsWidget
          showItemLink={authenticated}
          records={records}
          filteredRecords={filteredRecords}
          language={language}
        />
        <FilterFacetFieldsWidget
          meta={meta}
          records={records}
          filters={filters}
          onFiltersChange={setFilters}
          onFilterRemoved={setOptions ? removeFilter : null}
        />
      </div>
    </div>
  )
}

export default memo(forwardRef(MapViz))
