import uniq from 'lodash/uniq'
import isDeepEqual from 'fast-deep-equal'
import { useCallback, useMemo, useReducer } from 'react'
import { useEditorSchema } from '../../../hooks/schema'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import DropdownSelect from '../../DropdownSelect'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { QueryBuilderContainer } from '../QueryBuilderContainer'

/**
 * @typedef {{
 *  queries: Query[]
 *  evolutionField: string | null
 *  items: string[]
 *  basedOnField: string | null
 * }} TemporalQueryState
 *
 * @param {TemporalQueryState} state
 * @param {
    QueryActions
    | { type: 'UPDATE_EVOLUTION_FIELD', payload: string }
    | { type: 'UPDATE_ITEMS', payload: string[] }
    | { type: 'UPDATE_BASED_ON_FIELD', payload: string }
  } action
 * @returns {TemporalQueryState}
 */
function temporalVizReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_EVOLUTION_FIELD':
      return {
        ...state,
        items: [],
        basedOnField: null,
        evolutionField: action.payload,
      }
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'UPDATE_BASED_ON_FIELD':
      return {
        ...state,
        basedOnField: action.payload,
      }
    case 'RESET':
      return defaultState
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

/**
 * @type TemporalQueryState
 */
const defaultState = {
  basedOnField: null,
  items: [],
  evolutionField: null,
  queries: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {TemporalQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function TemporalQueryEditor({ initalState, onNext, vizId }) {
  const { fieldsByType } = useEditorSchema()
  const [state, dispatch] = useReducer(temporalVizReducer, initalState, init)

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const setEvolutionField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_EVOLUTION_FIELD',
        payload: field,
      }),
    []
  )
  const setBaseOnField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_BASED_ON_FIELD',
        payload: field,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const evolutionFields = useMemo(
    () => uniq(fieldsByType['facet'].map((f) => f.label)).sort(),
    [fieldsByType]
  )

  const evoItems = useMemo(
    () =>
      uniq(
        fieldsByType['facet']
          .filter((f) => f.label === state.evolutionField)
          .map((f) => f.item)
          .sort()
      ),
    [state.evolutionField, fieldsByType]
  )

  const dateFields = useMemo(
    () =>
      uniq(
        fieldsByType['date']
          .filter((f) => evoItems.includes(f.item))
          .map((f) => f.label)
          .sort()
      ),
    [evoItems, fieldsByType]
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)
  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      fields: [state.basedOnField, state.evolutionField].filter(Boolean),
    }),
    [state, allowedQueryFields]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {
              basedOnField: state.basedOnField,
              evolutionField: state.evolutionField,
            },
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <DropdownSelect
            label="Show the evolution of"
            className="mb-2"
            value={state.evolutionField}
            onChange={setEvolutionField}
            options={evolutionFields}
            placeholder="select a property"
          />
          {state.evolutionField && (
            <DropdownSelect
              label="Based on"
              className="mb-2"
              value={state.basedOnField}
              onChange={setBaseOnField}
              options={dateFields}
              placeholder="select a property"
            />
          )}
          {state.basedOnField && (
            <MultiBoxSelect
              label="Of the following items"
              values={state.items}
              options={evoItems}
              onChange={setItems}
            />
          )}
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
