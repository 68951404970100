import { createContext, useContext, useEffect, useRef } from 'react'
import { deps, rj, useRunRj } from 'react-rocketjump'
import api from '../api'

// the real one is in my garage
const BigCaheOfFieldValuesContext = createContext(null)

export function BigCaheOfFieldValues({ children }) {
  const bigCacheRef = useRef({})
  return (
    <BigCaheOfFieldValuesContext.Provider value={bigCacheRef}>
      {children}
    </BigCaheOfFieldValuesContext.Provider>
  )
}

export const FieldValuesState = rj({
  name: 'FieldValues',
  effectCaller: 'configured',
  effect: (t) => (field) =>
    api
      .auth(t)
      .mapResponse((r) => ({
        field,
        values: r.response,
      }))
      .get(`/api/values/${field}`),
})

/**
 * @param {string} field
 * @returns {string[] | null}
 */
export function useFieldValues(field) {
  const bigCacheRef = useContext(BigCaheOfFieldValuesContext)

  let values = null
  let runForDeps = [field]

  // Run API when no parent big cache or is empty
  if (bigCacheRef && bigCacheRef.current[field]) {
    values = bigCacheRef.current[field]
    runForDeps = [deps.maybe()]
  }

  const [state] = useRunRj(FieldValuesState, runForDeps)
  if (!values) {
    values = state.data?.values ?? null
  }

  useEffect(() => {
    if (state.data && bigCacheRef) {
      bigCacheRef.current[state.data.field] = state.data.values
    }
  }, [state.data, bigCacheRef])

  return values
}
