import classNames from 'classnames'
import { useMemo, useState } from 'react'
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern'
import ContextualTimelineChart from './ContextualTimelineChart'
import styles from './ContextualTimeline.module.scss'

export default function ContextualTimeline(props) {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div className="bg-white w-100 d-flex flex-column">
      <div
        className={classNames(
          'bg-dark p-2 d-flex align-items-center fw-bold text-white text-uppercase',
          {
            'border-bottom': !collapsed,
          }
        )}
      >
        <div
          className="me-2"
          role="button"
          onClick={() => setCollapsed((c) => !c)}
        >
          {collapsed ? (
            <i className="bi-chevron-down"></i>
          ) : (
            <i className="bi-chevron-up"></i>
          )}
        </div>
        <div className={styles.label}>Contextual timeline</div>
      </div>
      {!collapsed && (
        <div className="w-100 overflow-auto" style={{ height: 250 }}>
          <ParentSize>
            {({ width }) =>
              width > 0 ? (
                <ContextualTimelineChart {...props} width={width} />
              ) : null
            }
          </ParentSize>
        </div>
      )}
    </div>
  )
}
