import { Field } from 'formik'
import * as yup from 'yup'
import CheckField from '../../Form/CheckField'
import ColorField from '../../Form/ColorField'

export const defaultValues = {
  show_general_information: true,
  show_events_map: true,
  show_events_timespans: true,
  show_literary_production_timeline: true,
  main_color: '#4f7c4c',
}

export const ValidationSchema = {
  show_general_information: yup.boolean(),
  show_events_map: yup.boolean(),
  show_events_timespans: yup.boolean(),
  show_literary_production_timeline: yup.boolean(),
  main_color: yup.string().required().label('Main Color'),
}

/**
 * @param {{ ns: string }} props
 */
export function FieldSet({ ns }) {
  return (
    <div>
      <Field
        name={`${ns}.main_color`}
        component={ColorField}
        label="Main Color"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_general_information`}
        component={CheckField}
        label="General information"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_events_map`}
        component={CheckField}
        label="Events Map"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_events_timespans`}
        component={CheckField}
        label="Events timespans"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_literary_production_timeline`}
        component={CheckField}
        label="Literary production timeline"
        className="mb-3"
      />
    </div>
  )
}
