import { memo } from 'react'
import { Dropdown, ButtonGroup, Button } from 'react-bootstrap'

/**
 * A Dropdown that act like a select
 * @param {{
 *  value: any
 *  options: any[]
 *  label?: string
 *  labelPosition?: 'top' | 'left'
 *  placeholder?: string
 *  onChange: (option: any) => void
 *  className?: string
 *  variant?: import('react-bootstrap/esm/types').ButtonVariant
 *  size?: 'sm' | 'md'
 * }} props
 */
function DropdownSelect({
  value,
  options,
  onChange,
  label,
  isEffective,
  placeholder = '',
  labelPosition = 'top',
  className,
  variant = 'secondary',
  size = 'md',
}) {
  const selectNode = (
    <Dropdown className={className}>
      <Dropdown.Toggle
        variant={variant}
        size={size}
        className="text-start d-flex align-items-center"
      >
        <div className="w-100">{value ?? placeholder}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: 300, overflowY: 'auto' }}>
        {options.map((option) => (
          <Dropdown.Item
            key={option}
            active={option === value}
            onSelect={() => onChange(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
  // When no label return the select unwrap
  if (!label) {
    return selectNode
  }

  if (label) {
    if (labelPosition === 'left') {
      return (
        <div className="d-flex align-items-center">
          <div className="me-3">{label}</div>
          {selectNode}
          {!isEffective && (
            <div className="ms-2 badge bg-warning">
              this filter may not apply
            </div>
          )}
        </div>
      )
    }
    if (labelPosition === 'top') {
      return (
        <div className="mt-3">
          <div className="mb-1">{label}</div>
          {selectNode}
        </div>
      )
    }
  }
}

export default memo(DropdownSelect)
