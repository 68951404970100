import classNames from 'classnames'
import { getInLang } from '../../../utils'
import { OMEKA_ITEMS_URL } from '../../../consts'
import styles from './InfoCardViz.module.scss'

/**
 * @param {{
 * 	records: any[]
 * 	value: any
 * 	onChange(v: any): void
 *  language: 'en' | 'ar'
 * }}
 */
export default function PersonPicker({
  records,
  value,
  onChange,
  language,
  showItemLink,
}) {
  return (
    <div className="h-100 py-3" style={{ overflowY: 'auto' }}>
      <div
        className="text-uppercase bg-dark p-2 mb-0 text-white fw-bold"
        style={{ fontSize: '0.75rem' }}
      >
        {records.length > 1 ? 'profiles' : 'profile'}
      </div>
      <div className="list-group">
        {records.map((person) => (
          <button
            type="button"
            onClick={() => onChange(person)}
            className={classNames(
              'list-group-item list-group-item-action text-center ZaridSerif fs-5',
              styles.profile,
              {
                active: value?.id === person.id,
                [styles.profileActive]: value?.id === person.id,
              }
            )}
            key={person.id}
          >
            {getInLang(person.label, language)}

            <div style={{ fontSize: '0.875rem' }}>
              <span className="text-muted GraphikArabic">{person.item}</span>
              {showItemLink && (
                <a
                  href={OMEKA_ITEMS_URL + person.id}
                  target="_blank"
                  rel="noreferrer"
                  className="ms-1"
                >
                  <i className={classNames('bi-box-arrow-up-right')}></i>
                </a>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}
