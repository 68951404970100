import classNames from 'classnames'
import { VIZ_TYPES } from '../../consts'
import styles from './VizBlocksSelect.module.scss'

/**
 * @param {{
 *  value: any
 *  onChange(v: any): void
 * }} props
 */
export default function VizBlocksSelect({ value, onChange }) {
  return (
    <div className="row">
      {VIZ_TYPES.map((vizType) => (
        <div className="col" key={vizType.value}>
          <div
            className={classNames(
              styles.card,
              'border rounded position-relative',
              {
                'border-primary-pr text-primary-pr': value === vizType.value,
              }
            )}
            onClick={onChange ? () => onChange(vizType.value) : undefined}
          >
            <div className="position-absolute w-100 h-100 d-flex flex-column">
              <div
                className={classNames(
                  styles.top,
                  'flex-grow-1 flex-shrink-1 d-flex align-items-center justify-content-center',
                  {
                    [styles['active']]: value === vizType.value,
                  }
                )}
              >
                <i className={classNames(styles.icon, vizType.icon)}></i>
              </div>
              <h6 className="m-0 p-2 flex-grow-0 flex-shrink-0 GraphikArabic">
                {vizType.label}
              </h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
