import { scaleSequential } from 'd3-scale'
import range from 'lodash/range'
import { memo, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { PALETTE } from '../../consts'

const AwsomePaletteBox = memo(({ interpolator, height = 30 }) => {
  return (
    <div style={{ height, width: '100%' }} className="d-flex">
      {range(9).map((i) => (
        <div
          key={i}
          style={{
            height: '100%',
            width: 100 / 9 + '%',
            backgroundColor: interpolator[9][i],
          }}
        ></div>
      ))}
    </div>
  )
})

/**
 * @param {import("formik").FieldProps} props
 */
export default function PaletteField({
  field,
  label,
  form,
  className,
  placeholder = null,
  variant = 'info',
  size = 'md',
  ...props
}) {
  return (
    <div>
      {label && <label className="mb-2">{label}</label>}
      <Dropdown className={className}>
        <Dropdown.Toggle
          variant={variant}
          size={size}
          className="text-start d-flex align-items-center w-100 bg-white"
        >
          <div className="w-100">
            {field.value ? (
              <AwsomePaletteBox interpolator={PALETTE[field.value]} />
            ) : (
              placeholder
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ maxHeight: 300, overflowY: 'auto', width: '100%' }}
        >
          {Object.keys(PALETTE).map((option) => (
            <Dropdown.Item
              key={option}
              active={option === field.value}
              onSelect={() => form.setFieldValue(field.name, option)}
            >
              <AwsomePaletteBox interpolator={PALETTE[option]} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
