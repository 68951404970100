import classNames from 'classnames'
import { useMemo, useState } from 'react'
import differenceBy from 'lodash/differenceBy'
import { OMEKA_ITEMS_URL } from '../../consts'
import { getInLang } from '../../utils'

/**
 * @param {{
 * records: any[]
 * filteredRecords: any[]
 * language: 'ar' | 'en'
 * showItemLink?: boolean
 * }} props
 */
export default function ItemsWidget({
  records,
  filteredRecords,
  language,
  showItemLink = true,
}) {
  const [collapsed, setCollapsed] = useState(false)
  const filteredOutRecords = useMemo(
    () => differenceBy(records, filteredRecords, 'id'),
    [filteredRecords, records]
  )

  return (
    <div className="bg-white w-100 d-flex flex-column">
      <div
        className={classNames(
          'bg-dark p-2 text-white d-flex align-items-center fw-bold text-uppercase',
          {
            'border-bottom': !collapsed,
          }
        )}
      >
        <div
          role="button"
          className="me-2"
          onClick={() => setCollapsed((c) => !c)}
        >
          {collapsed ? (
            <i className="bi-chevron-down"></i>
          ) : (
            <i className="bi-chevron-up"></i>
          )}
        </div>
        <div style={{ fontSize: '0.75rem' }}>
          Items {filteredRecords.length} {' / '} {records.length}
        </div>
      </div>
      {!collapsed && (
        <ul
          className="list-group-flush p-0"
          style={{ overflowY: 'auto', marginBottom: 2, height: 200 }}
        >
          {filteredRecords.map((record) => (
            <li className="list-group-item p-2 text-center" key={record.id}>
              <div>
                {getInLang(record.label, language)}{' '}
                {showItemLink && (
                  <a
                    href={OMEKA_ITEMS_URL + record.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi-box-arrow-up-right"></i>
                  </a>
                )}
              </div>
              <div className="text-secondary">{record.item}</div>
            </li>
          ))}
          {filteredOutRecords.map((record) => (
            <li
              className="list-group-item p-2 text-muted text-end bg-light text-center"
              key={record.id}
            >
              <div>
                {getInLang(record.label, language)}{' '}
                {showItemLink && (
                  <a
                    href={OMEKA_ITEMS_URL + record.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi-box-arrow-up-right"></i>
                  </a>
                )}
              </div>
              <div className="fst-italic text-secondary">{record.item}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
