/**
 * @typedef {{
 *    columns: string[]
 * }} MapVizOptions
 */

import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'
import { unparse } from 'papaparse'
import { saveAs } from 'file-saver'
import { OMEKA_ITEMS_URL } from '../../../consts'
import { useVizFilters } from '../../../hooks/filters'
import { usePaginator } from '../../../hooks/paginator'
import { filterRecords, getInLang, arrayze } from '../../../utils'
import { useAuthState } from 'use-eazy-auth'
import Paginator from '../../Paginator'
import FilterFacetFieldsWidget from '../../Widget/FilterFacetFieldsWidget'
import FilterFieldPickerWidget from '../../Widget/FilterFieldPickerWidget'
import FilterTimeFieldsWidget from '../../Widget/FilterTimeFieldsWidget'
import classNames from 'classnames'
import styles from './ListViz.module.scss'
import SortIcon from '../../SortIcon'

/**
 * @param {{
 *  col: string,
 *  row: any,
 *  language: string
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 * }}
 */
function getColValue({ meta, row, col, language }) {
  if (meta.fields[col] === 'network') {
    const relationData = row.expanded_relations[col]
    if (relationData) {
      return getInLang(relationData.results[0].label, language)
    }
  } else if (['geo', 'text', 'date'].includes(meta.fields[col])) {
    return getInLang(row.data[col], language)
  } else if (meta.fields[col] === 'facet') {
    return arrayze(getInLang(row.data[col], language) ?? []).join(', ')
  }
  return null
}

/**
 * @param {{
 *  records: any[],
 *  language: string,
 *  columns: string[]
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 * }}
 */
function TableListViz({ records, language, columns, meta }) {
  const { authenticated } = useAuthState()
  const mapRow = useCallback(
    (row) => {
      const mRow = { ...row, label: getInLang(row.label, language), data: {} }
      columns.forEach((col) => {
        mRow.data[col] = getColValue({
          meta,
          row,
          col,
          language,
        })
      })
      return mRow
    },
    [columns, language, meta]
  )
  const [
    paginatedRows,
    { page, toggleOrderBy, setPage, numPages, orderByState, setOrderByState },
  ] = usePaginator(records, 30, mapRow)

  // When columns changed erase column that doens't appear in the screen
  useEffect(() => {
    setOrderByState((o) => {
      const keys = Object.keys(o)
      const nextO = { ...o }
      const colsData = columns.map((c) => `data.${c}`)
      keys.forEach((k) => {
        if (['id', 'label', 'type'].includes(k)) {
          return
        }
        if (!colsData.includes(k)) {
          delete nextO[k]
        }
      })
      if (Object.keys(nextO).length === keys.length) {
        return o
      }
      return nextO
    })
  }, [columns, setOrderByState])

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-grow-1 flex-shrink-1 overflow-auto pt-2">
        <table className="table table-striped position-relative">
          <thead>
            <tr>
              <th
                className={classNames(
                  'cursor-pointer text-uppercase sticky-top bg-background-pr text-nowrap',
                  styles.header
                )}
                onClick={() => toggleOrderBy('type')}
              >
                <div className="d-flex justify-content-between w-100">
                  <span className="me-2">Type</span>{' '}
                  <SortIcon direction={orderByState.type} />
                </div>
              </th>
              <th
                className={classNames(
                  'cursor-pointer text-uppercase sticky-top bg-background-pr text-nowrap',
                  styles.header
                )}
                onClick={() => toggleOrderBy('label')}
              >
                <div className="d-flex justify-content-between w-100">
                  <span className="me-2">Label</span>{' '}
                  <SortIcon direction={orderByState.label} />
                </div>
              </th>
              {columns.map((col) => (
                <th
                  key={col}
                  className={classNames(
                    'cursor-pointer text-uppercase sticky-top bg-background-pr text-nowrap',
                    styles.header
                  )}
                  onClick={() => toggleOrderBy(`data.${col}`)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <span className="me-2">{col}</span>{' '}
                    <SortIcon direction={orderByState[`data.${col}`]} />
                  </div>
                </th>
              ))}
              <th
                className={classNames(
                  'cursor-pointer text-uppercase sticky-top bg-background-pr text-nowrap',
                  styles.header
                )}
                onClick={() => toggleOrderBy('id')}
              >
                <div className="d-flex justify-content-between w-100">
                  <span className="me-2">Id</span>{' '}
                  <SortIcon direction={orderByState.id} />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedRows.map((row) => (
              <tr key={row.id}>
                <td>{row.item}</td>
                <td>{row.label}</td>
                {columns.map((col) => (
                  <td key={col}>{row.data[col]}</td>
                ))}
                <td>
                  {row.id}{' '}
                  {authenticated && (
                    <a
                      href={OMEKA_ITEMS_URL + row.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi-box-arrow-up-right"></i>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-grow-0 flex-shrink-0 pt-3">
        <Paginator goToPage={setPage} currentPage={page} numPages={numPages} />
      </div>
    </div>
  )
}

/**
 * @param {{
 *  records: any[]
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 *  query: QueryEditorState
 *  options: MapVizOptions
 *  setOptions?(options: MapVizOptions | ((options: MapVizOptions) => MapVizOptions)): void
 *  language: 'en' | 'ar'
 * }} props
 */
function ListViz(
  { records, options, meta, language, query, title, setOptions, viewer },
  ref
) {
  const [
    { filters, filterableFields },
    { setFilters, addFilter, removeFilter },
  ] = useVizFilters(meta.fields, options, setOptions)

  const filteredRecords = useMemo(
    () => filterRecords(records, filters),
    [records, filters]
  )

  const realColumns = useMemo(
    () => options.columns.filter((col) => meta.fields[col]),
    [meta.fields, options.columns]
  )

  useImperativeHandle(ref, () => ({
    exportViz: () => {
      const flatList = filteredRecords.map((row) => {
        const out = {
          type: row.item,
          id: row.id,
          label: getInLang(row.label, language),
        }
        realColumns.forEach((col) => {
          out[col] = getColValue({ meta, row, col, language })
        })
        return out
      })
      const csv = unparse(flatList)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      saveAs(
        blob,
        getInLang(title, language)
          ? getInLang(title, language) + '.csv'
          : 'list.csv'
      )
    },
  }))

  return (
    <div className="w-100 h-100 d-flex bg-background-pr">
      <div
        className="flex-grow-1 flex-shrink-1 d-flex flex-column pe-3"
        style={{ minWidth: 0 }}
      >
        <div className={classNames(styles.listContainer)}>
          {filteredRecords.length > 0 && (
            <TableListViz
              records={filteredRecords}
              language={language}
              columns={realColumns}
              meta={meta}
            />
          )}
        </div>
        <div className="flex-grow-0 flex-shrink-0">
          <FilterTimeFieldsWidget
            meta={meta}
            records={records}
            filters={filters}
            onFiltersChange={setFilters}
            onFilterRemoved={setOptions ? removeFilter : null}
          />
        </div>
      </div>
      <div
        className={classNames(styles.widgetContainerRight, {
          [styles.viewer]: viewer,
        })}
      >
        {setOptions && (
          <FilterFieldPickerWidget
            fields={filterableFields}
            filters={filters}
            onFilterPicked={addFilter}
            onFiltersChange={setFilters}
          />
        )}
        <FilterFacetFieldsWidget
          meta={meta}
          records={records}
          filters={filters}
          onFiltersChange={setFilters}
          onFilterRemoved={setOptions ? removeFilter : null}
        />
      </div>
    </div>
  )
}

export default memo(forwardRef(ListViz))
