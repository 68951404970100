import {
  memo,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import classNames from 'classnames'
import ItemsWidget from '../../Widget/ItemsWidget'
import FilterFieldPickerWidget from '../../Widget/FilterFieldPickerWidget'
import FilterFacetFieldsWidget from '../../Widget/FilterFacetFieldsWidget'
import saveSvgAsImage, { filterRecords, getInLang } from '../../../utils'
import FilterTimeFieldsWidget from '../../Widget/FilterTimeFieldsWidget'
import ContextualTimeline from '../ContextualTimeline'
import GanttChart from '../GanttChart'
import styles from './EventsTimelineViz.module.scss'
import { useVizFilters } from '../../../hooks/filters'
import { useAuthState } from 'use-eazy-auth'
import { select } from 'd3-selection'
import logo from '../../../assets/PalREAD_watermark_low.png'

/**
 * @typedef {{
 *    group_by: string
 * }} EventsTimelineVizOptions
 **/

/**
 * @param {{
 *  records: any[]
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 *  query: QueryEditorState
 *  options: EventsTimelineVizOptions
 *  setOptions?(options: EventsTimelineVizOptions | ((options: EventsTimelineVizOptions) => EventsTimelineVizOptions)): void
 *  language: 'en' | 'ar'
 *  timelineData: any
 * }} props
 */
function EventsTimelineViz(
  {
    records,
    options,
    meta,
    language,
    query,
    setOptions,
    title,
    timelineData,
    viewer,
  },
  ref
) {
  const [fromField, toField] = query.ui.pairField

  const containerRef = useRef()

  const [
    { filters, filterableFields },
    { setFilters, addFilter, removeFilter },
  ] = useVizFilters(meta.fields, options, setOptions)

  const filteredRecords = useMemo(
    () => filterRecords(records, filters),
    [records, filters]
  )

  useImperativeHandle(ref, () => ({
    exportViz: () => {
      const svgElement = containerRef.current.querySelector('svg#viz')
      const img = select(svgElement)
        .append('image')
        .attr('href', logo)
        .attr('height', 40)
        .attr('width', 'auto')
        .attr('x', 10)
        .attr('y', select(svgElement).attr('height') - 50)
        .style('opacity', 0.5)

      saveSvgAsImage(
        svgElement,
        getInLang(title, language)
          ? getInLang(title, language) + '.svg'
          : 'timeline.svg'
      )

      img.remove()
    },
  }))

  const { authenticated } = useAuthState()

  return (
    <div className="w-100 h-100 d-flex bg-background-pr" ref={containerRef}>
      <div className="flex-grow-1 flex-shrink-1 d-flex flex-column pe-3">
        <div className={classNames(styles.timelineContainer)}>
          {records.length > 0 && (
            <GanttChart
              records={filteredRecords}
              options={options}
              language={language}
            ></GanttChart>
          )}
        </div>
        <div className="flex-grow-0 flex-shrink-0">
          <FilterTimeFieldsWidget
            meta={meta}
            records={records}
            filters={filters}
            onFiltersChange={setFilters}
            onFilterRemoved={setOptions ? removeFilter : null}
          />
          {options.show_contextual_timeline && (
            <ContextualTimeline
              data={timelineData}
              filters={filters}
            ></ContextualTimeline>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.widgetContainerRight, {
          [styles.viewer]: viewer,
        })}
      >
        {setOptions && (
          <FilterFieldPickerWidget
            fields={filterableFields}
            filters={filters}
            onFilterPicked={addFilter}
            onFiltersChange={setFilters}
          />
        )}
        <ItemsWidget
          showItemLink={authenticated}
          records={records}
          filteredRecords={filteredRecords}
          language={language}
        />
        <FilterFacetFieldsWidget
          meta={meta}
          records={records}
          filters={filters}
          onFiltersChange={setFilters}
          onFilterRemoved={setOptions ? removeFilter : null}
        />
      </div>
    </div>
  )
}

export default memo(forwardRef(EventsTimelineViz))
