import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'
import { useActionErrorHandler } from '../../hooks/error'
import { useQueryTable } from '../../hooks/query'
import PageSpinner from '../PageSpinner'
import WrapWithEditorFooter from '../WrapWithEditorFooter'
import QueryRunner from './QueryRunner'

/**
 * @param {{
 *  left: React.ReactNode
 *  right: React.ReactNode
 *  query: ComplexQuery
 *  onReset(): void
 *  onNext(rows: any[], meta: { fields: Record<string, import('../../hooks/schema').OmekaFieldType> }): void
 *  dirty: boolean
 * }} props
 */
export function QueryBuilderContainer({
  left,
  right,
  query,
  onReset,
  onNext,
  dirty,
}) {
  const handleActionError = useActionErrorHandler()

  const [
    { rows, excludeIds, loading },
    { runQuery, run, toggle, untoggleAll, toggleAll, cleanQuery },
  ] = useQueryTable()

  // Clean data on new query
  useEffect(() => {
    cleanQuery()
  }, [cleanQuery, query])

  return (
    <WrapWithEditorFooter
      fixed
      className="justify-content-end bg-dark"
      footer={
        <Button
          disabled={loading || query.items.length === 0}
          onClick={() => {
            // Always fetch new records before go next
            // ... and eventually filter the exclude ids
            run
              .onFailure(handleActionError)
              .onSuccess(({ results, meta }) => {
                onNext(
                  results.filter((row) => !excludeIds[row.id]),
                  meta
                )
              })
              .run(query)
          }}
          variant="success"
        >
          <i className="bi-graph-up me-1"></i> Get data and visualize
        </Button>
      }
    >
      {loading && <PageSpinner text="Running the query, be patient..." />}
      <Prompt
        when={dirty}
        message="Are you sure to leave this page without saving?"
      />
      <div className="border-bottom d-flex justify-content-between align-items-center py-2">
        <div className="d-flex align-items-end">
          <h4 className="m-0">Query Builder</h4>
          {/* {dirty && (
            <small className="ms-2 text-primary">
              *You have unsaved changes
            </small>
          )} */}
        </div>
        <button className="btn btn-outline-danger btn-sm" onClick={onReset}>
          <i className="bi-arrow-counterclockwise"></i> Reset
        </button>
      </div>
      <div className="row mb-4">
        <div className="col-md-3 border-end">{left}</div>
        <div className="col-md-9">{right}</div>
      </div>
      <QueryRunner
        loading={loading}
        query={query}
        rows={rows}
        excludeIds={excludeIds}
        runQuery={runQuery}
        toggle={toggle}
        toggleAll={toggleAll}
        untoggleAll={untoggleAll}
      />
    </WrapWithEditorFooter>
  )
}
