import { Fragment } from 'react'
import { Bar } from '@visx/shape'
import { getInLang } from '../../../utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OMEKA_ITEMS_URL } from '../../../consts'
import { useAuthState } from 'use-eazy-auth'
import styles from './GanttChart.module.scss'

function Label({
  authenticated,
  timeRecord,
  textX,
  barY,
  textAnchor,
  language,
}) {
  if (authenticated) {
    return (
      <a
        href={OMEKA_ITEMS_URL + timeRecord.id}
        target="_blank"
        rel="noreferrer"
        className="text-decoration-none"
      >
        <text
          x={textX}
          y={barY}
          dy={-4}
          textAnchor={textAnchor}
          fontSize="0.675rem"
          fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
        >
          {getInLang(timeRecord.label, language)}
        </text>
      </a>
    )
  } else {
    return (
      <text
        x={textX}
        y={barY}
        dy={-4}
        textAnchor={textAnchor}
        fontSize="0.675rem"
        fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
      >
        {getInLang(timeRecord.label, language)}
      </text>
    )
  }
}

export default function GanttBars({
  records,
  xScale,
  barHeight,
  barYPadding,
  options,
  chartWidth,
  language,
}) {
  const { authenticated } = useAuthState()
  return (
    <Fragment>
      {records.map((timeRecord, i) => {
        // const barY =
        //   (gIndex + (options.compact_view ? timeRecord.yIndex : i)) *
        //     (barHeight + barYPadding) +
        //   barYPadding / 2

        const barY =
          (options.compact_view ? timeRecord.yIndex : i) *
            (barHeight + barYPadding) +
          barYPadding / 2

        const barX = xScale(timeRecord.from)
        const width =
          xScale(timeRecord.to) - barX < 12
            ? barHeight
            : xScale(timeRecord.to) - barX

        const textX = barX > chartWidth / 2 ? barX + width : barX
        const textAnchor = barX > chartWidth / 2 ? 'end' : 'start'
        return timeRecord.type === 'span' ? (
          <Fragment key={timeRecord.id}>
            {!options.compact_view && (
              <Label
                timeRecord={timeRecord}
                authenticated={authenticated}
                textX={textX}
                barY={barY}
                textAnchor={textAnchor}
                language={language}
              ></Label>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{getInLang(timeRecord.label, language)}</Tooltip>
              }
            >
              {({ ref, ...passProps }) => (
                <Bar
                  className={styles.bar}
                  innerRef={ref}
                  x={barX}
                  y={barY}
                  width={width}
                  height={barHeight}
                  fill={options.items_color}
                  rx={barHeight / 2}
                  {...passProps}
                />
              )}
            </OverlayTrigger>
          </Fragment>
        ) : (
          <Fragment key={timeRecord.id}>
            {!options.compact_view && (
              <Label
                timeRecord={timeRecord}
                authenticated={authenticated}
                textX={textX - barHeight / 2}
                barY={barY}
                textAnchor={textAnchor}
                language={language}
              ></Label>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{getInLang(timeRecord.label, language)}</Tooltip>
              }
            >
              {({ ref, ...passProps }) => (
                <circle
                  ref={ref}
                  cx={barX}
                  cy={barY + barHeight / 2}
                  r={barHeight / 2}
                  fill={options.items_color}
                  className={styles.circle}
                  {...passProps}
                ></circle>
              )}
            </OverlayTrigger>
          </Fragment>
        )
      })}
    </Fragment>
  )
}
