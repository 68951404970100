import { useRef, useEffect } from 'react'
import { Brush } from '@visx/brush'
import { ParentSize } from '@visx/responsive'
import { Bar } from '@visx/shape'
import { extent, rollups, max } from 'd3-array'
import { AxisBottom } from '@visx/axis'
import { scaleLinear, scaleTime, scaleBand } from 'd3-scale'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { timeYear } from 'd3-time'
import countBy from 'lodash/countBy'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { filterRecords, getInLang, multiDateParse } from '../../../utils'
import styles from './TimeBrushWidget.module.scss'
import { UNSET_KEY } from '../../../consts'

const HEIGHT = 100
const MIN_YEARS = 60
const MARGIN_UNSET = 20

/**
 * @param {{
 *  years: number[]
 *  yearsCount: Record<string, number>
 *  xScale(x: number): number
 *  yScale(y: number): number
 *  opacity?: number
 *  className?: string
 * }}
 */
function Bars({
  xScale,
  yScale,
  xScaleBand,
  className,
  data,
  opacity = 1,
  chartHeight,
}) {
  return data.map((row) => {
    const height = yScale(row.count) < 2 ? 2 : yScale(row.count)
    return (
      <OverlayTrigger
        key={row.date}
        placement="top"
        overlay={
          <Tooltip>
            <b>{row.date.getFullYear()}</b>
            <br></br>
            {row.count} {row.count > 1 ? 'items' : 'item'}
          </Tooltip>
        }
      >
        {({ ref, ...passProps }) => (
          <Bar
            {...passProps}
            innerRef={ref}
            className={className}
            key={row.date}
            x={xScale(row.date) - xScaleBand.bandwidth() / 2}
            y={chartHeight - height}
            width={xScaleBand.bandwidth()}
            height={height}
            fillOpacity={opacity}
            fill="#6c757d"
          />
        )}
      </OverlayTrigger>
    )
  })
}

function UnsetBar({
  yScale,
  xScaleBand,
  data,
  chartHeight,
  x,
  onSelectionChange,
}) {
  return (
    <g
      onClick={() => onSelectionChange([UNSET_KEY])}
      style={{ cursor: 'pointer' }}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <b>
              <i>Unset</i>
            </b>
            <br></br>
            {data[0]} {data[0] > 1 ? 'items' : 'item'}
          </Tooltip>
        }
      >
        {({ ref, ...passProps }) => (
          <rect
            ref={ref}
            {...passProps}
            x={x - xScaleBand.bandwidth() / 2}
            y={chartHeight - yScale(data[0])}
            width={xScaleBand.bandwidth()}
            height={yScale(data[0])}
            fillOpacity={0.5}
            fill="#6c757d"
          ></rect>
        )}
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <b>
              <i>Unset</i>
            </b>
            <br></br>
            {data[1]} {data[1] > 1 ? 'items' : 'item'}
          </Tooltip>
        }
      >
        {({ ref, ...passProps }) => (
          <rect
            ref={ref}
            {...passProps}
            x={x - xScaleBand.bandwidth() / 2}
            y={chartHeight - yScale(data[1])}
            width={xScaleBand.bandwidth()}
            height={yScale(data[1])}
            fillOpacity={1}
            fill="#6c757d"
          ></rect>
        )}
      </OverlayTrigger>
      <rect
        x={x - xScaleBand.bandwidth() / 2}
        y={chartHeight - yScale(data[1])}
        width={xScaleBand.bandwidth()}
        height={yScale(data[1])}
        fillOpacity={1}
        fill="url(#diagonalHatch)"
        style={{ pointerEvents: 'none' }}
      ></rect>
    </g>
  )
}

/**
 * @param {any[]} records
 * @param {string} field
 * @returns number[]
 */
function calculateYears(records, field) {
  return records
    .map((r) => getInLang(r.data[field]))
    .map((rawDate) => multiDateParse(rawDate)?.getFullYear() ?? UNSET_KEY)
}

/**
 * @param {{
 *  field: string
 * 	records: any[]
 *  width: number
 *  onSelectionChange(selction: number[]) void
 *  allFilters: Record<string, number[] | string[]>
 * }}
 */
function TimeBrushChart({
  field,
  records,
  width,
  allFilters,
  onSelectionChange,
}) {
  const brushRef = useRef(null)

  const years = useMemo(() => calculateYears(records, field), [field, records])

  const yearsCount = useMemo(() => countBy(years), [years])

  const totalUnset = yearsCount[UNSET_KEY] ?? 0

  const dataYears = useMemo(
    () =>
      Object.entries(yearsCount)
        .map((d) => {
          if (d[0] === UNSET_KEY) {
            return null
          }
          return { date: new Date(+d[0], 0, 1), count: d[1] }
        })
        .filter(Boolean),
    [yearsCount]
  )
  const crossYearsCount = useMemo(
    () =>
      countBy(
        calculateYears(filterRecords(records, omit(allFilters, field)), field)
      ),
    [allFilters, field, records]
  )

  console.log('Unset for cross years count', crossYearsCount[UNSET_KEY] ?? 0)
  const totalCrossUnset = crossYearsCount[UNSET_KEY] ?? 0
  const dataCrossYears = useMemo(
    () =>
      Object.entries(crossYearsCount)
        .map((d) => {
          if (d[0] === UNSET_KEY) {
            return null
          }
          return { date: new Date(+d[0], 0, 1), count: d[1] }
        })
        .filter(Boolean),
    [crossYearsCount]
  )

  const MARGIN = useMemo(() => {
    return {
      top: 5,
      right: 15,
      bottom: 25,
      left: totalUnset ? 50 : 15,
    }
  }, [totalUnset])

  const chartWidth = useMemo(() => {
    return width - MARGIN.left - MARGIN.right
  }, [MARGIN.left, MARGIN.right])

  const chartHeight = HEIGHT - MARGIN.top - MARGIN.bottom

  const yearsDomain = useMemo(() => {
    const ex = extent(dataYears, (d) => d.date)
    const yearDiff = ex[1].getFullYear() - ex[0].getFullYear()
    if (yearDiff < MIN_YEARS) {
      const now = new Date()

      const d1 = now.getFullYear() - ex[1].getFullYear()
      const d0 = timeYear.offset(ex[0], -(d1 < MIN_YEARS ? MIN_YEARS - d1 : 0))
      return [d0, now]
    } else {
      return ex
    }
  }, [years])

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const xScaleBand = useMemo(() => {
    return scaleBand()
      .domain(timeYear.range(...yearsDomain))
      .rangeRound([0, chartWidth])
      .padding(0.1)
  }, [chartWidth, yearsDomain])

  const yScale = useMemo(() => {
    return (
      scaleLinear()
        .range([0, chartHeight])
        //.domain([0, max(dataYears, (d) => d.count)])
        .domain([0, max(dataYears, (d) => max([d.count, totalUnset]))])
    )
  }, [dataYears, totalUnset])

  const initialBrushPosition = {
    start: { x: xScale(yearsDomain[0]) },
    end: { x: xScale(yearsDomain[1]) },
  }

  useEffect(() => {
    if (brushRef?.current && !allFilters[field].length) {
      brushRef.current.reset()
    }
  })

  return (
    <svg height={HEIGHT} width={width}>
      <pattern
        id="diagonalHatch"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <path
          d="M -1,1 l 2,-2 M 0,4 l 4,-4 M 3,5 l 2,-2"
          stroke="white"
          strokeWidth={1}
          strokeOpacity={0.5}
        ></path>
      </pattern>
      {totalUnset && (
        <g transform={`translate(${5}, ${MARGIN.top})`}>
          <UnsetBar
            data={[totalUnset, totalCrossUnset]}
            xScaleBand={xScaleBand}
            yScale={yScale}
            chartHeight={chartHeight}
            x={(MARGIN.left - MARGIN_UNSET) / 2}
            onSelectionChange={onSelectionChange}
          ></UnsetBar>
          <line
            x1={0}
            x2={MARGIN.left - MARGIN_UNSET}
            y1={chartHeight}
            y2={chartHeight}
            stroke="rgb(34, 34, 34)"
            fill="transparent"
            shapeRendering="crispedges"
            strokeWidth={1}
          ></line>
          <line
            x1={(MARGIN.left - MARGIN_UNSET) / 2}
            x2={(MARGIN.left - MARGIN_UNSET) / 2}
            y1={chartHeight}
            y2={chartHeight + 8}
            stroke="rgb(34, 34, 34)"
            fill="transparent"
            shapeRendering="crispedges"
            strokeLinecap="square"
          ></line>
          <text
            x={(MARGIN.left - MARGIN_UNSET) / 2}
            y={chartHeight + 13}
            fontFamily="Arial"
            fontSize={10}
            textAnchor="middle"
            fontStyle="italic"
            dominantBaseline="hanging"
          >
            Unset
          </text>
        </g>
      )}

      <g transform={`translate(${MARGIN.left - xScaleBand.bandwidth() / 2},0)`}>
        <Brush
          margin={MARGIN}
          innerRef={brushRef}
          xScale={xScale}
          yScale={yScale}
          width={chartWidth + xScaleBand.bandwidth()}
          height={chartHeight + MARGIN.top}
          handleSize={8}
          resizeTriggerAreas={['left', 'right']}
          brushDirection="horizontal"
          onBrushEnd={(domain) => {
            if (domain) {
              onSelectionChange([
                new Date(domain.x0).getFullYear(),
                new Date(domain.x1).getFullYear(),
              ])
            } else {
              onSelectionChange([])
            }
          }}
        />
      </g>
      <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
        <Bars
          data={dataYears}
          xScale={xScale}
          xScaleBand={xScaleBand}
          yScale={yScale}
          className={styles['cross-bar-time']}
          opacity={0.5}
          chartHeight={chartHeight}
        />
        <Bars
          data={dataCrossYears}
          xScale={xScale}
          yScale={yScale}
          xScaleBand={xScaleBand}
          className={styles['cross-bar-time']}
          opacity={1}
          chartHeight={chartHeight}
        />
      </g>
      <AxisBottom
        rangePadding={xScaleBand.bandwidth() / 2}
        scale={xScale}
        top={chartHeight + MARGIN.top}
        left={MARGIN.left}
      />
    </svg>
  )
}

/**
 * @param {{
 *  field: string
 * 	records: any[]
 *  onSelectionChange(selction: number[]) void
 *  onRemove?(): void
 *  allFilters: Record<string, number[] | string[]>
 * }} props
 */
export default function TimeBrushWidget({
  field,
  onRemove,
  allFilters,
  onSelectionChange,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div className="w-100 border bg-white">
      <div
        className={classNames('p-2 d-flex bg-dark text-white', {
          'border-bottom': !collapsed,
        })}
      >
        <div className="d-flex align-items-center w-100">
          <div
            className="me-2"
            role="button"
            onClick={() => setCollapsed((c) => !c)}
          >
            {collapsed ? (
              <i className="bi-chevron-down"></i>
            ) : (
              <i className="bi-chevron-up"></i>
            )}
          </div>
          <div
            className={classNames(
              'fw-bold text-uppercase me-auto',
              styles.fieldName
            )}
          >
            {field}{' '}
            <span className="fw-normal">
              {allFilters[field][0] === UNSET_KEY
                ? 'Unset'
                : allFilters[field].join('-')}
            </span>
          </div>
          {allFilters[field].length > 0 && (
            <div
              className="ms-auto me-2 text-white"
              onClick={() => onSelectionChange([])}
              role="button"
            >
              <i className="bi-arrow-counterclockwise"></i>
            </div>
          )}
          {onRemove && (
            <div className="me-2" onClick={onRemove} role="button">
              <i className="bi-trash"></i>
            </div>
          )}
        </div>
      </div>
      <ParentSize>
        {({ width }) =>
          width > 0 && !collapsed ? (
            <TimeBrushChart
              {...props}
              allFilters={allFilters}
              onSelectionChange={onSelectionChange}
              field={field}
              width={width}
            />
          ) : null
        }
      </ParentSize>
    </div>
  )
}
