import uniq from 'lodash/uniq'
import isDeepEqual from 'fast-deep-equal'
import { useCallback, useMemo, useReducer } from 'react'
import { useEditorSchema } from '../../../hooks/schema'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import DropdownSelect from '../../DropdownSelect'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { QueryBuilderContainer } from '../QueryBuilderContainer'

/**
 * @typedef {{
 *  queries: Query[]
 *  items: string[]
 *  field: string | null
 * }} MapQueryState
 *
 * @param {MapQueryState} state
 * @param {
    QueryActions
    | { type: 'UPDATE_ITEMS', payload: string[] }
    | { type: 'UPDATE_FIELD', payload: string }
    | { type: 'RESET' }
  } action
 * @returns {MapQueryState}
 */
function mapVizReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        items: [],
        field: action.payload,
      }
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'RESET':
      return defaultState
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

/**
 * @type MapQueryState
 */
const defaultState = {
  items: [],
  field: null,
  queries: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {MapQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function MapQueryEditor({ initalState, onNext, vizId }) {
  const { fieldsByType } = useEditorSchema()
  const [state, dispatch] = useReducer(mapVizReducer, initalState, init)

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const setField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_FIELD',
        payload: field,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const geoFields = useMemo(
    () => uniq(fieldsByType['geo'].map((f) => f.label)).sort(),
    [fieldsByType]
  )

  const geoItems = useMemo(
    () =>
      uniq(
        fieldsByType['geo']
          .filter((f) => f.label === state.field)
          .map((f) => f.item)
          .sort()
      ),
    [fieldsByType, state.field]
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)

  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      fields: [state.field].filter(Boolean),
    }),
    [allowedQueryFields, state]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {
              field: state.field,
            },
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <DropdownSelect
            label="Show the"
            placeholder="select a property"
            className="mb-3 d-grid"
            value={state.field}
            onChange={setField}
            options={geoFields}
          />
          {state.field && (
            <MultiBoxSelect
              label="Of the following items"
              values={state.items}
              options={geoItems}
              onChange={setItems}
            />
          )}
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
