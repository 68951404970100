import QueryEditor from './QueryEditor'
import VizOptions from './VizOptions'

/**
 * @param {{
 *  vizType: string
 *  onVizTypeChange(vizType: string): void
 *  step: 'query' | 'options'
 *  initialQueryEditorState: QueryEditorState
 *  onEditQuery(): void
 *  onShowOptions(state: QueryEditorState, records: any[], meta: any, timelineData: any): void
 *  onSaveOptions(opts: Record<string, any>): Promise<any>
 *  onRefreshData(): void
 *  onDelete?(): void
 *  intialVizOptions?: Record<string, any>
 *  records: any[] | null
 *  meta: { fields: Record<string, import('../hooks/schema').OmekaFieldType> }
 *   timelineData: any
 *  vizId: number | null
 *  className?: string
 *  refreshingData?: boolean
 * }} props
 */
export default function VizEditor({
  vizType,
  step,
  initialQueryEditorState,
  intialVizOptions,
  records,
  meta,
  timelineData,
  vizId = null,
  className = '',
  refreshingData = false,
  onVizTypeChange,
  onEditQuery,
  onRefreshData,
  onShowOptions,
  onSaveOptions,
  onDelete,
}) {
  return (
    <>
      {step === 'query' && (
        <QueryEditor
          className={className}
          vizType={vizType}
          onVizTypeChange={onVizTypeChange}
          initialEditorState={initialQueryEditorState}
          onNext={onShowOptions}
          vizId={vizId}
        />
      )}
      {step === 'options' && (
        <VizOptions
          timelineData={timelineData}
          query={initialQueryEditorState}
          className={className}
          intialVizOptions={intialVizOptions}
          refreshingData={refreshingData}
          onSave={onSaveOptions}
          onRefreshData={onRefreshData}
          vizType={vizType}
          onEditQuery={onEditQuery}
          onDelete={onDelete}
          records={records}
          meta={meta}
          vizId={vizId}
        />
      )}
    </>
  )
}
