import { useMemo } from 'react'
import { ParentSize } from '@visx/responsive'
import { AxisTop } from '@visx/axis'
import { GridColumns } from '@vx/grid'
import { LegendLinear } from '@visx/legend'
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import { extent, max, min, descending, ascending, rollups } from 'd3-array'
import { timeYear } from 'd3-time'
import {
  scaleLinear,
  scaleTime,
  scaleSequential,
  scaleBand,
  scaleQuantize,
} from 'd3-scale'
import { getInLang, multiDateParse } from '../../../utils'
import { PALETTE, OMEKA_ITEMS_URL } from '../../../consts'
import classNames from 'classnames'
import styles from './EvolutionChart.module.scss'

const BAR_HEIGHT = 30
const BAR_Y_PADDING = 35
const MARGINS = {
  top: 20,
  right: 20,
  bottom: 10,
  left: 20,
}

const LEGEND_STEPS = 9

function EvolutionChartComponent({ records, options, width, query, language }) {
  const timeList = useMemo(() => {
    let outList = []
    records.forEach((record) => {
      // TODO: Avoid hardcoding?
      if (
        record.data[query.ui.evolutionField] &&
        record.data[query.ui.basedOnField]
      ) {
        const date = multiDateParse(
          getInLang(record.data[query.ui.basedOnField])
        )
        const groups = getInLang(record.data[query.ui.evolutionField])
        if (date && Array.isArray(groups)) {
          groups.forEach((group) => {
            const timeRecord = {
              id: record.id,
              label: record.label,
              date: date,
              group: group,
              record: record.item,
            }
            outList.push(timeRecord)
          })
        }
      }
    })

    const timeGroup = rollups(
      outList.sort((a, b) => ascending(a.date, b.date)),
      (v) => v,
      (d) => d.group,
      (d) => d.date.getFullYear()
    )

    return timeGroup.sort((a, b) => descending(a[1].length, b[1].length))
  }, [records, query.ui])

  const height =
    timeList.length * (BAR_Y_PADDING + BAR_HEIGHT) +
    MARGINS.top +
    MARGINS.bottom

  const chartHeight = height - MARGINS.top - MARGINS.bottom

  const chartWidth = useMemo(() => {
    return width - MARGINS.left - MARGINS.right
  }, [width])

  const yearsDomain = useMemo(() => {
    const ex = extent(timeList.map((d) => d[1].map((c) => c[0])).flat()).map(
      (d) => new Date(d, 0, 1)
    )

    return [timeYear.offset(ex[0], -1), timeYear.offset(ex[1], 1)]
  }, [timeList])

  const colorScaleMax = useMemo(() => {
    return max(timeList, (d) => max(d[1], (f) => f[1].length))
  }, [timeList])

  const colorScaleMin = useMemo(() => {
    return min(timeList, (d) => min(d[1], (f) => f[1].length))
  }, [timeList])

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const xScaleBand = useMemo(() => {
    return scaleBand()
      .range([0, chartWidth])
      .domain(xScale.ticks(timeYear))
      .padding(0)
  }, [xScale])

  const colorScale = useMemo(() => {
    return scaleQuantize(
      [colorScaleMin, colorScaleMax],
      PALETTE[options.palette][LEGEND_STEPS]
    ).nice()
  }, [colorScaleMax, colorScaleMin, options.palette])

  // from https://observablehq.com/@mootari/svg-single-path-grid
  function gridPath(cols, rows, width = 1, height = 1, initPosition = true) {
    const sx = width / cols,
      sy = height / rows
    const px = Array(rows + 1)
      .fill(`h${width}`)
      .join(`m${-width},${sy}`)
    const py = Array(cols + 1)
      .fill(`v${height}`)
      .join(`m${sx},${-height}`)
    return `${initPosition ? 'M0,0' : ''}${px}m${-width}${-height}${py}`
  }

  const tickLabelProps = () => ({
    fontSize: '0.75rem',
    fontFamily: 'Graphik Arabic',
    textAnchor: 'middle',
    y: -5,
  })

  return (
    <div>
      {timeList.length > 0 ? (
        <div>
          <div className="w-100 d-flex pt-3">
            <div
              style={{ marginLeft: MARGINS.left }}
              className="d-flex flex-column"
            >
              <small>{query.ui.basedOnField}</small>
              <i className="bi-arrow-right"></i>
            </div>
            {!options.independent_scale && (
              <div className="ms-auto d-flex">
                <h6 className="me-2 GraphikArabic">
                  <small>Number of items</small>
                </h6>
                <div
                  style={{ fontSize: '0.75rem', marginRight: MARGINS.right }}
                >
                  <LegendLinear
                    scale={colorScale}
                    direction="row"
                    itemDirection="column"
                    labelMargin={0}
                    shapeMargin={0}
                    shapeHeight={20}
                    shapeWidth={5}
                    steps={LEGEND_STEPS}
                    labelFormat={(d, i) => {
                      return i !== 0 && i + 1 < LEGEND_STEPS
                        ? ''
                        : d === 0
                        ? 1
                        : d
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <svg width={width} height={30} className="sticky-top">
            <rect height={30} width={width} fill={'#f6f5f1'}></rect>
            <AxisTop
              scale={xScale}
              top={30}
              left={MARGINS.left}
              hideTicks={true}
              tickLength={0}
              stroke={'#ccc'}
              rangePadding={xScaleBand.bandwidth()}
              tickLabelProps={tickLabelProps}
            />
          </svg>
          <svg width={width} height={height} id="viz">
            <rect width={width} height={height} fill="#f6f5f1"></rect>
            <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
              {timeList.map((group, i) => {
                const localScale = colorScale
                  .copy()
                  .domain(extent(group[1], (d) => d[1].length))
                return (
                  <g
                    key={group[0]}
                    transform={`translate(0, ${
                      i * (BAR_HEIGHT + BAR_Y_PADDING)
                    })`}
                  >
                    <text
                      x={0}
                      y={0}
                      fontSize="1.2rem"
                      fontFamily="'Zarid Serif', system-ui, -apple-system, 'Segoe UI', Arial"
                    >
                      {group[0]}
                    </text>
                    <path
                      fill="none"
                      stroke="#efefef"
                      transform={`translate(0,${BAR_Y_PADDING / 4})`}
                      d={gridPath(
                        xScaleBand.domain().length,
                        1,
                        chartWidth + xScaleBand.bandwidth(),
                        BAR_HEIGHT
                      )}
                    ></path>
                    {group[1].map((year) => (
                      <OverlayTrigger
                        key={year[0]}
                        placement="auto"
                        trigger="click"
                        rootClose={true}
                        overlay={
                          <Popover>
                            <div className="p-2 bg-background-pr">
                              {year[1].length > 1 && (
                                <h6 className={styles.axisLabel}>
                                  {year[0]}: {year[1].length} items
                                </h6>
                              )}
                              <div
                                style={{
                                  maxHeight: '200px',
                                  overflowY: 'scroll',
                                  textAlign: 'center',
                                }}
                              >
                                {year[1].map((record, i) => (
                                  <div
                                    key={record.id}
                                    className={classNames('p-2 bg-white mb-1')}
                                  >
                                    <small>
                                      {getInLang(record.label, language)}{' '}
                                      <a
                                        href={OMEKA_ITEMS_URL + record.id}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {' '}
                                        <i
                                          className={classNames(
                                            'bi-box-arrow-up-right '
                                          )}
                                        ></i>
                                      </a>
                                    </small>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover>
                        }
                      >
                        {({ ref, ...passProps }) => (
                          <rect
                            className={styles.bar}
                            {...passProps}
                            ref={ref}
                            x={xScale(new Date(year[0], 0, 1))}
                            y={BAR_Y_PADDING / 4}
                            width={xScaleBand.bandwidth()}
                            height={BAR_HEIGHT}
                            fill={
                              options.independent_scale
                                ? localScale(year[1].length)
                                : colorScale(year[1].length)
                            }
                          ></rect>
                        )}
                      </OverlayTrigger>
                    ))}
                  </g>
                )
              })}
            </g>

            <GridColumns
              scale={xScale}
              width={chartWidth}
              height={chartHeight}
              left={MARGINS.left}
              top={MARGINS.top}
              stroke={'#ccc'}
              strokeDasharray={(3, 3)}
            />
          </svg>
        </div>
      ) : (
        'No data!'
      )}
    </div>
  )
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  options: {
 *    main_color: string
 *  }
 * }}
 */
export default function GanttChart(props) {
  return (
    <ParentSize>
      {({ width }) =>
        width > 0 ? <EvolutionChartComponent {...props} width={width} /> : null
      }
    </ParentSize>
  )
}
