import { useMemo, useState } from 'react'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import { descending } from 'd3-array'
import { filterRecords, getInLang } from '../../../utils'
import classNames from 'classnames'
import styles from './FilterFacetFieldWidget.module.scss'
import { UNSET_KEY } from '../../../consts'

/**
 * @param {any[]} records
 * @param {string} field
 */
function calculateFieldsCounts(records, field) {
  /**  @type Record<string, number> **/
  const countMap = {}
  records.forEach((record) => {
    const values = getInLang(record.data[field])
    if (Array.isArray(values)) {
      values.forEach((v) => {
        countMap[v] = (countMap[v] ?? 0) + 1
      })
    } else if (values) {
      countMap[values] = (countMap[values] ?? 0) + 1
    } else {
      countMap[UNSET_KEY] = (countMap[UNSET_KEY] ?? 0) + 1
    }
  })
  return countMap
}

/**
 * @param {Record<string, number>} countMap
 */
function makeOptions(countMap) {
  const opt = Object.keys(countMap).map((key) => ({
    label: key === UNSET_KEY ? 'Unset' : key,
    key,
    count: +countMap[key],
  }))

  opt.sort((a, b) => {
    return a.key === UNSET_KEY
      ? -1
      : b.key === UNSET_KEY
      ? 1
      : descending(a.count, b.count)
  })
  return opt
}

/**
 * @param {{
 * 	field: string
 * 	records: string
 *  selection: string[]
 *  onSelectionChange(selection: string[]): void
 *  onRemove?(): void
 *  allFilters: Record<string, string[] | number[]>
 * }} props
 */
export default function FilterFacetFieldWidget({
  field,
  records,
  selection = [],
  allFilters,
  onSelectionChange,
  onRemove,
}) {
  const options = useMemo(
    () => makeOptions(calculateFieldsCounts(records, field)),
    [records, field]
  )

  const crossCounts = useMemo(() => {
    const filteredRecords = filterRecords(records, omit(allFilters, field))
    return calculateFieldsCounts(filteredRecords, field)
  }, [records, field, allFilters])

  const extent = useMemo(
    () => Math.max(...options.map((o) => o.count)),
    [options]
  )
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div className="bg-white">
      <div
        className={classNames('p-2 d-flex bg-dark', {
          'border-bottom': !collapsed,
        })}
      >
        <div className="d-flex align-items-center w-100">
          <div
            className="me-2 text-white"
            role="button"
            onClick={() => setCollapsed((c) => !c)}
          >
            {collapsed ? (
              <i className="bi-chevron-down"></i>
            ) : (
              <i className="bi-chevron-up"></i>
            )}
          </div>
          <div
            className={classNames(
              'fw-bold text-uppercase text-white me-auto',
              styles.fieldName
            )}
          >
            <div className="d-flex align-items-center">
              <span>{field}</span>
              {selection.length > 0 && (
                <span className="ms-1 badge bg-secondary">
                  {selection.length}
                </span>
              )}
            </div>
          </div>
          {selection.length > 0 && (
            <div
              className="ms-auto me-2 text-white"
              onClick={() => onSelectionChange([])}
              role="button"
            >
              <i className="bi-arrow-counterclockwise"></i>
            </div>
          )}
          {onRemove && (
            <div className="me-2 text-white" onClick={onRemove} role="button">
              <i className="bi-trash"></i>
            </div>
          )}
        </div>
      </div>
      {!collapsed && (
        <div
          className="p-2"
          style={{ overflowY: 'auto', marginBottom: 2, maxHeight: 200 }}
        >
          {options.map((opt) => {
            const selected = selection.includes(opt.key)
            return (
              <div
                key={opt.key}
                className={classNames('mb-3', styles.filter, {
                  [styles.unset]: opt.key === UNSET_KEY,
                })}
                onClick={() => {
                  if (selected) {
                    onSelectionChange(selection.filter((s) => s !== opt.key))
                  } else {
                    onSelectionChange(selection.concat(opt.key))
                  }
                }}
                role="button"
              >
                <div
                  className={classNames(
                    'd-flex align-items-center text-secondary',
                    {
                      'text-dark': selected,
                    }
                  )}
                >
                  <div className="text-truncate me-1">
                    <small>{opt.label}</small>
                  </div>
                  <div
                    className={classNames(
                      'ms-auto text-secondary',
                      styles.count,
                      {
                        'text-dark': selected,
                      }
                    )}
                  >
                    {crossCounts[opt.key] ?? 0}
                    {'/'}
                    {opt.count}
                  </div>
                </div>
                <div className="position-relative bg-light overflow-hidden">
                  <div
                    className={styles.mainFilterBar}
                    style={{
                      width: `${(opt.count / extent) * 100}%`,
                    }}
                  ></div>
                  <div
                    className={classNames(styles.crossFilterBar, {
                      [styles.barSelected]: selected,
                    })}
                    style={{
                      width: `${((crossCounts[opt.key] ?? 0) / extent) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
