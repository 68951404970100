import EazyAuth, { useAuthActions } from 'use-eazy-auth'
import { ConfigureRj } from 'react-rocketjump'
import api from './api'

const loginCall = (params) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh,
    }))
    .post('/api/token/', params)

const meCall = (token) => api.auth(token).get('/api/me')

const refreshTokenCall = (refreshToken) =>
  api
    .mapResponse((r) => ({
      refreshToken,
      accessToken: r.response.access,
    }))
    .post('/api/token/refresh', {
      refresh: refreshToken,
    })

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  return (
    <EazyAuth
      loginCall={loginCall}
      meCall={meCall}
      refreshTokenCall={refreshTokenCall}
    >
      <InnerAuth>{children}</InnerAuth>
    </EazyAuth>
  )
}

export default Auth
