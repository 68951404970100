import FilterFacetFieldWidget from './FilterFacetFieldWidget'

/**
 * @param {{
 * 	records: any[]
 * 	filters: Record<string, string[]>
 * 	onFiltersChange(filters: Record<string, string[]>): void
 *  onFilterRemoved?(field: string): void
 *  meta: { fields: Record<string, import('../../hooks/schema').OmekaFieldType> }
 * }}
 */
export default function FilterFacetFieldsWidget({
  records,
  filters,
  meta,
  onFiltersChange,
  onFilterRemoved,
}) {
  return Object.keys(filters).map((field) =>
    meta.fields[field] === 'facet' ? (
      <FilterFacetFieldWidget
        key={field}
        records={records}
        field={field}
        selection={filters[field]}
        onRemove={
          onFilterRemoved
            ? () => {
                onFilterRemoved(field)
              }
            : null
        }
        onSelectionChange={(selection) =>
          onFiltersChange({
            ...filters,
            [field]: selection,
          })
        }
        allFilters={filters}
      />
    ) : null
  )
}
