import { createElement } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import VIZ_COMPONENTS from '../../components/Viz'
import { useViz } from '../../hooks/viz'
import { getStrPropInLang } from '../../utils'
import PageError from '../PageError'
import classNames from 'classnames'

export default function Viewer() {
  const { id } = useParams()
  const [{ data, error }] = useViz(id)

  if (error) {
    return <PageError error={error} />
  }

  return (
    <div className="vh-100 padding-for-fixed-nav bg-background-pr">
      <Layout fixedNav>
        {data && (
          <div className="h-100">
            <div
              className="container py-4"
              dir={data.default_language === 'en' ? 'lft' : 'rtl'}
            >
              {getStrPropInLang(data, 'author', data.default_language) && (
                <div className="row border-bottom">
                  <div className="col-12">
                    <p className="mb-1">
                      <small>
                        Visualization and research by{' '}
                        <span className="text-primary-pr">
                          {getStrPropInLang(
                            data,
                            'author',
                            data.default_language
                          )}
                        </span>
                      </small>
                    </p>
                  </div>
                </div>
              )}
              <div className="row mt-2">
                <div className="col-5">
                  <h1 className="fw-normal" style={{ fontSize: '1.75rem' }}>
                    {getStrPropInLang(data, 'title', data.default_language)}
                  </h1>
                </div>
                <div className="col-6 offset-1">
                  <p>
                    <small>
                      {getStrPropInLang(
                        data,
                        'description',
                        data.default_language
                      )}
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 border-top"></div>
            <div
              className={classNames('h-100', {
                container:
                  data.viz_type !== 'map' && data.viz_type !== 'network',
              })}
            >
              {VIZ_COMPONENTS[data.viz_type] &&
                createElement(VIZ_COMPONENTS[data.viz_type], {
                  query: data.query,
                  records: data.records,
                  meta: data.meta,
                  options: data.viz_options,
                  language: data.default_language,
                  timelineData: data.timeline_data,
                  viewer: true,
                })}
            </div>
          </div>
        )}
      </Layout>
    </div>
  )
}
