import classNames from 'classnames'

const FOOTER_HEIGHT = 60

const FIXED_STYLE = {
  zIndex: 10,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: FOOTER_HEIGHT,
}

/**
 * @param {{
 *  children: import('react').ReactChildren
 *  className: string?
 *  fixed?: boolean
 *  footer?: import('react').ReactNode
 * }} props
 */
export default function WrapWithEditorFooter({
  children,
  className = '',
  footer,
  fixed = false,
}) {
  return (
    <div
      className={classNames('h-100', {
        'd-flex flex-column': !fixed,
      })}
      style={fixed ? { marginBottom: FOOTER_HEIGHT + 30 } : undefined}
    >
      {children}
      <div
        className={`bg-light text-white d-flex align-items-center px-3 border-top ${className}`}
        style={fixed ? FIXED_STYLE : { height: FOOTER_HEIGHT }}
      >
        {footer}
      </div>
    </div>
  )
}
