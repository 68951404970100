import React, { Fragment } from 'react'
import { AxisTop } from '@visx/axis'
import { GridColumns } from '@vx/grid'
import { ParentSize } from '@visx/responsive'
import { extent, ascending, rollups, max, sum } from 'd3-array'
import { scaleLinear, scaleTime } from 'd3-scale'
import { useMemo } from 'react'
import { localPoint } from '@vx/event'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getInLang, multiDateParse, stackeEvents } from '../../../utils'
import GanttBars from './GanttBars'
import GroupAxis from './GroupAxis'
import { invertName } from '../../../utils'
import styles from './GanttChart.module.scss'

const TIME_FROM_FIELD = 'from year or date'
const TIME_TO_FIELD = 'to year or date'
const GROUP_TYPES = ['life event type', 'literary event type', 'activity type']

const BAR_HEIGHT = 12
const BAR_Y_PADDING = 25
const GROUP_PADDING = 30
const MARGINS = {
  top: 10,
  right: 15,
  bottom: 10,
  left: 15,
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 *  width: number
 * }}
 */
function GanttChartComponent({ records, options, width, language }) {
  /**
   * @type [{ from: number | null, to: number | null }]
   */

  const timeList = useMemo(() => {
    let outList = records.map((record) => {
      // TODO: Avoid hardcoding?
      if (record.data[TIME_FROM_FIELD] || record.data[TIME_TO_FIELD]) {
        const from = multiDateParse(getInLang(record.data[TIME_FROM_FIELD]))
        const to = multiDateParse(getInLang(record.data[TIME_TO_FIELD]))
        let eventType = undefined
        GROUP_TYPES.forEach((g) => {
          const t = getInLang(record.data[g])
          if (t) {
            if (Array.isArray(t)) {
              eventType = t[0]
            } else {
              eventType = t
            }
          }
        })

        if (from) {
          let newLabel = {}
          if (record.item === 'Life event') {
            for (const [key, value] of Object.entries(record.label)) {
              newLabel[key] = invertName(value, key)
            }
          } else {
            newLabel = record.label
          }
          const timeRecord = {
            id: record.id,
            label: newLabel,
            from: from,
            to: to ?? from,
            record: record.item,
            event_type: eventType,
            type: 'span',
          }
          if (timeRecord.from.getTime() === timeRecord.to.getTime()) {
            timeRecord.type = 'point'
          }
          return timeRecord
        }
      }
    })

    return outList.filter((d) => d).sort((a, b) => ascending(a.from, b.from))
  }, [records])

  const groupTimeList = useMemo(() => {
    if (options.group_by_event_type) {
      return rollups(
        timeList,
        (v) => {
          if (options.compact_view) {
            return stackeEvents(v, 'from', 'to')
          } else {
            return v
          }
        },
        (d) => d.record,
        (d) => d.event_type
      )
    } else {
      return rollups(
        timeList,
        (v) => {
          if (options.compact_view) {
            return stackeEvents(v, 'from', 'to')
          } else {
            return v
          }
        },
        (d) => d.record
      )
    }
  }, [timeList, options.group_by_event_type, options.compact_view])

  const height = useMemo(() => {
    let count = sum(groupTimeList, (d) => d[1].length)
    let groupsPadding = groupTimeList.length * GROUP_PADDING

    if (options.compact_view && !options.group_by_event_type) {
      count = sum(groupTimeList, (d) => max(d[1], (f) => f.yIndex) + 1)
    }
    if (options.group_by_event_type) {
      const groups = sum(groupTimeList, (d) => d[1].length)
      groupsPadding =
        (groupTimeList.length + groups) * GROUP_PADDING - groupsPadding
      count = 0
      groupTimeList.forEach((g) => {
        count =
          count +
          sum(g[1], (d) =>
            options.compact_view ? max(d[1], (f) => f.yIndex) + 1 : d[1].length
          )
      })
    }
    return (
      count * (BAR_Y_PADDING + BAR_HEIGHT) +
      MARGINS.top +
      MARGINS.bottom +
      groupsPadding
    )
  }, [groupTimeList, options])

  const chartHeight = useMemo(() => {
    return height - MARGINS.top - MARGINS.bottom
  }, [height])

  const chartWidth = useMemo(() => {
    return width - MARGINS.left - MARGINS.right
  }, [width])

  const yearsDomain = useMemo(() => {
    return extent([
      ...extent(timeList, (t) => t.from),
      ...extent(timeList, (t) => t.to),
    ])
  }, [timeList])

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const tickLabelProps = () => ({
    fontSize: '0.75rem',
    fontFamily: 'Graphik Arabic',
    textAnchor: 'middle',
    y: -5,
  })

  return (
    <div className="py-3">
      <div style={{ marginLeft: MARGINS.left }} className="d-flex">
        <small>From / to date</small>
        <i className="ms-2 bi-arrow-right"></i>
      </div>
      <div>
        <svg width={width} height={20} className="sticky-top">
          <rect height={20} width={width} fill={'#f6f5f1'}></rect>
          <AxisTop
            scale={xScale}
            top={20}
            left={MARGINS.left}
            hideTicks={true}
            tickLength={0}
            stroke={'#ccc'}
            tickLabelProps={tickLabelProps}
          />
        </svg>
        <svg width={width} height={height} id="viz">
          <rect width={width} height={height} fill="#f6f5f1"></rect>
          <GridColumns
            scale={xScale}
            width={chartWidth}
            height={chartHeight}
            left={MARGINS.left}
            top={MARGINS.top}
            stroke={'#ccc'}
            strokeDasharray={(3, 3)}
          />
          <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
            {groupTimeList.map((recordType, i) => {
              const offset = sum(groupTimeList.slice(0, i), (d) => {
                return options.group_by_event_type
                  ? options.compact_view
                    ? sum(d[1], (f) => max(f[1], (g) => g.yIndex) + 1)
                    : sum(d[1], (f) => f[1].length)
                  : options.compact_view
                  ? max(d[1], (f) => f.yIndex) + 1
                  : d[1].length
              })

              const countGroups = options.group_by_event_type
                ? sum(groupTimeList.slice(0, i), (d) => d[1].length)
                : 0

              const countPrimaryGroups = options.group_by_event_type
                ? 0
                : (i + 1) * GROUP_PADDING

              return (
                <g
                  key={recordType[0]}
                  transform={`translate(0, ${
                    (BAR_Y_PADDING + BAR_HEIGHT) * offset +
                    countGroups * GROUP_PADDING +
                    countPrimaryGroups
                  })`}
                >
                  {!options.group_by_event_type && (
                    <g transform="translate(-1,0)">
                      <rect
                        x={0}
                        width={chartWidth + 2}
                        height={GROUP_PADDING}
                        y={-GROUP_PADDING}
                        fill={'#f6f5f1'}
                      ></rect>
                      <text
                        dy={-GROUP_PADDING / 2}
                        fontSize="0.875rem"
                        fontWeight="bold"
                        fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                      >
                        {recordType[0].toUpperCase()}
                      </text>
                      <line
                        x1={0}
                        x2={chartWidth}
                        y1={-8}
                        y2={-8}
                        stroke={'#ccc'}
                      ></line>
                    </g>
                  )}

                  {options.group_by_event_type ? (
                    recordType[1].map((eventGroup, eIndex) => {
                      const offset2 = sum(recordType[1].slice(0, eIndex), (d) =>
                        options.compact_view
                          ? max(d[1], (f) => f.yIndex) + 1
                          : d[1].length
                      )

                      return (
                        <g
                          key={recordType[0] + eventGroup[0]}
                          transform={`translate(0, ${
                            (BAR_Y_PADDING + BAR_HEIGHT) * offset2 +
                            GROUP_PADDING * (eIndex + 1)
                          })`}
                        >
                          <g transform="translate(-1,0)">
                            <rect
                              x={0}
                              width={chartWidth + 2}
                              height={GROUP_PADDING - 8}
                              y={-GROUP_PADDING}
                              fill={'#f6f5f1'}
                            ></rect>
                            <text
                              dy={-GROUP_PADDING / 2}
                              fontSize="0.875rem"
                              fontWeight="bold"
                              fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                            >
                              {eventGroup[0]
                                ? eventGroup[0].toUpperCase()
                                : eventGroup[0]}
                              <tspan fill={'#ccc'} dx={8}>
                                {recordType[0].toUpperCase()}
                              </tspan>
                            </text>
                            <line
                              x1={0}
                              x2={chartWidth}
                              y1={-8}
                              y2={-8}
                              stroke={'#ccc'}
                            ></line>
                          </g>
                          <GanttBars
                            records={eventGroup[1]}
                            options={options}
                            barHeight={BAR_HEIGHT}
                            barYPadding={BAR_Y_PADDING}
                            chartWidth={chartWidth}
                            xScale={xScale}
                            language={language}
                          ></GanttBars>
                        </g>
                      )
                    })
                  ) : (
                    <GanttBars
                      records={recordType[1]}
                      options={options}
                      barHeight={BAR_HEIGHT}
                      barYPadding={BAR_Y_PADDING}
                      chartWidth={chartWidth}
                      xScale={xScale}
                      language={language}
                    ></GanttBars>
                  )}
                </g>
              )
            })}
          </g>
        </svg>
      </div>
    </div>
  )
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 * }}
 */
export default function GanttChart(props) {
  return (
    <ParentSize>
      {({ width }) =>
        width > 0 ? <GanttChartComponent {...props} width={width} /> : null
      }
    </ParentSize>
  )
}
