import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ActionErrorHandlerContext } from '../hooks/error'

export default function ActionErrorHandler({ children }) {
  const [errorModal, errorModalActions] = useModalTrigger()
  const ctxValue = useMemo(
    () => ({
      handleError: errorModalActions.open,
    }),
    [errorModalActions.open]
  )
  return (
    <>
      <Modal
        show={errorModal.isOpen}
        onHide={errorModalActions.toggle}
        onExiting={errorModalActions.onClosed}
      >
        <Modal.Header closeButton className="bg-danger text-white">
          <Modal.Title>Ops, got an error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-danger text-white">
          An error occurred while performing this action.
        </Modal.Body>
        <Modal.Footer className="bg-danger">
          <Button variant="secondary" onClick={errorModalActions.toggle}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <ActionErrorHandlerContext.Provider value={ctxValue}>
        {children}
      </ActionErrorHandlerContext.Provider>
    </>
  )
}
