import { Field } from 'formik'
import { useMemo } from 'react'
import * as yup from 'yup'
import MultiBoxSelectField from '../../Form/MultiBoxSelectField'

export const defaultValues = {
  columns: [],
  active_filters: [],
}

export const ValidationSchema = {
  columns: yup.mixed(),
}

/**
 * @param {{ ns: string, meta: Record<string, any> }} props
 */
export function FieldSet({ ns, meta }) {
  const colOptions = useMemo(
    () =>
      Object.keys(meta.fields)
        .filter((field) => !['name', 'title'].includes(field))
        .sort(),
    [meta.fields]
  )
  return (
    <div>
      <Field
        options={colOptions}
        name={`${ns}.columns`}
        component={MultiBoxSelectField}
        label="Columns"
        className="mb-3"
      />
    </div>
  )
}
