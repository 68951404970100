import { useState } from 'react'
import classNames from 'classnames'
import { getInLang } from '../../../utils'
import styles from './InfoCardViz.module.scss'

/**
 * @param {string[] | string} value
 * @returns string
 */
function displayArryOrLiteral(value) {
  return Array.isArray(value) ? value.join(' ') : value
}

/**
 * @param {{
 * 	person: any
 *  language: 'en' | 'ar'
 * }}
 */

const whitelistField = [
  'name',
  'pen name',
  'gender',
  'religion',
  'country of citizenship',
  'birth date',
  'birth city',
  'death date',
  'death city',
  'manner of death',
  'cause of death',
  'friend',
  'influenced by',
  'literary movement',
  'mother',
  'father',
  'sibling',
  'spouse',
  'child',
]
export default function PersonCard({ person, language, meta }) {
  const [tableOpen, setTableOpen] = useState(false)

  const toggleTable = () => {
    setTableOpen(!tableOpen)
  }

  return (
    <div className="my-3">
      <h4 className="border-bottom my-3 pb-1">General information</h4>
      <div
        className={classNames(styles.tableContainer, {
          [styles.open]: tableOpen,
        })}
      >
        <table className="table table-sm align-middle table-borderless">
          <tbody>
            {Object.keys(person.data).map(
              (field) =>
                meta.fields[field] !== 'network' && (
                  <tr key={field}>
                    <td
                      className={classNames(
                        'text-uppercase fw-bold',
                        styles.tableTextTitle
                      )}
                    >
                      {field}
                    </td>
                    <td
                      className={classNames(
                        styles.tableTextProp,
                        'ZaridSerif',
                        {
                          'text-end': language === 'ar',
                        }
                      )}
                    >
                      {displayArryOrLiteral(
                        getInLang(person.data[field], language)
                      )}
                    </td>
                  </tr>
                )
            )}
            {Object.keys(person.expanded_relations).map(
              (field) =>
                whitelistField.includes(field) && (
                  <tr key={field}>
                    <td
                      className={classNames(
                        'text-uppercase fw-bold',
                        styles.tableTextTitle
                      )}
                    >
                      {field}
                    </td>
                    <td
                      className={classNames(
                        styles.tableTextProp,
                        'ZaridSerif',
                        {
                          'text-end': language === 'ar',
                        }
                      )}
                    >
                      {person.expanded_relations[field].results.map(
                        (result) => (
                          <span key={result.id}>
                            {displayArryOrLiteral(
                              getInLang(result.label, language)
                            )}
                            <br />
                          </span>
                        )
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <div
        className={classNames(
          styles.read,
          'd-flex align-items-center justify-content-center text-success'
        )}
        onClick={toggleTable}
      >
        <div>read {tableOpen ? 'less' : 'more'}</div>{' '}
        <i className={`ms-1 ${tableOpen ? 'bi-dash' : 'bi-plus'}`}></i>
      </div>
    </div>
  )
}
