import { Field } from 'formik'
import * as yup from 'yup'
import CheckField from '../../Form/CheckField'
import ColorField from '../../Form/ColorField'
import InputField from '../../Form/InputField'

export const defaultValues = {
  items_color: '#4f7c4c',
  cluster_items: true,
  show_contextual_timeline: false,
  map_style: 'light',
  active_filters: [],
}

export const ValidationSchema = {
  items_color: yup.string().required().label('Items Color'),
  map_style: yup.string().required().label('Map Style'),
  show_contextual_timeline: yup.boolean(),
  cluster_items: yup.boolean(),
}

/**
 * @param {{ ns: string }} props
 */
export function FieldSet({ ns }) {
  return (
    <div>
      <Field
        name={`${ns}.items_color`}
        component={ColorField}
        label="Items Color"
        className="mb-3"
      />
      <Field
        label="Map Style"
        name={`${ns}.map_style`}
        component={InputField}
        renderAs="select"
        className="mb-3 form-select"
      >
        <option value="light">Light</option>
        <option value="pre-48-light">Pre 48 light</option>
        <option value="dark">Dark</option>
        <option value="pre-48-dark">Pre 48 dark</option>
      </Field>
      <Field
        name={`${ns}.cluster_items`}
        component={CheckField}
        label="Cluster Items"
        className="mb-3"
      />
    </div>
  )
}
