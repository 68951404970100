import { useMemo } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import find from 'lodash/find'
import { VIZ_TYPES } from '../../consts.js'
import styles from './VizPresetCard.module.scss'
/**
 *
 * @param {{
 *  viz: any
 *  onRemoveFromPreset(viz: any): void
 *  onCreateViz(viz: any): void
 * }}
 */
export default function VizPresetCard({
  viz,
  onRemoveFromPreset,
  onCreateViz,
}) {
  const vizTypeConfig = useMemo(
    () => find(VIZ_TYPES, { value: viz.viz_type }),
    [viz.viz_type]
  )
  return (
    <div
      className={classNames(
        'w-100 mb-3 d-flex flex-column position-relative rounded overflow-hidden border bg-white'
      )}
    >
      <div
        className={classNames(
          'd-inline-flex align-items-center position-relative text-body px-3 pt-2'
        )}
      >
        <i className={classNames(styles.icon, vizTypeConfig.icon)}></i>
        <span className="ms-2">
          <small>{vizTypeConfig.label}</small>
        </span>
      </div>
      <div className="flex-grow-1 flex-shink-1 p-3 position-relative">
        <h5 className={(classNames(styles.title), 'GraphikArabic')}>
          <Link
            to={`/editor/viz/${viz.id}`}
            className="text-decoration-none text-body"
          >
            {viz.title_en || viz.title_ar}
          </Link>
        </h5>
        {/* <p className={(classNames(styles.title), 'mb-1')}>
          {viz.description_en || viz.description_ar}
        </p> */}
      </div>
      <div className="p-3 flex-grow-0 flex-shrink-0 mt-auto d-flex align-items-center justify-content-end">
        <button
          className="btn btn-link  text-body me-1"
          onClick={() => {
            onCreateViz(viz)
          }}
        >
          <i className="bi-layers"></i>
        </button>
        <button
          className="btn btn-link  text-body"
          onClick={() => {
            onRemoveFromPreset(viz)
          }}
        >
          <i className="bi-trash-fill"></i>
        </button>
      </div>
    </div>
  )
}
