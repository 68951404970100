import uniq from 'lodash/uniq'
import classNames from 'classnames'
import { memo, useCallback, useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { useEditorSchema } from '../../hooks/schema'
import { isGoodFieldForFilter } from '../../query'
import '../../query-types'
import DropdownSelect from './../DropdownSelect'
import QueryInput from './QueryInput'

/**
 * @param {{
 *  query: Query
 *  fields: string[]
 *  items: string[]
 *  index: number
 *  onRemove(index: number): void
 *  onUpdate(index: number, query: Query | (q: Query) => Query): void
 * }} props
 */
let QueryField = ({ items, query, fields, index, onRemove, onUpdate }) => {
  const schema = useEditorSchema()
  const fieldInfo = schema.fieldByLabel[query.field]

  const isEffective = useMemo(
    () => (query.field === null ? true : fields.includes(query.field)),
    [fields, query.field]
  )

  const subFields = useMemo(() => {
    if (fieldInfo?.type === 'network') {
      const relatedItems = uniq(
        schema.ontology
          .filter(
            (triple) =>
              triple.verb === fieldInfo.label && items.includes(triple.subject)
          )
          .map((triple) => triple.subject2)
      ).filter(Boolean)
      /** @type string[] **/
      const finalFields = []
      return uniq(
        relatedItems
          .reduce((ff, item) => {
            ff.push(
              ...schema.fieldsByItem[item]
                .filter((f) => f.type !== 'network' && isGoodFieldForFilter(f))
                .map((f) => f.label)
            )
            return ff
          }, finalFields)
          .sort()
      )
    }
    return []
  }, [fieldInfo, items, schema])

  const onSubAdd = useCallback(
    /**
     * @param {BaseQuery} newQuery
     */
    () =>
      onUpdate(index, (query) => ({
        ...query,
        subqueries: query.subqueries.concat({
          field: null,
          expression: null,
          value: null,
        }),
      })),
    [index, onUpdate]
  )

  const onSubRemove = useCallback(
    /**
     * @param {number} subIndex
     */
    (subIndex) =>
      onUpdate(index, (query) => {
        const nextSub = [...query.subqueries]
        nextSub.splice(subIndex, 1)
        return {
          ...query,
          subqueries: nextSub,
        }
      }),
    [index, onUpdate]
  )

  const onSubUpdate = useCallback(
    /**
     * @param {number} subIndex
     * @param {BaseQuery | (q: BaseQuery) => BaseQuery} subQueryOrFn
     */
    (subIndex, subQueryOrFn) =>
      onUpdate(index, (query) => {
        const nextSub = [...query.subqueries]
        if (typeof subQueryOrFn === 'function') {
          nextSub[subIndex] = subQueryOrFn(nextSub[index])
        } else {
          nextSub[subIndex] = subQueryOrFn
        }
        return {
          ...query,
          subqueries: nextSub,
        }
      }),
    [index, onUpdate]
  )

  return (
    <div
      className={classNames('border p-4 rounded mb-2 bg-white', {
        'border-warning': !isEffective,
      })}
    >
      <div className="d-flex justify-content-between align-items-center">
        <DropdownSelect
          label="With"
          labelPosition="left"
          placeholder="select a property"
          isEffective={isEffective}
          options={fields}
          value={query.field}
          onChange={(value) => {
            const nextFieldInfo = schema.fieldByLabel[value]
            onUpdate(index, {
              field: value,
              // Reset other properties when field change
              value: ['text'].includes(nextFieldInfo.type)
                ? [{ text: '', expression: 'equal' }]
                : null,
              expression: ['date'].includes(nextFieldInfo.type)
                ? 'equal'
                : null,
              subqueries: [],
            })
          }}
        />
        <div>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => onRemove(index)}
          >
            <i className="bi-trash"></i>
          </Button>
        </div>
      </div>
      <div className="mt-2">
        {fieldInfo && fieldInfo?.type !== 'network' && (
          <QueryInput
            field={query.field}
            value={query.value}
            type={fieldInfo.type}
            onChange={(value) => {
              onUpdate(index, {
                ...query,
                value,
              })
            }}
            expression={query.expression}
            onExpressionChange={(expression) => {
              onUpdate(index, {
                ...query,
                expression: expression,
              })
            }}
          />
        )}
      </div>
      {fieldInfo?.type === 'network' && (
        <QueryBuilder
          fields={subFields}
          queries={query.subqueries}
          onAdd={onSubAdd}
          onRemove={onSubRemove}
          onUpdate={onSubUpdate}
        />
      )}
    </div>
  )
}
// NOTE: Yes this pattern sucks a lot =)
// (But is the only solution to avoid loosing props js declarations)
QueryField = memo(QueryField)

/**
 * @param {{
 *  fields: string[]
 *  queries: Query[]
 *  items: string[]
 *  onAdd(): void
 *  onRemove(index: number): void
 *  onUpdate(index: number, query: Query | (q: Query) => Query): void
 * }} props
 */
function QueryBuilder({
  items = [],
  fields,
  queries,
  onAdd,
  onRemove,
  onUpdate,
}) {
  return (
    <div className="mt-3">
      {queries.map((query, index) => (
        <QueryField
          items={items}
          fields={fields}
          query={query}
          key={index}
          index={index}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      ))}
      <div className="text-center">
        <Button variant="outline-secondary" onClick={() => onAdd()}>
          <i className="bi-funnel"></i> Add Filter
        </Button>
      </div>
    </div>
  )
}

export default memo(QueryBuilder)
