import { getIn } from 'formik'
import { Form } from 'react-bootstrap'

/**
 * @param {import("formik").FieldProps & import('react').InputHTMLAttributes & {
 *  label?: string
 *  renderAs?: string
 * }} props
 */
export default function InputField({ field, form, label, renderAs, ...props }) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...props}
        {...field}
        as={renderAs}
        isInvalid={touch && !!error}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  )
}
