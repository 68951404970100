import { AuthRoute, GuestRoute, MaybeAuthRoute } from 'use-eazy-auth/routes'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import ActionErrorHandler from './components/ActionErrorHandler'
import Auth from './Auth'
import Login from './pages/Login'
import Home from './pages/Home'
import Viewer from './pages/Viewer'
import Editor from './pages/Editor'
import PageNotFound from './pages/PageNotFound'
import ErrorBoundary from './components/ErrorBoundary'

function App() {
  return (
    <ErrorBoundary>
      <Auth>
        <ActionErrorHandler>
          <Router>
            <Switch>
              <GuestRoute path="/login" exact>
                <Login />
              </GuestRoute>
              <AuthRoute path="/" exact>
                <Home />
              </AuthRoute>

              <AuthRoute path="/editor">
                <Editor />
              </AuthRoute>

              <MaybeAuthRoute path="/view/:id" exact>
                <Viewer />
              </MaybeAuthRoute>

              <MaybeAuthRoute>
                <PageNotFound />
              </MaybeAuthRoute>
            </Switch>
          </Router>
        </ActionErrorHandler>
      </Auth>
    </ErrorBoundary>
  )
}

export default App
