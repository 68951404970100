import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { createContext, useContext, useMemo } from 'react'
import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'
import Layout from '../components/Layout'

const EditorSchemaState = rj({
  name: 'EditorSchema',
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get('/api/editor-schema'),
})

/**
  Some useful type to Y life painless

  @typedef {
      'date'
    | 'text'
    | 'external link'
    | 'network'
    | 'facet'
    | 'geo'
  } OmekaFieldType

  @typedef {{
    item: string
    label: string
    local_name: string
    type: OmekaFieldType
  }} OmekaField

  @typedef {{
    subject: string
    verb: string
    subject2: string
  }} OntologyLine
*/

/**
 *
 * @returns {{
 *  fields: OmekaField[]
 *  ontology: OntologyLine[]
 * }}
 */
export function useGetEditorSchema() {
  return useRunRj(EditorSchemaState)[0].data
}

const EditorSchemaContext = createContext(null)

/**
 * @param {{
 *  children: import('react').ReactChildren
 * }} props
 */
export function EditorSchemaProvider({ children }) {
  const rawSchema = useGetEditorSchema()

  const editorSchema = useMemo(() => {
    if (!rawSchema) {
      return null
    }
    const { fields, ontology } = rawSchema

    const excludeTypes = ['date', 'external link', 'text']
    const excludeLabels = ['part of', 'has part', 'subject of']
    const keepLabels = ['name', 'title']
    const networkFields = fields.filter(
      (f) =>
        keepLabels.includes(f.label) ||
        (!excludeTypes.includes(f.type) && !excludeLabels.includes(f.label))
    )

    return {
      fields,
      /** @type {Record<OmekaFieldType, OmekaField[]>} **/
      fieldsByType: groupBy(fields, 'type'),
      /** @type {Record<string, OmekaField>} **/
      fieldByLabel: keyBy(fields, 'label'),
      /** @type {Record<string, OmekaField[]>} **/
      fieldsByItem: groupBy(fields, 'item'),
      networkFields,
      ontology,
    }
  }, [rawSchema])

  // TODO: Show a very cool spinner inside Layout
  if (!editorSchema) {
    return <Layout />
  }

  return (
    <EditorSchemaContext.Provider value={editorSchema}>
      {children}
    </EditorSchemaContext.Provider>
  )
}

/**
 * @returns {{
 *  fields: OmekaField[]
 *  fieldsByType: Record<OmekaFieldType, OmekaField[]>
 *  fieldByLabel: Record<string, OmekaField>
 *  fieldsByItem: Record<string, OmekaField[]>
 *  networkFields: OmekaField[]
 *  ontology: OntologyLine[]
 * }}
 */
export function useEditorSchema() {
  return useContext(EditorSchemaContext)
}
