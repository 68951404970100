import { useCallback, useMemo, useReducer } from 'react'
import isDeepEqual from 'fast-deep-equal'
import { useEditorSchema } from '../../../hooks/schema'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { QueryBuilderContainer } from '../QueryBuilderContainer'

/**
 * @typedef {{
 *  queries: Query[]
 *  items: string[]
 * }} ListQueryState
 *
 * @param {ListQueryState} state
 * @param {
    QueryActions
    | { type: 'UPDATE_ITEMS', payload: string[] }
    | { type: 'RESET' }
  } action
 * @returns {ListQueryState}
 */
function listVizReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'RESET':
      return defaultState
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

/**
 * @type ListQueryState
 */
const defaultState = {
  items: [],
  queries: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {ListQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function ListQueryEditor({ initalState, onNext, vizId }) {
  const { fieldsByItem } = useEditorSchema()
  const [state, dispatch] = useReducer(listVizReducer, initalState, init)

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const allItems = useMemo(
    () => Object.keys(fieldsByItem).sort(),
    [fieldsByItem]
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)
  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      fields: [],
      with_expanded_relations: true,
    }),
    [state, allowedQueryFields]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {},
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <MultiBoxSelect
            label="Show the list of the following item"
            values={state.items}
            options={allItems}
            onChange={setItems}
          />
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
