import { Button } from 'react-bootstrap'
import StateDebugger from '../StateDebugger'
import { OMEKA_ITEMS_URL } from '../../consts'
import '../../query-types'
import { getInLang } from '../../utils'
import { usePaginator } from '../../hooks/paginator'
import Paginator from '../Paginator'
import { useActionErrorHandler } from '../../hooks/error'
import SortIcon from '../SortIcon'

function mapResultRow(row) {
  return {
    ...row,
    label: getInLang(row.label),
  }
}

/**
 * @param {{
 *  rows: any[] | null
 *  toggle(id: number): void
 *  excludeIds: Record<string, boolean>
 *  query: ComplexQuery
 *  untoggleAll(): void
 *  toggleAll(): void
 * }} props
 */
function TableResults({ rows, toggle, excludeIds, untoggleAll, toggleAll }) {
  const [
    paginatedRows,
    { page, setPage, numPages, orderByState, toggleOrderBy },
  ] = usePaginator(rows, 25, mapResultRow)
  const noChecks = Object.keys(excludeIds).length === 0
  return (
    <div>
      <table className="table table-borderless mt-3 bg-background-pr table-striped">
        <thead>
          <tr>
            <th scope="col">
              <input
                type="checkbox"
                checked={noChecks}
                onChange={() => {
                  if (noChecks) {
                    untoggleAll()
                  } else {
                    toggleAll()
                  }
                }}
              />
            </th>
            <th scope="col">#</th>
            <th
              scope="col"
              className="cursor-pointer"
              onClick={() => toggleOrderBy('item')}
            >
              Type <SortIcon direction={orderByState.item} />
            </th>
            <th
              scope="col"
              className="cursor-pointer"
              onClick={() => toggleOrderBy('label')}
            >
              Label <SortIcon direction={orderByState.label} />
            </th>
            <th
              scope="col"
              className="cursor-pointer"
              onClick={() => toggleOrderBy('id')}
            >
              Id <SortIcon direction={orderByState.id} />
            </th>
            {/* {query.fields.map((field) => (
                    <th key={field}>{field}</th>
                  ))} */}
          </tr>
        </thead>
        <tbody>
          {paginatedRows.map((row, i) => (
            <tr key={row.id}>
              <td>
                <input
                  onChange={() => toggle(row.id)}
                  type="checkbox"
                  checked={!excludeIds[row.id]}
                />
              </td>
              <td>{i + 1 + (page - 1) * 25}</td>
              <td>{row.item}</td>
              <td>{row.label}</td>
              <td>
                {row.id}{' '}
                <a
                  href={OMEKA_ITEMS_URL + row.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi-box-arrow-up-right"></i>
                </a>
              </td>
              {/* {query.fields.map((field) => (
                      <td key={field}>{getInLang(row.data[field])}</td>
                    ))} */}
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator goToPage={setPage} currentPage={page} numPages={numPages} />
    </div>
  )
}

/**
 * @param {{
 *  rows: any[] | null
 *  runQuery(q: ComplexQuery): void
 *  loading: boolean
 *  query: ComplexQuery
 *  excludeIds: Record<string, boolean>
 *  toggle(id: number): void
 *  untoggleAll(): void
 *  toggleAll(): void
 * }} props
 */
export default function QueryRunner({
  rows,
  runQuery,
  query,
  excludeIds,
  toggle,
  loading,
  toggleAll,
  untoggleAll,
}) {
  const handleActionError = useActionErrorHandler()
  return (
    <div>
      <div className="border-bottom">
        <h5 className="GraphikArabic">Data Preview</h5>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Button
            className="mt-3"
            disabled={loading || query.items.length === 0}
            variant="outline-secondary"
            onClick={() => {
              runQuery.onFailure(handleActionError).run(query)
            }}
          >
            <i className="bi-gear"></i> Run Test Query
          </Button>
          <div className="mt-3">
            {rows && rows.length && (
              <h6 className="GraphikArabic">
                Total results: <b>{rows.length}</b> records
              </h6>
            )}
          </div>
        </div>
        <div className="col-md-9 border-start">
          {rows && rows.length > 0 ? (
            <TableResults
              rows={rows}
              excludeIds={excludeIds}
              query={query}
              toggle={toggle}
              toggleAll={toggleAll}
              untoggleAll={untoggleAll}
            />
          ) : (
            <div className="text-muted text-center pt-3">
              <p>try to run query to see results</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
