import { AxisTop } from '@visx/axis'
import { GridColumns } from '@vx/grid'
import { Bar } from '@visx/shape'
import { extent, ascending } from 'd3-array'
import { scaleLinear, scaleTime } from 'd3-scale'
import { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OMEKA_ITEMS_URL } from '../../../consts'
import { getInLang, multiDateParse } from '../../../utils'
import { useAuthState } from 'use-eazy-auth'
import styles from './ContextualTimeline.module.scss'

const TIME_FROM_FIELD = 'from year or date'
const TIME_TO_FIELD = 'to year or date'

const BAR_HEIGHT = 10
const BAR_Y_PADDING = 18
const MARGINS = {
  top: 35,
  right: 30,
  bottom: 10,
  left: 30,
}

export default function ContextualTimelineChart({
  width,
  data,
  language = 'en',
}) {
  const { authenticated } = useAuthState()
  const timeList = useMemo(() => {
    if (!data) return []
    const outList = []
    data.results.forEach((record) => {
      // TODO: Avoid hardcoding?
      if (record.data[TIME_FROM_FIELD] || record.data[TIME_TO_FIELD]) {
        const from = multiDateParse(getInLang(record.data[TIME_FROM_FIELD]))
        const to = multiDateParse(getInLang(record.data[TIME_TO_FIELD]))
        if (from) {
          const timeRecord = {
            id: record.id,
            label: record.label,
            from: from,
            to: to ?? from,
            record: record.item,
            type: 'span',
          }
          if (timeRecord.from.getTime() === timeRecord.to.getTime()) {
            timeRecord.type = 'point'
          }
          outList.push(timeRecord)
        }
      }
    })
    return outList.sort((a, b) => ascending(a.from, b.from))
  }, [data])

  const height =
    timeList.length * (BAR_Y_PADDING + BAR_HEIGHT) +
    MARGINS.top +
    MARGINS.bottom

  const chartHeight = height - MARGINS.top - MARGINS.bottom

  const chartWidth = useMemo(() => {
    return width - MARGINS.left - MARGINS.right
  }, [width])

  const yearsDomain = useMemo(
    () =>
      extent([
        ...extent(timeList, (t) => t.from),
        ...extent(timeList, (t) => t.to),
      ]),
    [timeList]
  )

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const tickLabelProps = () => ({
    fontSize: '0.75rem',
    fontFamily: 'Graphik Arabic',
    textAnchor: 'middle',
    y: -5,
  })

  return (
    <svg width={width} height={height}>
      <AxisTop
        scale={xScale}
        top={20}
        left={MARGINS.left}
        hideTicks={true}
        tickLength={0}
        stroke={'#ccc'}
        tickLabelProps={tickLabelProps}
      />
      <GridColumns
        scale={xScale}
        width={chartWidth}
        height={chartHeight}
        left={MARGINS.left}
        top={MARGINS.top}
        stroke={'#ccc'}
        strokeDasharray={(3, 3)}
      />
      <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
        {timeList.map((timeRecord, i) => {
          const barY = i * (BAR_HEIGHT + BAR_Y_PADDING) + BAR_Y_PADDING / 2
          const barX = xScale(timeRecord.from)
          const width =
            timeRecord.type === 'span'
              ? xScale(timeRecord.to) - barX < BAR_HEIGHT
                ? BAR_HEIGHT
                : xScale(timeRecord.to) - barX
              : BAR_HEIGHT / 2

          const textX = barX > chartWidth / 2 ? barX + width : barX
          const textAnchor = barX > chartWidth / 2 ? 'end' : 'start'
          return timeRecord.type === 'span' ? (
            <g key={timeRecord.id} className={styles.barsG}>
              {authenticated ? (
                <a
                  href={OMEKA_ITEMS_URL + timeRecord.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <text
                    x={textX - BAR_HEIGHT / 2}
                    y={barY}
                    dy={-4}
                    textAnchor={textAnchor}
                  >
                    {getInLang(timeRecord.label, language)}
                  </text>
                </a>
              ) : (
                <text
                  x={textX - BAR_HEIGHT / 2}
                  y={barY}
                  dy={-4}
                  textAnchor={textAnchor}
                >
                  {getInLang(timeRecord.label, language)}
                </text>
              )}

              <text
                x={xScale(timeRecord.from) - 3}
                y={barY + BAR_HEIGHT}
                dy={-2}
                textAnchor={'end'}
              >
                {timeRecord.from.getFullYear()}
              </text>

              <Bar
                x={barX}
                y={barY}
                width={width}
                height={BAR_HEIGHT}
                fill={'#ccc'}
                rx={BAR_HEIGHT / 2}
              />
              <text
                x={xScale(timeRecord.to) + 3}
                y={barY + BAR_HEIGHT}
                dy={-2}
                textAnchor={'start'}
              >
                {timeRecord.to.getFullYear()}
              </text>
            </g>
          ) : (
            <g key={timeRecord.id} className={styles.barsG}>
              {authenticated ? (
                <a
                  href={OMEKA_ITEMS_URL + timeRecord.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <text
                    x={textX - BAR_HEIGHT / 2}
                    y={barY}
                    dy={-4}
                    textAnchor={textAnchor}
                  >
                    {getInLang(timeRecord.label, language)}
                  </text>
                </a>
              ) : (
                <text
                  x={textX - BAR_HEIGHT / 2}
                  y={barY}
                  dy={-4}
                  textAnchor={textAnchor}
                >
                  {getInLang(timeRecord.label, language)}
                </text>
              )}
              <text
                x={xScale(timeRecord.from) - 3 - BAR_HEIGHT / 2}
                y={barY + BAR_HEIGHT}
                dy={-2}
                textAnchor={'end'}
              >
                {timeRecord.from.getFullYear()}
              </text>
              <circle
                cx={barX}
                cy={barY + BAR_HEIGHT / 2}
                r={BAR_HEIGHT / 2}
                fill={'#ccc'}
              ></circle>
            </g>
          )
        })}
      </g>
    </svg>
  )
}
