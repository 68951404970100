import classNames from 'classnames'
import { getIn } from 'formik'
import { useState } from 'react'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'

// TODO: Use real colors XD
const COLORS = ['#4f7c4c', '#b64747', '#deb145']

/**
 * @param {{ color: string }} props
 */
const ColorBox = ({ color }) => (
  <div
    className="text-dark d-flex align-items-center"
    style={{
      // NOTE: Evil trick to have the same width :D
      fontFamily: 'monospace',
    }}
  >
    <div
      className="me-2"
      style={{ height: 25, width: 25, backgroundColor: color }}
    />
    {color}
  </div>
)

/**
 * @param {import("formik").FieldProps  & {
 * 	placeholder? string
 *  label?: string
 * }} props
 */
export default function ColorField({
  field,
  form,
  label,
  placeholder = 'Choose a color',
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow((a) => !a)

  return (
    <div className="mb-3">
      {label && <div className="mb-1">{label}</div>}
      <Dropdown
        as={ButtonGroup}
        className="w-100"
        show={show}
        onToggle={setShow}
      >
        <Button
          variant="secondary"
          onClick={toggleShow}
          className={classNames('bg-white text-dark border text-left', {
            'border-danger': touch && !!error,
          })}
        >
          {field.value ? <ColorBox color={field.value} /> : placeholder}
        </Button>
        <Dropdown.Toggle
          variant="secondary"
          split
          onToggle={() => {}}
          style={{ flex: 'unset' }}
          className={classNames('bg-white text-dark border', {
            'border-danger': touch && !!error,
          })}
        />
        <Dropdown.Menu align="right" className="w-100">
          {COLORS.map((color) => (
            <Dropdown.Item
              key={color}
              onSelect={() => {
                field.onChange(field.name)(color)
              }}
              active={color === field.value}
            >
              <ColorBox color={color} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {touch && !!error && (
        <div className="text-danger">
          <small>{error}</small>
        </div>
      )}
    </div>
  )
}
