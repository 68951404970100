import { Field } from 'formik'
import * as yup from 'yup'
import ColorField from '../../Form/ColorField'
import InputField from '../../Form/InputField'

export const defaultValues = {
  node_size: 'degree',
  source_nodes_color: '#4f7c4c',
  target_nodes_color: '#deb145',
}

export const ValidationSchema = {
  node_size: yup.string().required().label('Node size'),
  source_nodes_color: yup.string().required().label('Source nodes color'),
  target_nodes_color: yup.string().required().label('Target nodes color'),
}

/**
 * @param {{ ns: string }} props
 */
export function FieldSet({ ns, query }) {
  return (
    <div>
      <Field
        label="Node Size"
        name={`${ns}.node_size`}
        component={InputField}
        renderAs="select"
        className="mb-3"
      >
        <option value="degree">Degree</option>
        <option value="inDegree">In degree</option>
        <option value="outDegree">Out degree</option>
      </Field>
      <Field
        name={`${ns}.source_nodes_color`}
        component={ColorField}
        label={`${query.ui.connectField} nodes color`}
        className="mb-3"
      />
      <Field
        name={`${ns}.target_nodes_color`}
        component={ColorField}
        label={`${query.ui.targetField} nodes color`}
        className="mb-3"
      />
    </div>
  )
}
