import { useCallback, useState, useRef, useEffect } from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import { ParentSize } from '@visx/responsive'
import { rgb, color } from 'd3-color'
import { useMemo } from 'react'
import { scaleLinear, scaleLog } from 'd3-scale'
import { extent } from 'd3-array'

function NetworkChartComponent({
  width,
  height,
  network,
  options,
  query,
  items,
}) {
  const graphRef = useRef()
  const [neighbors, setNeighbors] = useState(null)
  const [selectedNode, setSelectedNode] = useState(null)
  const [selectedLink, setSelectedLink] = useState(null)
  const [data, setData] = useState(network)
  const [zoom, setZoom] = useState(null)

  const mergeById = (a1, a2, prop = 'id') =>
    a1.map((itm) => ({
      ...a2.find((item) => item[prop] === itm[prop] && item),
      ...itm,
    }))

  const mergeByLink = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find(
        (item) =>
          item.source.id + '_' + item.target.id ===
            itm.source.id + '_' + itm.target.id && item
      ),
      ...itm,
    }))

  const colors = {
    [query['targetField']]: options.target_nodes_color,
    [query['connectField']]: options.source_nodes_color,
  }

  const strengthScale = useMemo(() => {
    const domain = extent(data.links.map((d) => d.attributes.weight))
    return scaleLog().domain(domain).range([0.1, 0.75])
  }, [data])

  const handleNodeHover = (node) => {
    setSelectedNode(node)
    setNeighbors(node?.neighbors?.map((d) => d).concat([node?.id]) || null)
  }

  const handleLinkHover = (link) => {
    setSelectedLink(link)
  }

  const handleZoom = (value) => {
    if (!graphRef.current) return

    const currentZoom = graphRef.current.zoom()
    graphRef.current.zoom(currentZoom + value, 200)
  }

  const nodeOpacity = useCallback(
    (node) => {
      const nodeColor = neighbors
        ? neighbors.includes(node.id)
          ? colors[node.data.type]
          : color(colors[node.data.type]).copy({ opacity: 0.1 })
        : colors[node.data.type]
      node.__hoverColor = nodeColor
      return nodeColor
    },
    [neighbors, colors]
  )

  const linkOpacity = useCallback(
    (link) => {
      if (selectedLink) {
        if (selectedLink.index === link.index) return 'rgba(204,204,204,0.9)'
      }
      if (!neighbors) {
        return 'rgba(204,204,204,0.5)'
      }
      const isSource = query['connectField'] === selectedNode.data.type
      return neighbors.includes(link[`${isSource ? 'source' : 'target'}`].id)
        ? 'rgba(204,204,204,0.75)'
        : 'rgba(204,204,204,0)'
    },
    [neighbors, colors, selectedNode, selectedLink]
  )

  const renderLabel = useCallback(
    (node, ctx, globalScale) => {
      const fontSize = 14 / globalScale
      const radius = Math.cbrt(node[options.node_size]) * 4 * globalScale
      const hide = node.__hoverColor && node.__hoverColor.opacity === 0.1
      if (!hide && fontSize <= radius * 2) {
        const label = node.data.label
        ctx.font = `${fontSize}px Graphik Arabic`
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = 'black'
        ctx.fillText(label, node.x, node.y)
      }
    },
    [options.node_size]
  )

  useEffect(() => {
    const fg = graphRef.current
    fg.d3Force('charge').strength(-50)
    fg.d3Force('link').strength((link) => {
      return strengthScale(link.attributes.weight)
    })
  }, [])

  useEffect(() => {
    setData((oldData) => {
      return {
        nodes: mergeById(network.nodes, oldData.nodes),
        links: mergeByLink(network.links, oldData.links),
      }
    })
  }, [network])

  return (
    <div>
      <div className="w-100 px-3 py-2 d-flex justify-content-between align-items-center border-bottom">
        <div class="btn-group" role="group" aria-label="zoom control">
          <button
            onClick={() => handleZoom(0.5)}
            type="button"
            className="btn btn-background-pr"
          >
            <i className="bi-zoom-in"></i>
          </button>
          <button
            onClick={() => graphRef.current.zoomToFit(200)}
            type="button"
            className="btn btn-background-pr"
          >
            <i className="bi-fullscreen"></i>
          </button>
          <button
            onClick={() => handleZoom(-0.5)}
            type="button"
            className="btn btn-background-pr"
          >
            <i className="bi-zoom-out"></i>
          </button>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column align-items-end">
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: options.source_nodes_color,
              }}
              className="rounded-circle"
            ></div>
            <div style={{ fontSize: '0.675rem' }}>{query['connectField']}</div>
          </div>
          <div className="d-flex flex-column align-items-center mt-auto mx-2">
            <div
              style={{
                width: '100%',
                minWidth: 80,
                height: 2,
                backgroundColor: 'rgba(204,204,204,0.9)',
                marginBottom: 7,
              }}
            ></div>
            <div className="ms-1" style={{ fontSize: '0.675rem' }}>
              {items.join(', ')}
            </div>
          </div>
          <div className="d-flex flex-column align-items-start ">
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: options.target_nodes_color,
              }}
              className="rounded-circle"
            ></div>
            <div style={{ fontSize: '0.675rem' }}>{query['targetField']}</div>
          </div>
        </div>
      </div>
      <ForceGraph2D
        ref={graphRef}
        graphData={data}
        width={width}
        height={height}
        backgroundColor="#f6f5f1"
        linkWidth={(d) => d.attributes.weight}
        nodeColor={nodeOpacity}
        linkColor={linkOpacity}
        nodeVal={(d) => d[options.node_size]}
        nodeLabel={(d) => d.data.label}
        linkLabel={(d) => d.attributes.label}
        onNodeHover={handleNodeHover}
        onLinkHover={handleLinkHover}
        nodeCanvasObjectMode={() => 'after'}
        nodeCanvasObject={renderLabel}
      />
    </div>
  )
}

export default function NetworkChart(props) {
  return (
    <ParentSize>
      {({ width, height }) =>
        width > 0 ? (
          <NetworkChartComponent {...props} width={width} height={height} />
        ) : null
      }
    </ParentSize>
  )
}
