import chunk from 'lodash/chunk'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo, useState } from 'react'

const NO_ROWS = []

/**
 * @param rows {any[]}
 * @param pageSize {number}
 * @param mapRow {((row: any) => any)?}
 * @returns {[any[], {
 *  numPages: number,
 *  page: number,
 *  setPage(n: number): void
 *  orderByState: Record<string, 'asc' | 'desc' | null>
 *  setOrderBy(field: string, direction: string): void
 *  toggleOrderBy(field: string): void
 *  setOrderByState(o: Record<string, 'asc' | 'desc' | null> | (o: Record<string, 'asc' | 'desc' | null>) => Record<string, 'asc' | 'desc' | null>): void
 * }]}
 */
export function usePaginator(rows, pageSize, mapRow) {
  const [prevRows, setPrevRows] = useState(rows)
  const [prevPageSize, setPrevPageSize] = useState(pageSize)
  const [page, setPage] = useState(1)
  const [orderByState, setOrderByState] = useState({})
  // Map rows
  const mappedRows = useMemo(
    () => (mapRow ? rows.map(mapRow) : rows),
    [mapRow, rows]
  )
  // Sort rows
  const sortedRows = useMemo(() => {
    const fields = Object.keys(orderByState).filter((k) => orderByState[k])
    if (fields.length === 0) {
      return mappedRows
    }
    const orders = fields.map((k) => orderByState[k])
    return orderBy(mappedRows, fields, orders)
  }, [mappedRows, orderByState])
  // Chunk rows for pagination
  const chunkedRows = useMemo(
    () => chunk(sortedRows, pageSize),
    [pageSize, sortedRows]
  )

  // When rows or page size changed reset pagination
  if (prevRows !== rows || prevPageSize !== pageSize) {
    setPage(1)
    setPrevRows(rows)
    setPrevPageSize(pageSize)
    setOrderByState({})
  }

  const numPages = chunkedRows.length
  const paginatedRows =
    numPages === 0
      ? NO_ROWS
      : chunkedRows[Math.min(Math.max(page - 1, 0), numPages - 1)]

  const setOrderBy = useCallback((field, direction) => {
    setOrderByState((o) => ({ ...o, [field]: direction }))
  }, [])

  const toggleOrderBy = useCallback((field) => {
    setOrderByState((o) => {
      let direction = o[field]
      if (!direction) {
        direction = 'desc'
      } else if (direction === 'desc') {
        direction = 'asc'
      } else {
        direction = null
      }
      if (direction) {
        return {
          ...o,
          [field]: direction,
        }
      } else {
        const nextOrder = { ...o }
        delete nextOrder[field]
        return nextOrder
      }
    })
    setPage(1)
  }, [])

  return [
    paginatedRows,
    {
      setOrderBy,
      toggleOrderBy,
      setOrderByState,
      orderByState,
      page,
      numPages,
      setPage,
    },
  ]
}
