import MapGL, { Marker, NavigationControl, Popup } from '@urbica/react-map-gl'
import Cluster from '@urbica/react-map-gl-cluster'
import { scaleLinear, scaleSqrt } from 'd3-scale'
import { max } from 'd3-array'
import { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { LngLatBounds } from 'mapbox-gl'
import { useAuthState } from 'use-eazy-auth'
import { DEFAULT_MAP_CENTER, MAPBOX_ACCESS_TOKEN } from '../../../consts'
import { RELATIONS_OF_EVENTS } from './consts'
import MapPopupContent, { LIMIT_RECORDS_IN_POPUP } from '../MapPopupContent'
import styles from './InfoCardViz.module.scss'

const PRIORITIZE_GEO_FIELDS = ['location']

function geoSortIndexOf(a) {
  const index = PRIORITIZE_GEO_FIELDS.indexOf(a)
  return index === -1 ? Infinity : index
}

function geoSort(a, b) {
  return geoSortIndexOf(a) - geoSortIndexOf(b)
}

// TODO: Maybe do common css ore move in another place for now keep here XD
const markerStyle = {
  width: 10,
  height: 10,
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
}

const markerClusterStyle = {
  // cursor: 'pointer',
  width: 20,
  height: 20,
  color: '#fff',
  borderRadius: 10,
  textAlign: 'center',
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  language: string
 *  subjectId: number
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 * }}
 */
export default function PersonMap({
  expandedRelations,
  options,
  language,
  subjectId,
}) {
  const { authenticated } = useAuthState()
  const clusterRef = useRef()
  const mapRef = useRef()

  const geoList = useMemo(() => {
    const outList = []
    RELATIONS_OF_EVENTS.forEach((relName) => {
      if (!expandedRelations[relName]) {
        return
      }
      const metaRelated = expandedRelations[relName].meta.fields
      const candidateGeo = Object.keys(metaRelated)
        .filter((k) => metaRelated[k] === 'geo')
        .sort(geoSort)
      expandedRelations[relName].results.forEach((record) => {
        for (let i = 0; i < candidateGeo.length; i++) {
          if (record.geo[candidateGeo[i]]) {
            outList.push({
              id: record.id,
              label: record.label,
              point: record.geo[candidateGeo[i]],
            })
            break
          }
        }
      })
    })
    return outList
  }, [expandedRelations])

  const [viewport, setViewport] = useState({
    latitude: DEFAULT_MAP_CENTER.latitude,
    longitude: DEFAULT_MAP_CENTER.longitude,
    zoom: 6,
  })

  const [maxRadius, setMaxRadius] = useState(20)

  const radiusScale = useMemo(() => {
    return scaleSqrt().domain([1, maxRadius]).range([20, 40])
  }, [maxRadius])

  const changeMaxRadius = useCallback(() => {
    const cluster = clusterRef.current.getCluster()
    const map = mapRef.current.getMap()
    const bounds = map.getBounds()
    const zoom = map.getZoom()
    const geojsonCluster = cluster.getClusters(
      [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat],
      Math.round(zoom)
    )
    setMaxRadius(max(geojsonCluster, (d) => d.properties.point_count))
  }, [])

  const togglePopup = useCallback((payload) => {
    setPopup((p) => (p?.id === payload.id ? null : payload))
  }, [])

  const [popup, setPopup] = useState(null)

  // NOTE: Reset popup when new person is selected
  useEffect(() => {
    setPopup(null)
  }, [subjectId])

  const ClusterMarker = ({ longitude, latitude, pointCount, clusterId }) => (
    <Marker
      longitude={longitude}
      latitude={latitude}
      onClick={() => {
        const cluster = clusterRef.current.getCluster()
        const records = cluster
          .getLeaves(clusterId, LIMIT_RECORDS_IN_POPUP)
          .map((l) => l.properties.props.record)
        togglePopup({
          id: clusterId,
          latitude,
          longitude,
          records,
          offsetY: radiusScale(records.length) / 2,
        })
      }}
    >
      <div
        className={styles.cluster}
        style={{
          width: radiusScale(pointCount) ? radiusScale(pointCount) : 0,
          height: radiusScale(pointCount) ? radiusScale(pointCount) : 0,
          background: options.main_color,
        }}
      >
        {pointCount}
      </div>
    </Marker>
  )

  useEffect(() => {
    if (geoList.length === 0) {
      return
    }
    const bounds = new LngLatBounds()
    geoList.forEach(function (record) {
      bounds.extend([record.point.lng, record.point.lat])
    })
    const map = mapRef.current.getMap()
    map.once('load', () => {
      map.fitBounds(bounds, { padding: 50 })
      changeMaxRadius()
    })
  }, [changeMaxRadius, geoList])

  return (
    <div className="my-3">
      <h4 className="border-bottom my-3 pb-1">Maps of biographical events</h4>
      <div className={classNames('border', styles.mapContainer)}>
        <MapGL
          ref={mapRef}
          preserveDrawingBuffer={true}
          style={{ width: '100%', height: '100%' }}
          mapStyle={`mapbox://styles/mapbox/light-v10`}
          accessToken={MAPBOX_ACCESS_TOKEN}
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          maxZoom={16}
          viewportChangeMethod={'easeTo'}
          onViewportChange={(viewport) => {
            changeMaxRadius()
            setViewport(viewport)
            setPopup(null)
          }}
          scrollZoom={false}
        >
          {popup && (
            <Popup
              longitude={popup.longitude}
              latitude={popup.latitude}
              closeButton={false}
              closeOnClick={false}
              offset={popup.offsetY}
            >
              <MapPopupContent
                records={popup.records}
                language={language}
                showItemLink={authenticated}
              />
            </Popup>
          )}
          <Cluster
            ref={clusterRef}
            key={options.main_color}
            radius={40}
            extent={512}
            nodeSize={64}
            component={ClusterMarker}
          >
            {geoList.map((record) => (
              <Marker
                key={record.id}
                record={record}
                onClick={() => {
                  togglePopup({
                    latitude: record.point.lat,
                    longitude: record.point.lng,
                    records: [record],
                    id: record.id,
                    offsetY: 5,
                  })
                }}
                longitude={record.point.lng}
                latitude={record.point.lat}
              >
                <div
                  style={{
                    ...markerStyle,
                    background: options.main_color,
                  }}
                />
              </Marker>
            ))}
          </Cluster>
          <NavigationControl
            visualizePitch={true}
            showZoom={true}
            showCompass={true}
            position="top-right"
          />
        </MapGL>
      </div>
    </div>
  )
}
