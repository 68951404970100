import classNames from 'classnames'
import { getInLang } from '../../utils'
import { OMEKA_ITEMS_URL } from '../../consts'

export const LIMIT_RECORDS_IN_POPUP = Infinity

export default function MapPopupContent({
  records,
  language,
  showItemLink,
  field,
}) {
  return (
    <>
      {records.length > 1 && (
        <p className="GraphikArabic mb-2">{records.length} items</p>
      )}
      <div
        style={{ maxHeight: '200px', overflowY: 'scroll', textAlign: 'center' }}
      >
        {records.map((record, i) => (
          <div
            key={record.id}
            className={classNames('p-2 bg-white mb-1 ZaridSerif')}
          >
            <div>
              <p className="m-0" style={{ fontSize: '0.875rem' }}>
                {getInLang(record.label, language)}{' '}
                {showItemLink && (
                  <a
                    href={OMEKA_ITEMS_URL + record.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className={classNames('ms-1 bi-box-arrow-up-right')}></i>
                  </a>
                )}
              </p>
            </div>
            {field && (
              <div className="text-secondary GraphikArabic">
                <p className="m-0" style={{ fontSize: '0.65rem' }}>
                  {getInLang(record.data[field], language)}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
