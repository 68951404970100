import { useCallback } from 'react'
import { BigCaheOfFieldValues } from '../../hooks/fields'
import VizBlocksSelect from '../../components/VizBlocksSelect'
import MapQueryEditor from './editors/MapQueryEditor'
import ListQueryEditor from './editors/ListQueryEditor'
import NetworkQueryEditor from './editors/NetworkQueryEditor'
import InfoCardQueryEditor from './editors/InfoCardQueryEditor'
import TemporalQueryEditor from './editors/TemporalQueryEditor'
import EventsTimelineQueryEditor from './editors/EventsTimelineQueryEditor'
import { useTimelineDataRunner } from '../../hooks/query'
import PageSpinner from '../PageSpinner'
import { useActionErrorHandler } from '../../hooks/error'

const TIMELINE_DATA_VIZ_TYPES = ['temporal_evolution', 'events_timeline']

/**
 * @param {{
 *  vizType: string
 *  onVizTypeChange(type: string): void
 *  initialEditorState: QueryEditorState
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../hooks/schema').OmekaFieldType> }, timelineData: any): void
 *  className?: string
 *  vizId: number | null
 * }} props
 */
export default function QueryEditor({
  vizType,
  onVizTypeChange,
  initialEditorState,
  onNext,
  className = '',
  vizId,
}) {
  const handleActionError = useActionErrorHandler()
  const [{ pending }, { run: fetchTimelineData }] = useTimelineDataRunner()

  const handleOnNext = useCallback(
    (editorState, rows, meta) => {
      if (TIMELINE_DATA_VIZ_TYPES.includes(vizType)) {
        fetchTimelineData
          .onFailure(handleActionError)
          .onSuccess((timelineData) => {
            onNext(editorState, rows, meta, timelineData)
          })
          .run()
      } else {
        onNext(editorState, rows, meta, null)
      }
    },
    [fetchTimelineData, handleActionError, onNext, vizType]
  )

  const props = {
    key: vizType,
    initalState: initialEditorState,
    onNext: handleOnNext,
    vizId,
  }

  return (
    <div className={`container ${className}`}>
      {vizId === null && (
        <>
          <div className="row">
            <h4 className="border-bottom py-2">Choose a visual model</h4>
          </div>
          <VizBlocksSelect value={vizType} onChange={onVizTypeChange} />
        </>
      )}
      {pending && <PageSpinner text="Running the query, be patient..." />}
      <BigCaheOfFieldValues>
        <div className="row pt-3">
          {vizType === 'map' && <MapQueryEditor {...props} />}
          {vizType === 'list' && <ListQueryEditor {...props} />}
          {vizType === 'temporal_evolution' && (
            <TemporalQueryEditor {...props} />
          )}
          {vizType === 'network' && <NetworkQueryEditor {...props} />}
          {vizType === 'info_card' && <InfoCardQueryEditor {...props} />}
          {vizType === 'events_timeline' && (
            <EventsTimelineQueryEditor {...props} />
          )}
        </div>
      </BigCaheOfFieldValues>
    </div>
  )
}
