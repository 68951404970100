import React, { useState } from 'react'
import classNames from 'classnames'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import { Modal } from 'react-bootstrap'
import logo from '../../assets/PalREAD_Logo.png'
import { Link } from 'react-router-dom'

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          About the PalREAD project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-background-pr">
        <p>
          With massive numbers of the world’s population living as refugees and
          exiles, the literary production of displaced peoples poses serious
          questions and challenges to conventional and established methods of
          literary analysis. This is the context in which PalREAD is working to
          develop a comprehensive model for the study of Palestinian literature
          as an early and on-going case of literary displacement.
        </p>
        <p>
          To do so, PalREAD is seeking new ways to account for and analyze
          texts, literary production, reception and reading practices that
          challenge and lie outside the framework of the nation-state. In
          tracing the story of Palestinian literary production across different
          countries and continents, PalREAD adopts a transnational perspective
          combined with a holistic methodology that can view and understand
          Literature in its widest components.
        </p>
        <p>
          To find out more see our language versions:{' '}
          <a
            href="https://www.geschkult.fu-berlin.de/en/e/palread/_ContentElement/Arabic-Description.html"
            target="_blank"
            className="text-success"
          >
            عربي
          </a>
          ,{' '}
          <a
            href="https://www.geschkult.fu-berlin.de/en/e/palread/_ContentElement/English-Description.html"
            target="_blank"
            className="text-success"
          >
            English
          </a>
          ,{' '}
          <a
            href="https://www.geschkult.fu-berlin.de/en/e/palread/_ContentElement/Spanish-Description.html"
            target="_blank"
            className="text-success"
          >
            Español
          </a>
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default function Layout({ children, fixedNav = false }) {
  const { authenticated } = useAuthState()
  const { logout } = useAuthActions()
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <nav
        className={classNames(
          'navbar navbar-expand-md navbar-light bg-white border-bottom',
          {
            'fixed-top': fixedNav,
          }
        )}
      >
        <div className="container-fluid">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src={logo} height={40} alt="PalREAD Logo" />
            <span className="ms-3" style={{ fontSize: '1rem' }}>
              PalREAD Viz
            </span>
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {authenticated && (
              <button
                type="button"
                className="ms-auto btn btn-link text-danger text-decoration-none"
                onClick={() => {
                  logout()
                }}
              >
                Log out
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary rounded-circle p-0"
              style={{ width: 30, height: 30 }}
              onClick={() => setModalShow(true)}
            >
              <i className="bi-info"></i>
            </button>
          </div>
        </div>
      </nav>
      {children}
    </>
  )
}
