import { forwardRef, memo, useImperativeHandle, useMemo, useRef } from 'react'
import { saveAs } from 'file-saver'
import { makeNetwork } from '../../../utils'
import classNames from 'classnames'
import ItemsWidget from '../../Widget/ItemsWidget'
import FilterFieldPickerWidget from '../../Widget/FilterFieldPickerWidget'
import FilterFacetFieldsWidget from '../../Widget/FilterFacetFieldsWidget'
import { filterRecords, getInLang } from '../../../utils'
import FilterTimeFieldsWidget from '../../Widget/FilterTimeFieldsWidget'
import { useVizFilters } from '../../../hooks/filters'
import NetworkChart from '../NetworkChart'
import styles from './NetworkViz.module.scss'
import { useAuthState } from 'use-eazy-auth'
import logo from '../../../assets/PalREAD_watermark.png'

/**
 * @typedef {{
 *    node_size: string
 *    source_nodes_color: string
 *    target_nodes_color: string
 * }} NeworkVizOptions
 **/

/**
 * @param {{
 *  records: any[]
 *  meta: { fields: Record<string, import("../../../hooks/schema").OmekaFieldType> }
 *  query: QueryEditorState
 *  options: NeworkVizOptions
 *  setOptions?(options: NeworkVizOptions | ((options: NeworkVizOptions) => NeworkVizOptions)): void
 *  language: 'en' | 'ar'
 * }} props
 */

function NetworkViz(
  { records, options, meta, language, query, title, setOptions },
  ref
) {
  const [
    { filters, filterableFields },
    { setFilters, addFilter, removeFilter },
  ] = useVizFilters(meta.fields, options, setOptions)

  const fieldsInfo = {
    connectField: meta.fields[query.ui.connectField],
    targetField: meta.fields[query.ui.targetField],
  }

  const filteredRecords = useMemo(
    () => filterRecords(records, filters),
    [records, filters]
  )

  const network = useMemo(() => {
    return makeNetwork(filteredRecords, query.ui, fieldsInfo, language)
  }, [filteredRecords, query.ui, language])

  const containerRef = useRef()

  useImperativeHandle(ref, () => ({
    exportViz: () => {
      const container = containerRef.current
      const originalCanvas = container.querySelector('canvas')
      const canvas = originalCanvas.cloneNode(true)
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#fefbf3'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(originalCanvas, 0, 0)
      const logoWidth = 100

      const image = new Image()
      image.onload = () => {
        ctx.globalAlpha = 0.5
        ctx.save()

        ctx.setTransform(1, 0, 0, 1, 0, 0)

        ctx.drawImage(
          image,
          20,
          canvas.height - (logoWidth * image.height) / image.width - 20,
          logoWidth,
          (logoWidth * image.height) / image.width
        )
        ctx.restore()

        canvas.toBlob((blob) => {
          saveAs(
            blob,
            getInLang(title, language)
              ? getInLang(title, language) + '.png'
              : 'network.png'
          )
        })
      }
      image.src = logo
    },
  }))

  const { authenticated } = useAuthState()

  return (
    <div className="w-100 h-100 d-flex bg-background-pr" ref={containerRef}>
      <div className="flex-grow-1 flex-shrink-1 d-flex flex-column">
        <div className={classNames(styles.networkContainer)}>
          {filteredRecords.length > 0 && (
            <NetworkChart
              network={network}
              options={options}
              query={query.ui}
              items={query.items}
            ></NetworkChart>
          )}
        </div>
        <div className="flex-grow-0 flex-shrink-0">
          <FilterTimeFieldsWidget
            meta={meta}
            records={records}
            filters={filters}
            onFiltersChange={setFilters}
            onFilterRemoved={setOptions ? removeFilter : null}
          />
        </div>
      </div>
      <div className={classNames(styles.widgetContainerRight)}>
        {setOptions && (
          <FilterFieldPickerWidget
            fields={filterableFields}
            filters={filters}
            onFilterPicked={addFilter}
            onFiltersChange={setFilters}
          />
        )}
        <ItemsWidget
          showItemLink={authenticated}
          records={records}
          filteredRecords={filteredRecords}
          language={language}
        />
        <FilterFacetFieldsWidget
          meta={meta}
          records={records}
          filters={filters}
          onFiltersChange={setFilters}
          onFilterRemoved={setOptions ? removeFilter : null}
        />
      </div>
    </div>
  )
}

export default memo(forwardRef(NetworkViz))
