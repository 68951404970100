import * as yup from 'yup'
import { Field, Formik, useFormikContext } from 'formik'
import WrapWithEditorFooter from '../WrapWithEditorFooter'
import { Button, Tab, Tabs } from 'react-bootstrap'
import * as MapOptions from './options/MapOptions'
import * as NetworkOptions from './options/NetworkOptions'
import * as InfoCardOptions from './options/InfoCardOptions'
import * as ListOptions from './options/ListOptions'
import * as TemporalEvolutionOptions from './options/TemporalEvolutionOptions'
import * as EventsTimelineOptions from './options/EventsTimelineOptions'
import VIZ_COMPONENTS from '../Viz'
import { useMemo, createElement, useCallback, forwardRef, useRef } from 'react'
import InputField from '../Form/InputField'
import { Link, Prompt } from 'react-router-dom'
import PageSpinner from '../PageSpinner'
import { transformErrorForForm } from '../../utils'
import { useActionErrorHandler } from '../../hooks/error'
import styles from './VizOptions.module.scss'

const OPTIONS = {
  map: MapOptions,
  network: NetworkOptions,
  info_card: InfoCardOptions,
  events_timeline: EventsTimelineOptions,
  list: ListOptions,
  temporal_evolution: TemporalEvolutionOptions,
}

const BaseVizSchema = yup.object().shape(
  {
    title_en: yup
      .string()
      .when('title_ar', {
        is: (v) => !v,
        then: yup.string().required(),
      })
      .label('Title'),
    title_ar: yup
      .string()
      .when('title_en', {
        is: (v) => !v,
        then: yup.string().required(),
      })
      .label('Title'),
    description_en: yup.string(),
    description_ar: yup.string(),
    author_ar: yup.string(),
    author_en: yup.string(),
    default_language: yup.string().required().label('Language'),
    published: yup.boolean(),
  },
  // Ninjutsu to avoid cycle error ;)
  [['title_ar', 'title_en']]
)

const defaultBaseOptions = {
  title_en: '',
  description_en: '',
  author_en: '',
  title_ar: '',
  description_ar: '',
  author_ar: '',
  default_language: '',
  published: false,
  viz_options: {},
}

/**
 * @param {{
 *  vizType: string
 *  query: QueryEditorState
 *  meta: { fields: Record<string, import('../../hooks/schema').OmekaFieldType> }
 *  records: any[]
 *  timelineData: any
 * }} props
 */
const CustomVizComponent = forwardRef(
  ({ vizType, query, meta, records, timelineData }, ref) => {
    const { values, setValues } = useFormikContext()

    const setOptions = useCallback(
      (optionsOrCb) => {
        setValues((values) => {
          const newOptions =
            typeof optionsOrCb === 'function'
              ? optionsOrCb(values.viz_options)
              : optionsOrCb
          return {
            ...values,
            viz_options: newOptions,
          }
        }, false)
      },
      [setValues]
    )

    if (!VIZ_COMPONENTS[vizType]) {
      return null
    }

    return createElement(VIZ_COMPONENTS[vizType], {
      query,
      records,
      meta,
      timelineData,
      options: values.viz_options,
      title: { en: values.title_en, ar: values.title_ar },
      language: values.default_language,
      setOptions,
      ref,
    })
  }
)

/**
 * @param {{
 *  vizType: string
 *  intialVizOptions: Record<string, any>
 *  onEditQuery(): void
 *  onRefreshData(): void
 *  onSave(opts: Record<string, any>): Promise<any>
 *  onDelete?(): void
 *  records: any[]
 *  query: QueryEditorState
 *  meta: { fields: Record<string, import('../../hooks/schema').OmekaFieldType> }
 *  vizId: number | null
 *  className?: string
 *  refreshingData: boolean
 *  timelineData: any
 * }} props
 */
export default function VizOptions({
  vizType,
  intialVizOptions,
  records,
  meta,
  timelineData,
  query,
  onEditQuery,
  onRefreshData,
  onSave,
  onDelete,
  vizId,
  refreshingData,
  className = '',
}) {
  const handleActionError = useActionErrorHandler()

  const initialValues = useMemo(() => {
    if (intialVizOptions) {
      return intialVizOptions
    }
    return {
      ...defaultBaseOptions,
      viz_options: OPTIONS?.[vizType]?.defaultValues ?? {},
    }
  }, [intialVizOptions, vizType])

  const VizSchema = useMemo(() => {
    const vizSchema = OPTIONS?.[vizType]?.ValidationSchema
    if (!vizSchema) {
      return BaseVizSchema
    }
    return BaseVizSchema.shape({
      viz_options: yup.object().shape(vizSchema),
    })
  }, [vizType])

  const vizRef = useRef()

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, actions) =>
        onSave(values).catch((error) => {
          if (error.status === 400) {
            actions.setErrors(transformErrorForForm(error))
          } else {
            handleActionError(error)
          }
        })
      }
      validationSchema={VizSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting, values, dirty }) => (
        <form onSubmit={handleSubmit} className={className}>
          {refreshingData && (
            <PageSpinner text="Refreshing data, please wait..." />
          )}
          <Prompt
            when={dirty && vizId !== null}
            message="Are you sure to leave this page without saving?"
          />
          <WrapWithEditorFooter
            className="justify-content-between bg-dark"
            footer={
              <>
                <div>
                  <Button
                    disabled={isSubmitting}
                    type="button"
                    onClick={onEditQuery}
                    variant="outline-light"
                  >
                    <i className="bi-pencil"></i> Edit Query
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className="ms-2"
                    type="button"
                    onClick={onRefreshData}
                    variant="outline-light"
                  >
                    <i className="bi-arrow-clockwise"></i> Refresh Data
                  </Button>
                </div>
                {dirty && vizId && (
                  <div className="badge bg-warning">{'unsaved changes'}</div>
                )}
                <div>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="success"
                    className="me-2"
                  >
                    <i className="bi-save"></i> Save project
                  </Button>
                  {vizId && (
                    <Button
                      className="me-2"
                      variant="outline-light"
                      as={Link}
                      to={`/view/${vizId}`}
                    >
                      <i className="bi-eye"></i> Preview
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      vizRef.current.exportViz()
                    }}
                    className="me-2"
                    disabled={isSubmitting}
                    type="button"
                    variant="outline-light"
                  >
                    <i className="bi-download"></i> Export
                  </Button>
                  {onDelete && (
                    <Button
                      disabled={isSubmitting}
                      onClick={onDelete}
                      type="button"
                      variant="danger"
                    >
                      <i className="bi-trash"></i> Delete
                    </Button>
                  )}
                </div>
              </>
            }
          >
            <div className="w-100 h-100 overflow-hidden d-flex">
              <div
                className="h-100 flex-grow-0 flex-shrink-0 p-2 border-end bg-white"
                style={{ overflowY: 'auto', width: 325 }}
              >
                <div className="mb-2">
                  <Tabs
                    transition={false}
                    defaultActiveKey="en"
                    id="viz-options-tabs"
                    className={`mt-2 ${styles.customNavLink}`}
                  >
                    <Tab eventKey="en" title="English" className="mt-3">
                      <Field
                        name="title_en"
                        component={InputField}
                        label="Title"
                        className="mb-3"
                      />
                      <Field
                        name="description_en"
                        component={InputField}
                        label="Summary"
                        renderAs="textarea"
                        className="mb-3"
                      />
                      <Field
                        name="author_en"
                        component={InputField}
                        label="Author(s)"
                        className="mb-3"
                      />
                    </Tab>
                    <Tab eventKey="ar" title="Arabic" className="mt-3">
                      <Field
                        name="title_ar"
                        component={InputField}
                        label="Title"
                        className="mb-3"
                      />
                      <Field
                        name="description_ar"
                        component={InputField}
                        label="Summary"
                        renderAs="textarea"
                        className="mb-3"
                      />
                      <Field
                        name="author_ar"
                        component={InputField}
                        label="Author(s)"
                        className="mb-3"
                      />
                    </Tab>
                  </Tabs>
                </div>
                <div className="mb-2">
                  <Field
                    name="published"
                    component={InputField}
                    label="Status"
                    renderAs="select"
                    className="mb-3 form-select"
                  >
                    <option value="false">Draft</option>
                    <option value="true">Published</option>
                  </Field>
                  <Field
                    name="default_language"
                    component={InputField}
                    label="Main Language"
                    renderAs="select"
                    className="mb-3 form-select"
                  >
                    <option value="">Choose a language</option>
                    <option value="ar">Arabic</option>
                    <option value="en">English</option>
                  </Field>
                </div>
                <h5 className="GraphikArabic border-top pt-3 mt-4 mb-3">
                  Options
                </h5>
                {OPTIONS[vizType] &&
                  createElement(OPTIONS[vizType].FieldSet, {
                    meta,
                    query,
                    ns: 'viz_options',
                  })}
              </div>
              <div className="h-100 flex-grow-1 flex-shrink-1 overflow-hidden">
                <CustomVizComponent
                  timelineData={timelineData}
                  vizType={vizType}
                  query={query}
                  meta={meta}
                  records={records}
                  ref={vizRef}
                />
              </div>
            </div>
          </WrapWithEditorFooter>
        </form>
      )}
    </Formik>
  )
}
