import React from 'react'

// NOTE: Maybe can be improved
function paginationRange(current, total, delta = 1, separator = '...') {
  if (total <= 1) return [1]

  const center = [current]
  for (let i = 1; i <= delta; i++) {
    center.push(current + i)
    center.unshift(current - i)
  }
  if (delta === 1 && current === 1) {
    center.push(3)
  }
  if (delta === 1 && current === total) {
    center.unshift(total - 2)
  }

  const filteredCenter = center.filter((p) => p > 1 && p < total),
    includeLeft = current === delta + 2 + 1,
    includeRight = current === total - delta - 2,
    includeLeftDots = current > delta + 2 + 1,
    includeRightDots = current < total - delta - 2

  if (includeLeft) filteredCenter.unshift(2)
  if (includeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift(separator)
  if (includeRightDots) filteredCenter.push(separator)

  return [1, ...filteredCenter, total]
}

const Paginator = ({
  numPages,
  currentPage,
  goToPage,
  className = 'd-flex justify-content-center',
}) => (
  <nav aria-label="Paginator" className={className}>
    <ul className="pagination">
      <li
        style={{ cursor: 'pointer' }}
        className={`page-item ${
          currentPage === null || currentPage === 1 ? 'disabled' : ''
        }`}
      >
        <span
          className="page-link"
          aria-label="Previous"
          onClick={() => goToPage(currentPage - 1)}
        >
          <span className="sr-only">Prev</span>
        </span>
      </li>
      {paginationRange(currentPage, numPages, 3).map((page, i) =>
        typeof page === 'string' ? (
          <li className="page-item" key={i}>
            <span className="page-link">{page}</span>
          </li>
        ) : (
          <li
            key={i}
            style={{ cursor: 'pointer' }}
            className={`page-item ${currentPage === page ? 'active' : ''} `}
          >
            <span onClick={() => goToPage(page)} className="page-link">
              {page}
            </span>
          </li>
        )
      )}
      <li
        style={{ cursor: 'pointer' }}
        className={`page-item ${
          currentPage === null || !(currentPage < numPages) ? 'disabled' : ''
        }`}
      >
        <span
          className="page-link"
          aria-label="Next"
          onClick={() => goToPage(currentPage + 1)}
        >
          <span className="sr-only">Next</span>
        </span>
      </li>
    </ul>
  </nav>
)

export default Paginator
