import classNames from 'classnames'
import { useReducer } from 'react'
import { useHistory } from 'react-router'
import Layout from '../../components/Layout'
import VizEditor from '../../components/VizEditor'
import { useActionErrorHandler } from '../../hooks/error'
import { useQueryRunner } from '../../hooks/query'
import { useCreateViz } from '../../hooks/viz'

/**
 * @typedef {{
 *  vizType: string
 *  step: 'query' | 'options'
 *  records: any[] | null
 *  meta: Record<string, any> | null
 *  queryEditor: QueryEditorState | null
 *  timelineData: any | null
 * }} NewVizState
 */

/**
 * @type NewVizState
 */
const initialNewVizState = {
  vizType: 'map',
  step: 'query',
  records: null,
  meta: null,
  queryEditor: null,
  timelineData: null,
}

/**
 * @param {NewVizState} state
 * @param {{
 *  type: 'BACK_TO_QUERY',
 * } |
 * {
 *  type: 'SHOW_OPTIONS',
 *  payload: {
 *    records: any[],
 *    meta: Record<string, any> | null,
 *    timelineData: any
 *  }
 * } |
 * {
 *  type: 'SET_VIZ_TYPE',
 *  payload: string
 * } |
 * {
 *  type: 'SET_RECORDS',
 *  payload: { records: any[], meta:Record<string, any> | null }
 * }
 * } action
 * @returns {NewVizState}
 */
function newVizReducer(state, action) {
  switch (action.type) {
    case 'BACK_TO_QUERY':
      return {
        ...state,
        step: 'query',
      }
    case 'SHOW_OPTIONS':
      return {
        ...state,
        ...action.payload,
        step: 'options',
      }
    case 'SET_VIZ_TYPE':
      return {
        ...initialNewVizState,
        vizType: action.payload,
      }
    case 'SET_RECORDS':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default function NewViz() {
  const history = useHistory()
  const handleActionError = useActionErrorHandler()
  const [newVizState, dispatch] = useReducer(newVizReducer, initialNewVizState)
  const createViz = useCreateViz()
  const [{ pending: refreshing }, { run: runQuery }] = useQueryRunner()

  return (
    <div
      className={classNames({
        'vh-100': newVizState.step === 'options',
      })}
    >
      <Layout fixedNav={newVizState.step === 'options'}>
        <VizEditor
          refreshingData={refreshing}
          onRefreshData={() => {
            const { queryEditor } = newVizState
            runQuery
              .onFailure(handleActionError)
              .onSuccess((data) => {
                dispatch({
                  type: 'SET_RECORDS',
                  payload: { meta: data.meta, records: data.results },
                })
              })
              .run({
                fields: queryEditor.fields,
                items: queryEditor.items,
                queries: queryEditor.queries,
              })
          }}
          className={classNames({
            'padding-for-fixed-nav h-100': newVizState.step === 'options',
          })}
          step={newVizState.step}
          vizType={newVizState.vizType}
          onVizTypeChange={(vizType) => {
            dispatch({
              type: 'SET_VIZ_TYPE',
              payload: vizType,
            })
          }}
          initialQueryEditorState={newVizState.queryEditor}
          records={newVizState.records}
          meta={newVizState.meta}
          timelineData={newVizState.timelineData}
          onShowOptions={(queryEditor, records, meta, timelineData) => {
            dispatch({
              type: 'SHOW_OPTIONS',
              payload: {
                timelineData,
                queryEditor,
                records,
                meta,
              },
            })
          }}
          onEditQuery={() => {
            dispatch({
              type: 'BACK_TO_QUERY',
            })
          }}
          onSaveOptions={(values) =>
            createViz
              .onSuccess((createdViz) => {
                history.push(`/editor/viz/${createdViz.id}`)
              })
              .asPromise({
                ...values,
                timeline_data: newVizState.timelineData,
                meta: newVizState.meta,
                records: newVizState.records,
                query: newVizState.queryEditor,
                viz_type: newVizState.vizType,
              })
          }
        />
      </Layout>
    </div>
  )
}
