import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'

/**
 * @param {{ text: string }}
 */
export default function PageSpinner({ text = null }) {
  useEffect(() => {
    document.body.classList.add('no-body-overflow')
    return () => {
      document.body.classList.remove('no-body-overflow')
    }
  })
  return (
    <div className="fade modal-backdrop show d-flex flex-column align-items-center justify-content-center text-white">
      <Spinner animation="border" />
      {text && <div className="mt-2">{text}</div>}
    </div>
  )
}
