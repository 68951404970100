import MapViz from './MapViz'
import InfoCardViz from './InfoCardViz'
import EventsTimelineViz from './EventsTimelineViz'
import NetworkViz from './NetworkViz'
import ListViz from './ListViz'
import TemporlaEvolutionViz from './TemporlaEvolutionViz'

const VIZ_COMPONENTS = {
  map: MapViz,
  info_card: InfoCardViz,
  events_timeline: EventsTimelineViz,
  network: NetworkViz,
  list: ListViz,
  temporal_evolution: TemporlaEvolutionViz,
}
export default VIZ_COMPONENTS
