export const RELATIONS_OF_EVENTS = [
  'boycotted event',
  'imprisonment event',
  'employment event',
  'education event',
  'embership event',
  'participant in',
  'residence event',
  'activity timespan',
]
