import { Link } from 'react-router-dom'
import find from 'lodash/find'
import { Dropdown } from 'react-bootstrap'
import classNames from 'classnames'
import { VIZ_TYPES } from '../../consts.js'
import { parseISO, format } from 'date-fns'
import styles from './VizCard.module.scss'
import { useMemo } from 'react'

/**
 *
 * @param {{
 *  viz: any
 *  onDuplicate(viz: any): void
 *  onDelete(viz: any): void
 *  onMakePreset(viz: any): void
 * }}
 */
export default function VizCard({ viz, onDuplicate, onDelete, onMakePreset }) {
  const vizTypeConfig = useMemo(
    () => find(VIZ_TYPES, { value: viz.viz_type }),
    [viz.viz_type]
  )
  return (
    <div
      className={classNames(
        styles.container,
        'w-100 mb-3 d-flex flex-column position-relative rounded overflow-hidden border bg-white'
      )}
    >
      <div
        className={classNames(
          styles.top,
          'bg-dark d-flex flex-column align-items-center justify-content-center flex-grow-0 flex-shrink-0 position-relative'
        )}
      >
        <div className="d-flex align-items-center justify-content-between w-100 px-2">
          <span className="text-white">{vizTypeConfig.label}</span>
          <span
            className={classNames(
              styles.badge,
              'badge rounded-pill fw-normal',
              {
                'bg-success': viz.published,
                'bg-warning': !viz.published,
              }
            )}
          >
            {viz.published ? 'published' : 'draft'}
          </span>
        </div>
        <i className={classNames(styles.icon, vizTypeConfig.icon)}></i>
      </div>
      <div className="flex-grow-1 flex-shink-1 p-3 position-relative overflow-hidden">
        <h5 className={classNames(styles.title, 'GraphikArabic')}>
          <Link
            to={`/editor/viz/${viz.id}`}
            className="text-decoration-none text-body"
          >
            {viz.title_en || viz.title_ar}
          </Link>
        </h5>
      </div>
      <div className="p-3 flex-grow-0 flex-shrink-0 mt-auto d-flex align-items-center">
        <div className="d-dlex justify-content-between w-100">
          <div>
            <small>{viz.author_en || viz.author_ar}</small>
          </div>
          <div className="text-muted">
            <small>{format(parseISO(viz.created_at), 'dd-MM-yyyy')}</small>
          </div>
        </div>
        <div className="flex-shrink-0 flex-grow-0 ms-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              className="text-body"
            ></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/view/${viz.id}`}>
                View
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => onDuplicate(viz)}>
                Duplicate
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => onMakePreset(viz)}>
                Convert to sample
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                className="text-danger"
                onClick={() => onDelete(viz)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
