import { memo } from 'react'
import classNames from 'classnames'

/**
 * Multi selection
 *
 * @param {{
 *  options: any[]
 *  values: any[]
 *  onChange: (nextValues: any[]) => void
 *  label?: string
 *  mode?: 'box' | 'flat'
 * }} props
 */
function MultiBoxSelect({ options, values, onChange, label, mode = 'box' }) {
  return (
    <div
      className={classNames('mt-3', {
        'border bg-white p-2': mode === 'flat',
      })}
      style={{ maxHeight: 300, overflowY: 'auto' }}
    >
      {label && <div className="mb-1">{label}</div>}
      {options.map((option) => {
        const check = values.includes(option)
        const handleChange = () => {
          onChange(
            check ? values.filter((v) => v !== option) : values.concat(option)
          )
        }
        return (
          <div
            onClick={handleChange}
            className={classNames(
              'bg-white d-flex align-items-center rounded mb-1 p-2 ',
              {
                border: mode === 'box',
                'py-1': mode === 'flat',
                'border-primary': check,
              }
            )}
            key={option}
          >
            <div className="form-group form-check m-0 p-0 pl-2">
              <input onChange={handleChange} type="checkbox" checked={check} />
              <label className="form-check-label ms-1">{option}</label>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(MultiBoxSelect)
