/**
 * @param {{
 *  direction: 'asc' | 'desc' | null
 * }
 */
export default function SortIcon({ direction }) {
  if (direction === 'asc') {
    return <i className="bi-arrow-up"></i>
  } else if (direction === 'desc') {
    return <i className="bi-arrow-down"></i>
  } else {
    return <i className="bi-dash"></i>
  }
}
