import { useMemo, Fragment } from 'react'
import { rollups } from 'd3-array'
import { ParentSize } from '@visx/responsive'
import { AxisTop } from '@visx/axis'
import { GridColumns } from '@vx/grid'
import { LegendLinear } from '@visx/legend'
import { extent, ascending, max } from 'd3-array'
import { timeYear } from 'd3-time'
import {
  scaleLinear,
  scaleTime,
  scaleSequential,
  scaleBand,
  scaleQuantize,
} from 'd3-scale'
import { schemeYlGn, schemeYlOrRd, schemeYlOrBr } from 'd3-scale-chromatic'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import classNames from 'classnames'
import { OMEKA_ITEMS_URL } from '../../../consts'
import { getInLang, multiDateParse } from '../../../utils'
import styles from './InfoCardViz.module.scss'

const LITERARY_PRODUCTION = [
  'Book',
  'Article',
  'Book series',
  'Contribution',
  'Ephemera',
  'Issue',
  'Periodical',
]

const COLOR_DICT = {
  '#4f7c4c': schemeYlGn,
  '#b64747': schemeYlOrRd,
  '#deb145': schemeYlOrBr,
}

const BAR_HEIGHT = 30
const BAR_Y_PADDING = 35
const MARGINS = {
  top: 35,
  right: 20,
  bottom: 10,
  left: 1,
}

const LEGEND_STEPS = 9

/**
 * @param {{
 * 	relatedItems: any
 * }}
 */
function PersonWorksChart({
  relatedItems,
  personId,
  options,
  language,
  width,
}) {
  const roles = useMemo(() => {
    let items = []
    LITERARY_PRODUCTION.forEach((d) => {
      relatedItems[d] && items.push(...relatedItems[d].results)
    })

    items = items
      .filter((f) => {
        return multiDateParse(getInLang(f.data['publication date']))
      })
      .map((d) => {
        d.date = multiDateParse(getInLang(d.data['publication date']))
        return d
      })

    const rolesGroup = rollups(
      items,
      (v) => v,
      (d) => {
        const roles = Object.entries(d.relations).filter(
          (f) => f[1].filter((c) => c.id === personId).length > 0
        )
        return roles[0][0]
      },
      (d) => d.date.getFullYear()
    )

    return rolesGroup
  }, [relatedItems, personId])

  const height =
    roles.length * (BAR_Y_PADDING + BAR_HEIGHT) + MARGINS.top + MARGINS.bottom

  const chartHeight = height - MARGINS.top - MARGINS.bottom

  const chartWidth = useMemo(() => {
    return width - MARGINS.left - MARGINS.right
  }, [width])

  const yearsDomain = useMemo(
    () =>
      extent(roles.map((d) => d[1].map((c) => c[0])).flat()).map(
        (d) => new Date(d, 0, 1)
      ),
    [roles]
  )

  const colorScaleMax = useMemo(() => {
    return max(roles, (d) => max(d[1], (f) => f[1].length))
  }, [roles])

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const xScaleBand = useMemo(() => {
    return scaleBand()
      .range([0, chartWidth])
      .domain(xScale.ticks(timeYear))
      .padding(0)
  }, [xScale])

  const colorScale = useMemo(() => {
    return scaleQuantize(
      [1, colorScaleMax],
      COLOR_DICT[options.main_color][LEGEND_STEPS]
    ).nice()
  }, [colorScaleMax, options.main_color])

  const tickLabelProps = () => ({
    fontSize: '0.75rem',
    fontFamily: 'Graphik Arabic',
    textAnchor: 'middle',
    y: -5,
  })

  // form https://observablehq.com/@mootari/svg-single-path-grid
  function gridPath(cols, rows, width = 1, height = 1, initPosition = true) {
    const sx = width / cols,
      sy = height / rows
    const px = Array(rows + 1)
      .fill(`h${width}`)
      .join(`m${-width},${sy}`)
    const py = Array(cols + 1)
      .fill(`v${height}`)
      .join(`m${sx},${-height}`)
    return `${initPosition ? 'M0,0' : ''}${px}m${-width}${-height}${py}`
  }
  return (
    <div className="my-3">
      <h4 className="border-bottom mt-3 mb-2 pb-1">Literary production</h4>
      {roles.length > 0 ? (
        <div>
          <div className="w-100 d-flex pt-1">
            <div
              style={{ marginLeft: MARGINS.left }}
              className="d-flex flex-column"
            >
              <small>Publication date</small>
              <i className="bi-arrow-right"></i>
            </div>

            <div className="ms-auto d-flex">
              <h6 className="me-2 GraphikArabic">
                <small>Number of items</small>
              </h6>
              <div style={{ fontSize: '0.75rem', marginRight: MARGINS.right }}>
                <LegendLinear
                  scale={colorScale}
                  direction="row"
                  itemDirection="column"
                  labelMargin={0}
                  shapeMargin={0}
                  shapeHeight={20}
                  shapeWidth={5}
                  steps={LEGEND_STEPS}
                  labelFormat={(d, i) => {
                    return i !== 0 && i + 1 < LEGEND_STEPS
                      ? ''
                      : d === 0
                      ? 1
                      : d
                  }}
                />
              </div>
            </div>
          </div>
          <svg width={width} height={height}>
            <rect width={width} height={height} fill="#f6f5f1"></rect>
            <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
              {roles.map((role, i) => (
                <g
                  key={role[0]}
                  transform={`translate(0, ${
                    i * (BAR_HEIGHT + BAR_Y_PADDING)
                  })`}
                >
                  <text
                    x={0}
                    y={0}
                    fontSize="1.2rem"
                    fontFamily="'Zarid Serif', system-ui, -apple-system, 'Segoe UI', Arial"
                  >
                    {role[0]}
                  </text>

                  <path
                    fill="none"
                    stroke="#efefef"
                    transform={`translate(0,${BAR_Y_PADDING / 4})`}
                    d={gridPath(
                      xScaleBand.domain().length,
                      1,
                      chartWidth + xScaleBand.bandwidth(),
                      BAR_HEIGHT
                    )}
                  ></path>
                  {role[1].map((year) => (
                    <OverlayTrigger
                      key={year[0]}
                      placement="auto"
                      trigger="click"
                      rootClose={true}
                      overlay={
                        <Popover>
                          <div className="p-2 bg-background-pr">
                            {year[1].length > 1 && (
                              <h6 className={styles.axisLabel}>
                                {year[0]}: {year[1].length} ITEMS
                              </h6>
                            )}
                            <div
                              style={{
                                maxHeight: '200px',
                                overflowY: 'scroll',
                                textAlign: 'center',
                              }}
                            >
                              {year[1].map((record, i) => (
                                <div
                                  key={record.id}
                                  className={classNames('p-2 bg-white mb-1')}
                                >
                                  {getInLang(record.label, language)}{' '}
                                  <a
                                    href={OMEKA_ITEMS_URL + record.id}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {' '}
                                    <i
                                      className={classNames(
                                        'bi-box-arrow-up-right'
                                      )}
                                    ></i>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover>
                      }
                    >
                      {({ ref, ...passProps }) => (
                        <rect
                          key={year[0]}
                          {...passProps}
                          ref={ref}
                          className={styles.bar}
                          x={xScale(new Date(year[0], 0, 1))}
                          y={BAR_Y_PADDING / 4}
                          width={xScaleBand.bandwidth()}
                          height={BAR_HEIGHT}
                          fill={colorScale(year[1].length)}
                        ></rect>
                      )}
                    </OverlayTrigger>
                  ))}
                </g>
              ))}
            </g>
            <AxisTop
              scale={xScale}
              top={MARGINS.top - 20}
              left={MARGINS.left}
              hideTicks={true}
              tickLength={0}
              stroke={'#ccc'}
              rangePadding={xScaleBand.bandwidth()}
              tickLabelProps={tickLabelProps}
            />
            <GridColumns
              scale={xScale}
              width={chartWidth}
              height={chartHeight}
              left={MARGINS.left}
              top={MARGINS.top}
              stroke={'#ccc'}
              strokeDasharray={(3, 3)}
            />
          </svg>
        </div>
      ) : (
        'No data!'
      )}
    </div>
  )
}

export default function PersonWorks(props) {
  return (
    <ParentSize>
      {({ width }) =>
        width > 0 ? <PersonWorksChart {...props} width={width} /> : null
      }
    </ParentSize>
  )
}
