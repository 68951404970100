import { useCallback, useMemo, useReducer } from 'react'
import isDeepEqual from 'fast-deep-equal'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { QueryBuilderContainer } from '../QueryBuilderContainer'

/**
 * @typedef {{
 *  queries: Query[]
 *  items: string[]
 * }} InfoCardQueryState
 *
 * @param {InfoCardQueryState} state
 * @param {
    QueryActions
    | { type: 'RESET' }
    | { type: 'UPDATE_ITEMS', payload: string[] }
  } action
 * @returns {InfoCardQueryState}
 */
function infoCardVizReducer(state, action) {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

const infoCardItems = ['Publisher', 'Person', 'Organisation']

/**
 * @type InfoCardQueryState
 */
const defaultState = {
  queries: [],
  items: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {InfoCardQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function InfoCardQueryEditor({ initalState, onNext, vizId }) {
  const [state, dispatch] = useReducer(infoCardVizReducer, initalState, init)

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)

  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      with_related_items: true,
      with_expanded_relations: true,
      fields: [],
    }),
    [state, allowedQueryFields]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {},
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <MultiBoxSelect
            label="Select"
            values={state.items}
            options={infoCardItems}
            onChange={setItems}
          />
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
