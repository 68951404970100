import { useMemo } from 'react'
import intersection from 'lodash/intersection'
import './query-types'
import { useEditorSchema } from './hooks/schema'

/**
 * @param {Query[]} state
 * @param {QueryActions} action
 * @returns {Query[]}
 */
export function queriesReducer(state, action) {
  switch (action.type) {
    case 'ADD_QUERY':
      return state.concat({
        expression: null,
        field: null,
        value: null,
        subqueries: [],
      })
    case 'REMOVE_QUERY': {
      const next = [...state]
      next.splice(action.payload.index, 1)
      return next
    }
    case 'UPDATE_QUERY': {
      const next = [...state]
      const queryValueOrFn = action.payload.query
      const index = action.payload.index
      const prevQuery = next[index]
      if (typeof queryValueOrFn === 'function') {
        next[index] = queryValueOrFn(prevQuery)
      } else {
        next[index] = queryValueOrFn
      }
      return next
    }
    default:
      return state
  }
}

/**
 * Which field are good to beeing filtered???
 * @param {import('./hooks/schema').OmekaField} field
 */
export function isGoodFieldForFilter(field) {
  if (['name', 'title'].includes(field.label)) {
    return true
  }
  return ['date', 'network', 'facet', 'geo'].includes(field.type)
}

/**
 * @param {string[]} items
 * @returns {string[]}
 */
export function useQueryFieldsByItems(items) {
  const { fieldsByItem } = useEditorSchema()

  // NOTE: Interesction choiche
  return useMemo(() => {
    const fieldsSet = items.map((item) =>
      fieldsByItem[item].filter(isGoodFieldForFilter).map((f) => f.label)
    )
    return intersection(...fieldsSet).sort()
  }, [fieldsByItem, items])
  // NOTE: Union choiche
  // return useMemo(
  //   () =>
  //     uniq(fields.filter((f) => items.includes(f.item)).map((f) => f.label)),
  //   [fields, items]
  // )
}

/**
 * @param {Query[]} queries
 * @param {import('./hooks/schema').OmekaField[]} fields
 */
export function getEffectiveQueries(queries, fields) {
  return queries.filter((query) => fields.includes(query.field))
}
