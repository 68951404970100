import { useEffect, useState } from 'react'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import Layout from '../../components/Layout/Layout'
import styles from './Login.module.scss'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Layout fixedNav>
      <div className={styles.LoginContainer}>
        <form
          className="row w-100"
          onSubmit={(e) => {
            e.preventDefault()
            if (email !== '' && password !== '') {
              login({ email, password })
            }
          }}
        >
          <div className="col-md-4 offset-md-4">
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                name="email"
                placeholder="Email"
                className="form-control"
                type="email"
                value={email}
                onChange={(e) => {
                  clearLoginError()
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className="form-group mt-4">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                placeholder="password"
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => {
                  clearLoginError()
                  setPassword(e.target.value)
                }}
              />
            </div>
            <button className="btn btn-primary mt-3" disabled={loginLoading}>
              {!loginLoading ? 'Login' : 'Logged in...'}
            </button>
            {loginError && (
              <div className="alert alert-danger mt-3">
                Bad combination of username and password.
              </div>
            )}
          </div>
        </form>
      </div>
    </Layout>
  )
}
