import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useCallback } from 'react'
import { Button, FormControl, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Layout from '../../components/Layout'
import Paginator from '../../components/Paginator'
import VizCard from '../../components/VizCard'
import VizPresetCard from '../../components/VizPresetCard/VizPresetCard'
import { VIZ_TYPES } from '../../consts'
import { useActionErrorHandler } from '../../hooks/error'
import { useVizList, useVizPresetsList } from '../../hooks/viz'
import PageError from '../PageError'
import styles from './Home.module.scss'

export default function Home() {
  const handleActionError = useActionErrorHandler()
  const history = useHistory()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  console.log(debQueryParams)

  const [
    { list, pagination, error },
    { duplicateViz, deleteViz, makePreset, run: refreshVizs },
  ] = useVizList(debQueryParams)

  const [
    { data: presetsList, error: presetsError },
    { run: refreshPresets, removeFromPreset, createFromPreset },
  ] = useVizPresetsList()

  const handleMakeVizPreset = useCallback(
    (viz) => {
      makePreset
        .onSuccess(() => {
          refreshPresets()
        })
        .onFailure(handleActionError)
        .run(viz.id)
    },
    [handleActionError, makePreset, refreshPresets]
  )

  const handleRemoveVizFromPreset = useCallback(
    (viz) => {
      removeFromPreset
        .onSuccess(() => {
          refreshVizs()
        })
        .onFailure(handleActionError)
        .run(viz.id)
    },
    [handleActionError, refreshVizs, removeFromPreset]
  )

  const handleCreateFromPreset = useCallback(
    (viz) => {
      createFromPreset
        .onSuccess((newViz) => {
          history.push(`/editor/viz/${newViz.id}`)
        })
        .run(viz.id)
    },
    [createFromPreset, history]
  )

  const [duplicateModal, duplicateModalActions] = useModalTrigger()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  if (error || presetsError) {
    return <PageError error={error || presetsError} />
  }

  return (
    <Layout>
      <div className="container mt-3">
        <div className="row">
          <div className="col-9">
            <div className="d-flex justify-content-between border-bottom">
              <h2>Visualizations</h2>
              <div>
                <Button variant="success" as={Link} to="/editor/viz/new">
                  <i className="bi-plus"></i> New visualization
                </Button>
              </div>
            </div>
            <div className="w-100 d-flex mb-4 border-bottom py-3 justify-content-between align-items-end sticky-top bg-background-pr">
              <div style={{ flex: 1 }}>
                <label className={classNames(styles.label, 'form-label mb-1')}>
                  search
                </label>
                <FormControl
                  value={queryParams.search ?? ''}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  placeholder="type to start searching..."
                  className="bg-background-pr"
                />
              </div>
              <div className="ms-4">
                <label className={classNames(styles.label, 'form-label mb-1')}>
                  filter by
                </label>

                <FormControl
                  as="select"
                  value={queryParams.viz_type ?? ''}
                  onChange={(e) => {
                    setQueryParams({
                      viz_type: e.target.value,
                      page: 1,
                    })
                  }}
                  className="form-select"
                >
                  <option value="">All</option>
                  {VIZ_TYPES.map((vizType) => (
                    <option key={vizType.value} value={vizType.value}>
                      {vizType.label}
                    </option>
                  ))}
                </FormControl>
              </div>
              <div className="ms-4">
                <label className={classNames(styles.label, 'form-label mb-1')}>
                  sort by
                </label>
                <FormControl
                  as="select"
                  value={queryParams.ordering ?? '-created_at'}
                  onChange={(e) => {
                    setQueryParams({
                      ordering: e.target.value,
                      page: 1,
                    })
                  }}
                  className="form-select"
                >
                  <option value={'created_at'}>Oldest First</option>
                  <option value={'-created_at'}>Newest First</option>
                </FormControl>
              </div>
            </div>
            <div className="row">
              {list &&
                list.map((viz) => (
                  <div className="col-4" key={viz.id}>
                    <VizCard
                      onMakePreset={handleMakeVizPreset}
                      onDelete={deleteModalActions.open}
                      onDuplicate={duplicateModalActions.open}
                      viz={viz}
                    ></VizCard>
                  </div>
                ))}
            </div>
            <Paginator
              goToPage={(page) => setQueryParams({ page })}
              numPages={pagination.numPages}
              currentPage={+(debQueryParams.page ?? 1)}
            />
          </div>
          <div className="col-3 border-start">
            <h2 className="border-bottom pb-2 mb-3">Samples</h2>
            {presetsList &&
              presetsList.map((viz) => (
                <VizPresetCard
                  viz={viz}
                  key={viz.id}
                  onRemoveFromPreset={handleRemoveVizFromPreset}
                  onCreateViz={handleCreateFromPreset}
                />
              ))}
          </div>
        </div>
      </div>
      <Modal
        show={duplicateModal.isOpen}
        onHide={duplicateModalActions.toggle}
        onExiting={duplicateModalActions.onClosed}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Duplicate visualization{' '}
          {duplicateModal.value && (
            <b>
              {duplicateModal.value.title_en || duplicateModal.value.title_ar}
            </b>
          )}
          {' ?'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={duplicateModalActions.toggle}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              duplicateViz
                .onFailure(handleActionError)
                .onSuccess((duplicatedViz) => {
                  history.push(`/editor/viz/${duplicatedViz.id}`)
                })
                .run(duplicateModal.value.id)
            }}
          >
            Duplicate
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteModal.isOpen}
        onHide={deleteModalActions.toggle}
        onExiting={deleteModalActions.onClosed}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Delete visualization{' '}
          {deleteModal.value && (
            <b>{deleteModal.value.title_en || deleteModal.value.title_ar}</b>
          )}
          {' ?'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteModalActions.toggle}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteViz
                .onFailure(handleActionError)
                .onSuccess(deleteModalActions.close)
                .run(deleteModal.value.id)
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}
