import { useHistory, useParams } from 'react-router'
import pick from 'lodash/pick'
import Layout from '../../components/Layout'
import VizEditor from '../../components/VizEditor'
import { useViz } from '../../hooks/viz'
import classNames from 'classnames'
import PageSpinner from '../../components/PageSpinner'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Button, Modal } from 'react-bootstrap'
import { useActionErrorHandler } from '../../hooks/error'
import PageError from '../PageError'

export default function Viz() {
  const handleActionError = useActionErrorHandler()
  const { id, step } = useParams()
  const vizStep = step ?? 'options'
  const history = useHistory()
  const [
    { data, updating, refreshing, deleting, error },
    { patchViz, refreshViz, deleteViz },
  ] = useViz(id)
  const [deleteModal, deleteModalActions] = useModalTrigger()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <div
      className={classNames({
        'vh-100': vizStep === 'options',
      })}
    >
      {updating && vizStep === 'query' && (
        <PageSpinner text="Saving your data" />
      )}
      <Layout fixedNav={vizStep === 'options'}>
        {data && (
          <VizEditor
            className={classNames({
              'padding-for-fixed-nav h-100': vizStep === 'options',
            })}
            onRefreshData={() => {
              refreshViz.onFailure(handleActionError).run(id)
            }}
            onDelete={() => {
              deleteModalActions.open()
            }}
            refreshingData={refreshing}
            vizId={data.id}
            vizType={data.viz_type}
            step={vizStep}
            onEditQuery={() => history.push(`/editor/viz/${data.id}/query`)}
            initialQueryEditorState={data.query}
            intialVizOptions={pick(data, [
              'title_en',
              'description_en',
              'author_en',
              'title_ar',
              'description_ar',
              'author_ar',
              'default_language',
              'published',
              'viz_options',
            ])}
            records={data.records}
            meta={data.meta}
            timelineData={data.timeline_data}
            onShowOptions={(editorState, records, meta, timelineData) => {
              patchViz
                .onFailure(handleActionError)
                .onSuccess(() => {
                  history.push(`/editor/viz/${data.id}`)
                })
                .run(data.id, {
                  query: editorState,
                  timeline_data: timelineData,
                  meta,
                  records,
                })
            }}
            onSaveOptions={(values) => patchViz.asPromise(data.id, values)}
          />
        )}
      </Layout>
      <Modal
        show={deleteModal.isOpen}
        onHide={deleteModalActions.toggle}
        onExiting={deleteModalActions.onClosed}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Visualization</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this visualization?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteModalActions.toggle}>
            Cancel
          </Button>
          <Button
            variant="danger"
            disabled={deleting}
            onClick={() => {
              deleteViz
                .onFailure(handleActionError)
                .onSuccess(() => {
                  history.push('/')
                })
                .run(data.id)
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
