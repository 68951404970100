import MultiBoxSelect from '../MultiBoxSelect'

/**
 * @param {import("formik").FieldProps} props
 */
export default function MultiBoxSelectField({ field, label, form, ...props }) {
  return (
    <div>
      {label && <label>{label}</label>}
      <MultiBoxSelect
        values={field.value}
        onChange={(nextValues) => {
          form.setFieldValue(field.name, nextValues)
        }}
        {...props}
      />
    </div>
  )
}
