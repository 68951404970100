import { useCallback, useMemo, useReducer } from 'react'
import isDeepEqual from 'fast-deep-equal'
import uniq from 'lodash/uniq'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import { QueryBuilderContainer } from '../QueryBuilderContainer'
import DropdownSelect from '../../DropdownSelect'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { useEditorSchema } from '../../../hooks/schema'

/**
 * @typedef {{
 *  queries: Query[]
 *  items: string[]
 *  connectField: string | null
 *  targetField: string | null
 * }} NetworkQueryState
 *
 * @param {NetworkQueryState} state
 * @param {
    QueryActions
    | { type: 'UPDATE_ITEMS', payload: string[] }
    | { type: 'UPDATE_CONNECT_FIELD', payload: string }
    | { type: 'UPDATE_TARGET_FIELD', payload: string }
  } action
 * @returns {NetworkQueryState}
 */
function temporalVizReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_CONNECT_FIELD':
      return {
        ...state,
        items: [],
        connectField: action.payload,
        targetField: null,
      }
    case 'UPDATE_TARGET_FIELD':
      return {
        ...state,
        items: [],
        targetField: action.payload,
      }
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'UPDATE_BASED_ON_FIELD':
      return {
        ...state,
        basedOnField: action.payload,
      }
    case 'RESET':
      return defaultState
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

/**
 * @type NetworkQueryState
 */
const defaultState = {
  items: [],
  connectField: null,
  targetField: null,
  queries: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {NetworkQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function NetworkQueryEditor({ initalState, onNext, vizId }) {
  const {
    fieldsByType,
    fieldsByItem,
    fields,
    networkFields: netFieldsData,
  } = useEditorSchema()
  const [state, dispatch] = useReducer(temporalVizReducer, initalState, init)

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const setConnectField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_CONNECT_FIELD',
        payload: field,
      }),
    []
  )
  const setTargetField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_TARGET_FIELD',
        payload: field,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const networkFields = useMemo(
    () =>
      uniq(fieldsByType['network'].map((f) => f.label))
        .concat('title', 'name')
        .sort(),
    [fieldsByType]
  )
  const targetFields = useMemo(() => {
    const items = uniq(
      fieldsByType['network']
        .concat(fields.filter((f) => ['title', 'name'].includes(f.label)))
        .filter((f) => f.label === state.connectField)
        .map((f) => f.item)
    )
    return uniq(
      netFieldsData
        .filter((f) => f.label !== state.connectField && items.includes(f.item))
        .map((f) => f.label)
    ).sort()
  }, [fieldsByType, netFieldsData, state.connectField, fields])

  const joinItems = useMemo(
    () =>
      Object.keys(fieldsByItem)
        .filter((item) => {
          return (
            fieldsByItem[item].some((f) => f.label === state.targetField) &&
            fieldsByItem[item].some((f) => f.label === state.connectField)
          )
        })
        .sort(),
    [fieldsByItem, state.connectField, state.targetField]
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)
  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      fields: [state.targetField, state.connectField].filter(Boolean),
      with_expanded_relations: true,
    }),
    [state, allowedQueryFields]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {
              targetField: state.targetField,
              connectField: state.connectField,
            },
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <DropdownSelect
            label="Connect"
            className="mb-1"
            value={state.connectField}
            onChange={setConnectField}
            options={networkFields}
            placeholder={'Select a property'}
          />
          {state.connectField && (
            <DropdownSelect
              label="With"
              className="mb-1"
              value={state.targetField}
              onChange={setTargetField}
              options={targetFields}
              placeholder={'Select a property'}
            />
          )}
          {state.targetField && (
            <MultiBoxSelect
              label="By"
              className="mb-1"
              values={state.items}
              options={joinItems}
              onChange={setItems}
            />
          )}
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
