import { Route, Switch } from 'react-router'
import { EditorSchemaProvider } from '../../hooks/schema'
import NewViz from '../NewViz/NewViz'
import PageNotFound from '../PageNotFound'
import Viz from '../Viz/Viz'

export default function Editor() {
  return (
    <EditorSchemaProvider>
      <Switch>
        <Route path="/editor/viz/new" exact>
          <NewViz />
        </Route>
        <Route path="/editor/viz/:id/:step(query)?" exact>
          <Viz />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </EditorSchemaProvider>
  )
}
