import React, { Fragment } from 'react'
import { AxisTop } from '@visx/axis'
import { GridColumns } from '@vx/grid'
import { ParentSize } from '@visx/responsive'
import { Bar } from '@visx/shape'
import { extent, ascending } from 'd3-array'
import { scaleLinear, scaleTime } from 'd3-scale'
import { useMemo } from 'react'
import { localPoint } from '@vx/event'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuthState } from 'use-eazy-auth'
import { getInLang, multiDateParse, invertName } from '../../../utils'
import { RELATIONS_OF_EVENTS } from './consts'
import { OMEKA_ITEMS_URL } from '../../../consts'
import styles from './InfoCardViz.module.scss'

const TIME_FROM_FIELD = 'from year or date'
const TIME_TO_FIELD = 'to year or date'

const BAR_HEIGHT = 12
const BAR_Y_PADDING = 25
const MARGINS = {
  top: 15,
  right: 30,
  bottom: 10,
  left: 30,
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 *  width: number
 * }}
 */
function PersonTimelineChart({ expandedRelations, options, width, language }) {
  /**
   * @type [{ from: number | null, to: number | null }]
   */
  const { authenticated } = useAuthState()

  const timeList = useMemo(() => {
    const outList = []
    RELATIONS_OF_EVENTS.forEach((relName) => {
      if (!expandedRelations[relName]) {
        return
      }
      expandedRelations[relName].results.forEach((record) => {
        // TODO: Avoid hardcoding?
        if (record.data[TIME_FROM_FIELD] || record.data[TIME_TO_FIELD]) {
          const from = multiDateParse(getInLang(record.data[TIME_FROM_FIELD]))
          const to = multiDateParse(getInLang(record.data[TIME_TO_FIELD]))
          if (from) {
            let newLabel = {}
            if (record.item === 'Life event') {
              for (const [key, value] of Object.entries(record.label)) {
                newLabel[key] = invertName(value, key)
              }
            } else {
              newLabel = record.label
            }
            const timeRecord = {
              id: record.id,
              label: newLabel,
              from: from,
              to: to ?? from,
              record: record.item,
              type: 'span',
            }
            if (timeRecord.from.getTime() === timeRecord.to.getTime()) {
              timeRecord.type = 'point'
            }
            outList.push(timeRecord)
          }
        }
      })
    })
    return outList.sort((a, b) => ascending(a.from, b.from))
  }, [expandedRelations])

  const height =
    timeList.length * (BAR_Y_PADDING + BAR_HEIGHT) +
    MARGINS.top +
    MARGINS.bottom

  const chartHeight = height - MARGINS.top - MARGINS.bottom

  const chartWidth = useMemo(() => {
    return width - MARGINS.left - MARGINS.right
  }, [width])

  const yearsDomain = useMemo(
    () =>
      extent([
        ...extent(timeList, (t) => t.from),
        ...extent(timeList, (t) => t.to),
      ]),
    [timeList]
  )

  const xScale = useMemo(() => {
    return scaleTime().range([0, chartWidth]).domain(yearsDomain)
  }, [chartWidth, yearsDomain])

  const tickLabelProps = () => ({
    fontSize: '0.75rem',
    fontFamily: 'Graphik Arabic',
    textAnchor: 'middle',
    y: -5,
  })

  return (
    <div className="my-3">
      <h4 className="border-bottom mt-3 mb-2 pb-1">Events timeline</h4>
      <div className="d-flex">
        <small>From / to date</small>
        <i className="ms-2 bi-arrow-right"></i>
      </div>
      <div></div>
      <svg width={width} height={20} className={styles.fixedAxis}>
        <rect height={20} width={width} fill={'#f6f5f1'}></rect>
        <AxisTop
          scale={xScale}
          top={20}
          left={MARGINS.left}
          hideTicks={true}
          tickLength={0}
          stroke={'#ccc'}
          tickLabelProps={tickLabelProps}
        />
      </svg>
      <svg width={width} height={height} id={'viz'}>
        <rect width={width} height={height} fill="#f6f5f1"></rect>
        <GridColumns
          scale={xScale}
          width={chartWidth}
          height={chartHeight}
          left={MARGINS.left}
          top={0}
          stroke={'#ccc'}
          strokeDasharray={(3, 3)}
        />
        <g transform={`translate(${MARGINS.left}, ${MARGINS.top})`}>
          {timeList.map((timeRecord, i) => {
            const barY = i * (BAR_HEIGHT + BAR_Y_PADDING) + BAR_Y_PADDING / 2
            const barX = xScale(timeRecord.from)
            const width =
              timeRecord.type === 'span'
                ? xScale(timeRecord.to) - barX < BAR_HEIGHT
                  ? BAR_HEIGHT
                  : xScale(timeRecord.to) - barX
                : BAR_HEIGHT / 2

            const textX = barX > chartWidth / 2 ? barX + width : barX
            const textAnchor = barX > chartWidth / 2 ? 'end' : 'start'
            return timeRecord.type === 'span' ? (
              <g key={timeRecord.id} className={styles.barsG}>
                {authenticated ? (
                  <a
                    href={OMEKA_ITEMS_URL + timeRecord.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <text
                      x={textX}
                      y={barY}
                      dy={-4}
                      textAnchor={textAnchor}
                      fontSize="0.675rem"
                      fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                    >
                      {getInLang(timeRecord.label, language)}
                    </text>
                  </a>
                ) : (
                  <text
                    x={textX}
                    y={barY}
                    dy={-4}
                    textAnchor={textAnchor}
                    fontSize="0.675rem"
                    fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                  >
                    {getInLang(timeRecord.label, language)}
                  </text>
                )}

                <text
                  x={xScale(timeRecord.from) - 3}
                  y={barY + BAR_HEIGHT}
                  dy={-2}
                  textAnchor={'end'}
                  fontSize="0.675rem"
                  fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                >
                  {timeRecord.from.getFullYear()}
                </text>

                <Bar
                  x={barX}
                  y={barY}
                  width={width}
                  height={BAR_HEIGHT}
                  fill={options.main_color}
                  rx={BAR_HEIGHT / 2}
                />
                <text
                  x={xScale(timeRecord.from) + width + 3}
                  y={barY + BAR_HEIGHT}
                  dy={-2}
                  textAnchor={'start'}
                  fontSize="0.675rem"
                  fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                >
                  {timeRecord.to.getFullYear()}
                </text>
              </g>
            ) : (
              <g key={timeRecord.id} className={styles.barsG}>
                {authenticated ? (
                  <a
                    href={OMEKA_ITEMS_URL + timeRecord.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <text
                      x={textX - BAR_HEIGHT / 2}
                      y={barY}
                      dy={-4}
                      textAnchor={textAnchor}
                      fontSize="0.675rem"
                      fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                    >
                      {getInLang(timeRecord.label, language)}
                    </text>
                  </a>
                ) : (
                  <text
                    x={textX - BAR_HEIGHT / 2}
                    y={barY}
                    dy={-4}
                    textAnchor={textAnchor}
                    fontSize="0.675rem"
                    fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                  >
                    {getInLang(timeRecord.label, language)}
                  </text>
                )}
                <text
                  x={xScale(timeRecord.from) - 3 - BAR_HEIGHT / 2}
                  y={barY + BAR_HEIGHT}
                  dy={-2}
                  textAnchor={'end'}
                  fontSize="0.675rem"
                  fontFamily="'Graphik Arabic', system-ui, -apple-system, 'Segoe UI', Arial"
                >
                  {timeRecord.from.getFullYear()}
                </text>
                <circle
                  cx={barX}
                  cy={barY + BAR_HEIGHT / 2}
                  r={BAR_HEIGHT / 2}
                  fill={options.main_color}
                ></circle>
              </g>
            )
          })}
        </g>
      </svg>
    </div>
  )
}

/**
 * @param {{
 *  expandedRelations: Record<string, any>
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 * }}
 */
export default function PersonTimeline(props) {
  return (
    <ParentSize>
      {({ width }) =>
        width > 0 ? <PersonTimelineChart {...props} width={width} /> : null
      }
    </ParentSize>
  )
}
