import { schemeYlOrBr, schemeYlGn, schemeYlOrRd } from 'd3-scale-chromatic'

// Speical key used for unset filters
export const UNSET_KEY = '__unset__'

export const PAGE_SIZE = 15

export const VIZ_TYPES = [
  {
    label: 'Map',
    value: 'map',
    icon: 'bi-map',
  },
  {
    label: 'Info Card',
    value: 'info_card',
    icon: 'bi-info-square',
  },
  {
    label: 'Events Timeline',
    value: 'events_timeline',
    icon: 'bi-bar-chart-steps',
  },
  {
    label: 'Temporal Evolution',
    value: 'temporal_evolution',
    icon: 'bi-bar-chart',
  },
  {
    label: 'List',
    value: 'list',
    icon: 'bi-list-ul',
  },
  {
    value: 'network',
    label: 'Network',
    icon: 'bi-diagram-3',
  },
]

export const PALETTE = {
  green: schemeYlGn,
  red: schemeYlOrRd,
  yellow: schemeYlOrBr,
}

export const MAP_STYLES = {
  'pre-48-light': 'mapbox://styles/gaidamarcel/ckzfsvrhb00ad14rwrohhpych',
  'pre-48-dark': 'mapbox://styles/gaidamarcel/ckzgpy9tp001v15qkpeh207o1',
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
}

// TODO: Move in evn...
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiZ2FpZGFtYXJjZWwiLCJhIjoiY2t5dmlwejBqMGxhajJ2b3hveGg0eGhrNiJ9.9xKmXwfjMmOn78dZK3WnNQ'

export const DEFAULT_MAP_CENTER = {
  latitude: 31.87275559474816,
  longitude: 34.785919921107116,
}

export const OMEKA_ITEMS_URL =
  'https://palread.geschkult.fu-berlin.de/omeka-s/admin/item/'
