import { Field } from 'formik'
import * as yup from 'yup'
import CheckField from '../../Form/CheckField'
import PaletteField from '../../Form/PaletteField'

export const defaultValues = {
  palette: 'green',
  independent_scale: false,
  show_contextual_timeline: false,
}

export const ValidationSchema = {
  palette: yup.string().required().label('Palette'),
  show_contextual_timeline: yup.boolean(),
  independent_scale: yup.boolean(),
}

/**
 * @param {{ ns: string }} props
 */
export function FieldSet({ ns }) {
  return (
    <div>
      <Field
        name={`${ns}.palette`}
        component={PaletteField}
        label="Items Palette"
        className="mb-3"
      />
      <Field
        name={`${ns}.independent_scale`}
        component={CheckField}
        label="Independent scale"
        className="mb-3"
      />
      <Field
        name={`${ns}.show_contextual_timeline`}
        component={CheckField}
        label="Show contextual timeline"
        className="mb-3"
      />
    </div>
  )
}
