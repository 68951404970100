import { useMemo } from 'react'
import DropdownSelect from '../DropdownSelect'
import difference from 'lodash/difference'

/**
 * @param {{
 * 	fields: string[]
 * 	filters: Record<string, string[]>
 * 	onFilterPicked(field: string): void
 * }}
 */
export default function FilterFieldPickerWidget({
  fields,
  filters,
  onFilterPicked,
}) {
  const availableFields = useMemo(
    () => difference(fields, Object.keys(filters)),
    [fields, filters]
  )
  return (
    <>
      {availableFields.length > 0 && (
        <div className="m-2">
          <DropdownSelect
            placeholder="Add filter"
            size="sm"
            options={availableFields}
            onChange={(field) => {
              onFilterPicked(field)
            }}
            variant="success"
            className="d-grid gap-1"
          />
        </div>
      )}
    </>
  )
}
