import { useCallback, useMemo, useReducer } from 'react'
import isDeepEqual from 'fast-deep-equal'
import find from 'lodash/find'
import uniq from 'lodash/uniq'
import {
  getEffectiveQueries,
  queriesReducer,
  useQueryFieldsByItems,
} from '../../../query'
import DropdownSelect from '../../DropdownSelect'
import MultiBoxSelect from '../../MultiBoxSelect'
import QueryBuilder from '../QueryBuilder'
import { QueryBuilderContainer } from '../QueryBuilderContainer'
import { useEditorSchema } from '../../../hooks/schema'

const TIMELINE_ITEMS = ['Life event', 'Literary event', 'Activity timespan']

const TIME_PAIRS = [
  {
    label: 'From / to year or date',
    pair: ['from year or date', 'to year or date'],
  },
]

const getTimeLabelFromPair = (pair) =>
  !pair
    ? null
    : find(TIME_PAIRS, (i) => i.pair.join('') === pair.join(''))?.label ?? null

const getTimePairFromLabel = (label) =>
  find(TIME_PAIRS, { label })?.pair ?? null

const timePairsLabel = TIME_PAIRS.map((p) => p.label)

/**
 * @typedef {{
 *  queries: Query[]
 *  items: string[]
 *  pairField: string[] | null
 * }} EventsTimelineQueryState
 *
 * @param {EventsTimelineQueryState} state
 * @param {
    QueryActions
    | { type: 'RESET' }
    | { type: 'UPDATE_PAIR_FIELD', payload: string }
    | { type: 'UPDATE_ITEMS', payload: string[] }
  } action
 * @returns {EventsTimelineQueryState}
 */
function eventsTimelineVizReducer(state, action) {
  switch (action.type) {
    case 'RESET':
      return defaultState
    case 'UPDATE_PAIR_FIELD':
      return {
        ...state,
        items: state.pairField === action.payload ? state.items : [],
        pairField: action.payload,
      }
    case 'UPDATE_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'UPDATE_QUERY':
    case 'ADD_QUERY':
    case 'REMOVE_QUERY':
      return {
        ...state,
        queries: queriesReducer(state.queries, action),
      }
    default:
      return state
  }
}

/**
 * @type EventsTimelineQueryState
 */
const defaultState = {
  pairField: null,
  queries: [],
  items: [],
}

/**
 * @param {QueryEditorState | null} initialState
 * @returns {EventsTimelineQueryState}
 */
function init(initialState) {
  if (initialState) {
    return {
      items: initialState.items,
      queries: initialState.queries,
      ...initialState.ui,
    }
  }
  return defaultState
}

/**
 * @param {{
 *  initalState: QueryEditorState | null
 *  onNext(state: QueryEditorState, rows: any[] | null, meta: { fields: Record<string, import('../../../hooks/schema').OmekaFieldType> }): void
 *  vizId: number | null
 * }} props
 */
export default function EventsTimelineQueryEditor({
  initalState,
  onNext,
  vizId,
}) {
  const { fieldsByItem } = useEditorSchema()
  const [state, dispatch] = useReducer(
    eventsTimelineVizReducer,
    initalState,
    init
  )

  const setItems = useCallback(
    (items) =>
      dispatch({
        type: 'UPDATE_ITEMS',
        payload: items,
      }),
    []
  )
  const setField = useCallback(
    (field) =>
      dispatch({
        type: 'UPDATE_PAIR_FIELD',
        payload: field,
      }),
    []
  )
  const onAddQuery = useCallback(
    () =>
      dispatch({
        type: 'ADD_QUERY',
      }),
    []
  )
  const onRemoveQuery = useCallback(
    (index) =>
      dispatch({
        type: 'REMOVE_QUERY',
        payload: { index },
      }),
    []
  )
  const onUpdateQuery = useCallback(
    (index, query) =>
      dispatch({
        type: 'UPDATE_QUERY',
        payload: { index, query },
      }),
    []
  )

  const allowedQueryFields = useQueryFieldsByItems(state.items)

  const query = useMemo(
    () => ({
      queries: getEffectiveQueries(state.queries, allowedQueryFields),
      items: state.items,
      fields: [...(state.pairField ?? [])],
    }),
    [state, allowedQueryFields]
  )

  // Start from given items take only the items with
  // selected pair of fields ....
  const timeItems = useMemo(
    () =>
      TIMELINE_ITEMS.reduce(
        (myItems, item) =>
          uniq(
            myItems.concat(
              fieldsByItem[item]
                .filter((f) => (state.pairField ?? []).includes(f.label))
                .map((f) => f.item)
                .sort()
            )
          ),
        []
      ),
    [fieldsByItem, state.pairField]
  )

  const dirty = useMemo(
    () => (!vizId ? false : !isDeepEqual(state, init(initalState))),
    [vizId, state, initalState]
  )

  return (
    <QueryBuilderContainer
      dirty={dirty}
      onNext={(rows, meta) =>
        onNext(
          {
            ...query,
            ui: {
              pairField: state.pairField,
            },
          },
          rows,
          meta
        )
      }
      query={query}
      onReset={() => dispatch({ type: 'RESET' })}
      left={
        <>
          <DropdownSelect
            label="Show temporal spans"
            placeholder="select a property"
            className="mb-3 d-grid"
            value={useMemo(
              () => getTimeLabelFromPair(state.pairField),
              [state.pairField]
            )}
            onChange={(label) => setField(getTimePairFromLabel(label))}
            options={timePairsLabel}
          />
          {state.pairField && (
            <MultiBoxSelect
              label="Of the following items"
              values={state.items}
              options={timeItems}
              onChange={setItems}
            />
          )}
        </>
      }
      right={
        <QueryBuilder
          items={state.items}
          fields={allowedQueryFields}
          queries={state.queries}
          onAdd={onAddQuery}
          onRemove={onRemoveQuery}
          onUpdate={onUpdateQuery}
        />
      }
    />
  )
}
