import { Component } from 'react'
import logo from '../assets/PalREAD_Logo.png'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container-fluid mt-5 d-flex align-items-center justify-content-center flex-column">
          <div className="d-flex text-black mb-2 align-items-baseline justify-content-center text-secondary">
            <img src={logo} height={25} alt="PalREAD Logo" />
            <h3 className="ms-3">PalREAD</h3>
          </div>
          <div className="text-danger text-center">
            <h1>Something went wrong</h1>
            <h2>
              <i className="bi bi-bug"></i>
            </h2>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
