import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import { useAuthState } from 'use-eazy-auth'
import PersonCard from './PersonCard'
import PersonMap from './PersonMap'
import PersonPicker from './PersonPicker'
import PersonTimeline from './PersonTimeline'
import PersonWorks from './PersonWorks'
import { Button, Modal } from 'react-bootstrap'
import { getInLang } from '../../../utils'
import saveSvgAsImage from '../../../utils'
import classNames from 'classnames'

/**
 * @param {{
 *  records: any[]
 *  meta: { fields: Record<string, import("../../hooks/schema").OmekaFieldType> }
 *  query: QueryEditorState
 *  options: {
 *    show_general_information: boolean
 *    show_events_map: boolean
 *    show_events_timespans: boolean
 *    show_literary_production_timeline: boolean
 *    main_color: string
 *  }
 *  language: 'en' | 'ar'
 * }} props
 */
function InfoCardViz(
  { records, options, meta, language, title, query, viewer },
  ref
) {
  const { authenticated } = useAuthState()

  const [selectedPerson, setSelectedPerson] = useState(() =>
    records.length > 0 ? records[0] : null
  )

  const [modalExport, modalExportActions] = useModalTrigger()

  const personContainerRef = useRef()

  useImperativeHandle(ref, () => ({
    exportViz: () => {
      modalExportActions.open()
      console.log('Export Info Card')
    },
  }))

  function exportPersonMap() {
    const mapContainer =
      personContainerRef.current.querySelector('.mapboxgl-map')
    html2canvas(mapContainer, {
      ignoreElements: (element) => {
        const klist = element.classList
        return klist.contains('mapboxgl-ctrl')
      },
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(
          blob,
          getInLang(title, language)
            ? getInLang(title, language) + '-map.png'
            : 'infocard-map.png'
        )
      })
    })
  }

  function exportPersonTimeline() {
    const svgElement = personContainerRef.current.querySelector(
      '#info-card-viz-person-timeline svg#viz'
    )
    saveSvgAsImage(
      svgElement,
      getInLang(title, language)
        ? getInLang(title, language) + '-timeline.svg'
        : 'infocard-timeline.svg'
    )
  }

  function exportPersonWorks() {
    const svgElement = personContainerRef.current.querySelector(
      '#info-card-viz-person-works svg'
    )
    saveSvgAsImage(
      svgElement,
      getInLang(title, language)
        ? getInLang(title, language) + '-literary-production.svg'
        : 'infocard-literary-production.svg'
    )
  }

  return (
    <div className={classNames('h-100 w-100', { 'container-fluid': !viewer })}>
      <Modal
        show={modalExport.isOpen}
        onHide={modalExportActions.toggle}
        onExiting={modalExportActions.onClosed}
      >
        <Modal.Header closeButton>
          <Modal.Title>Info Card Export</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center px-5">
          {options.show_events_map && (
            <Button
              variant="primary"
              className="mb-2"
              onClick={exportPersonMap}
            >
              Export Map
            </Button>
          )}
          {options.show_events_timespans && (
            <Button
              variant="primary"
              className="mb-2"
              onClick={exportPersonTimeline}
            >
              Export Events Timeline
            </Button>
          )}
          {options.show_literary_production_timeline && (
            <Button
              variant="primary"
              className="mb-2"
              onClick={exportPersonWorks}
            >
              Export Literary Production
            </Button>
          )}
        </Modal.Body>
      </Modal>
      <div className="row h-100 bg-background-pr">
        <div className="col-3 h-100">
          <PersonPicker
            language={language}
            records={records}
            value={selectedPerson}
            onChange={setSelectedPerson}
            showItemLink={authenticated}
          />
        </div>
        {selectedPerson && (
          <div
            className="col-9 h-100"
            style={{ overflowY: 'auto' }}
            ref={personContainerRef}
          >
            {options.show_general_information && (
              <PersonCard
                person={selectedPerson}
                language={language}
                meta={meta}
              />
            )}
            {options.show_events_map && (
              <PersonMap
                subjectId={selectedPerson.id}
                language={language}
                expandedRelations={selectedPerson.expanded_relations}
                options={options}
              />
            )}
            {options.show_events_timespans && (
              <div id="info-card-viz-person-timeline">
                <PersonTimeline
                  language={language}
                  expandedRelations={selectedPerson.expanded_relations}
                  options={options}
                />
              </div>
            )}
            {options.show_literary_production_timeline && (
              <div id="info-card-viz-person-works">
                <PersonWorks
                  relatedItems={selectedPerson.related_items}
                  personId={selectedPerson.id}
                  options={options}
                  language={language}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(forwardRef(InfoCardViz))
