import { useCallback, useMemo, useState } from 'react'
import omit from 'lodash/omit'

/**
 * @param fields {Record<string, string>}
 * @param options {Record<string, any>}
 * @param setOptions {(options: Record<string, string[]>) => void}
 * @returns {[
 *  {
 *    filters: Record<string, string[]>,
 *    filterableFields: string[]
 * }, {
 *  setFilters(filters: any): void
 *  addFilter(field: string): void
 *  removeFilter(field: string): void
 * }]}
 */
export function useVizFilters(fields, options, setOptions) {
  const filterableFields = useMemo(
    () =>
      Object.keys(fields)
        .filter((k) => ['facet', 'date'].includes(fields[k]))
        .sort(),
    [fields]
  )

  const [filters, setFilters] = useState(() =>
    (options.active_filters ?? [])
      .filter((field) => filterableFields.includes(field))
      .reduce((initialFilters, field) => {
        initialFilters[field] = []
        return initialFilters
      }, {})
  )
  const addFilter = useCallback(
    (field) => {
      setOptions((options) => ({
        ...options,
        active_filters: (options.active_filters ?? []).concat(field),
      }))
      setFilters((filters) => ({
        ...filters,
        [field]: [],
      }))
    },
    [setOptions]
  )
  const removeFilter = useCallback(
    (field) => {
      setOptions((options) => ({
        ...options,
        active_filters: (options.active_filters ?? []).filter(
          (loopField) => field !== loopField
        ),
      }))
      setFilters((filters) => omit(filters, field))
    },
    [setOptions]
  )

  return [
    { filters, filterableFields },
    { setFilters, removeFilter, addFilter },
  ]
}
