import Layout from '../../components/Layout'
import PageNotFound from '../PageNotFound'

export default function PageError({ error }) {
  if (error.status === 404) {
    return <PageNotFound />
  }
  return (
    <Layout>
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center text-danger">
          <h1>
            {error.status > 0 && `Got a server error (${error.status})`}
            {error.status === 0 && `You are offline`}
          </h1>
        </div>
      </div>
    </Layout>
  )
}
