import classNames from 'classnames'
import { format, parse } from 'date-fns'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useFieldValues } from '../../hooks/fields'
import DropdownSelect from '../DropdownSelect'
import MultiBoxSelect from '../MultiBoxSelect'

/**
 * @param {{
 *  values: string[]
 *  field: string
 *  onChange(v: string[]): void
 * }} props
 */
function MultiQueryInput({ values, field, onChange }) {
  const options = useFieldValues(field)
  if (options === null) {
    return null
  }
  return (
    <MultiBoxSelect
      mode="flat"
      options={options}
      values={values}
      onChange={onChange}
    />
  )
}

const DATE_EXPRESSION = ['equal', 'before', 'after']
const TEXT_EXPRESSION = ['equal', 'contains', 'not contains']

/**
 * @param {{
 *  field: string
 *  value: any
 *  onChange(v: any): void
 *  type: import('../../hooks/schema').OmekaFieldType
 *  expression: string | null
 *  onExpressionChange(e: string): void
 * }} props
 */
export default function QueryInput({
  field,
  value,
  onChange,
  type,
  expression,
  onExpressionChange,
}) {
  if (type === 'facet' || type === 'geo') {
    return (
      <MultiQueryInput field={field} values={value ?? []} onChange={onChange} />
    )
  }
  if (type === 'date') {
    const selectedDate = value ? parse(value, 'yyyy-MM-dd', new Date()) : null
    return (
      <div className="d-flex">
        <DropdownSelect
          className="mr-2"
          size="sm"
          variant="light"
          value={expression}
          options={DATE_EXPRESSION}
          onChange={onExpressionChange}
        />
        <DatePicker
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            if (date) {
              onChange(format(date, 'yyyy-MM-dd'))
            } else {
              onChange(null)
            }
          }}
        />
      </div>
    )
  }
  return (
    <div>
      {value.map((valueQuery, i) => (
        <div
          key={i}
          className={classNames('d-flex', {
            'mt-2': i > 0,
          })}
        >
          <DropdownSelect
            className="mr-2"
            size="sm"
            variant="light"
            value={valueQuery.expression}
            options={TEXT_EXPRESSION}
            onChange={(expression) => {
              const nextValue = [...value]
              nextValue[i] = { ...nextValue[i], expression }
              onChange(nextValue)
            }}
          />
          <input
            type="text"
            value={valueQuery.text ?? ''}
            onChange={(e) => {
              const nextValue = [...value]
              nextValue[i] = { ...nextValue[i], text: e.target.value }
              onChange(nextValue)
            }}
          />

          <Button
            disabled={i !== value.length - 1}
            variant="primary"
            size="sm"
            className="ms-1"
            onClick={() => {
              onChange(value.concat({ expression: 'equal', text: '' }))
            }}
          >
            OR
          </Button>

          <Button
            onClick={() => {
              const nextValue = [...value]
              nextValue.splice(i, 1)
              onChange(nextValue)
            }}
            className="ms-2"
            size="sm"
            variant="danger"
            disabled={value.length < 2}
          >
            <i className="bi-x-lg"></i>
          </Button>
        </div>
      ))}
    </div>
  )
}
