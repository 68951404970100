import { getIn } from 'formik'
import { Form } from 'react-bootstrap'

/**
 * @param {import("formik").FieldProps & import('react').InputHTMLAttributes & {
 *  label?: string
 * }} props
 */
export default function CheckField({ field, form, label, ...props }) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const { value, ...fieldProps } = field

  return (
    <Form.Group>
      <Form.Check
        // NOTE: Very workaround but without id this fucked up -.-"
        // onChange insnt' triggered
        id={`check__${field.name}`}
        {...props}
        {...fieldProps}
        checked={value}
        isInvalid={touch && !!error}
        type="switch"
        label={label}
      />
      {error && touch && <small className="text-danger">{error}</small>}
    </Form.Group>
  )
}
