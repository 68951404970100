import { useCallback, useMemo, useState } from 'react'
import { rj, useRj } from 'react-rocketjump'
import api from '../api'

export const QueryState = rj({
  name: 'Query',
  effectCaller: 'configured',
  effect: (t) => (query) =>
    api.auth(t).get(`/api/query`, {
      query: JSON.stringify(query),
    }),
})

export function useQueryRunner() {
  return useRj(QueryState)
}

export const TimelineDataState = rj({
  name: 'TimelineData',
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get(`/api/timeline-data`),
})

export function useTimelineDataRunner() {
  return useRj(TimelineDataState)
}

const EMPTY_DICT = {}

/**
 * @returns {[{
 *  rows: any[]|null,
 *  loading: boolean,
 *  excludeIds: Record<string, boolean>
 * }, {
 *  runQuery(query: any): void
 *  getSelectedRows(): any[] | null
 *  toggle(id: number): void
 *  untoggleAll(): void
 *  toggleAll(): void
 *  cleanQuery(): void
 *  meta: { fields: Record<string, import('./schema').OmekaFieldType> }
 *  run: any
 * }]}
 */
export function useQueryTable() {
  const [{ data, pending: loading }, { run, clean, updateData }] =
    useQueryRunner()

  const rows = data ? data.results : null
  const meta = data ? data.meta : null

  const [excludeIds, setExcludeIds] = useState(EMPTY_DICT)

  const runQuery = useMemo(
    () => run.onSuccess(() => setExcludeIds(EMPTY_DICT)).curry(),
    [run]
  )

  const toggle = useCallback(
    (id) =>
      setExcludeIds((ids) => ({
        ...ids,
        [id]: !ids[id],
      })),
    []
  )

  const untoggleAll = useCallback(
    () =>
      setExcludeIds(
        rows
          ? rows.reduce((all, r) => {
              all[r.id] = true
              return all
            }, {})
          : EMPTY_DICT
      ),
    [rows]
  )

  const toggleAll = useCallback(() => setExcludeIds(EMPTY_DICT), [])

  const cleanQuery = useCallback(() => {
    clean()
    setExcludeIds(EMPTY_DICT)
  }, [clean])

  const getSelectedRows = useCallback(() => {
    if (rows === null) {
      return null
    }
    return rows.filter((row) => !excludeIds[row.id])
  }, [excludeIds, rows])

  return [
    { rows, meta, excludeIds, loading },
    {
      runQuery,
      updateData,
      getSelectedRows,
      toggle,
      untoggleAll,
      toggleAll,
      run,
      cleanQuery,
    },
  ]
}
