import { rj, useRj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import api from '../api'
import { PAGE_SIZE } from '../consts'

export const CreateVizState = rj({
  name: 'CreateViz',
  effectCaller: 'configured',
  effect: (t) => (data) => api.auth(t).post('/api/viz', data),
})

export const VizState = rj({
  name: 'Viz',
  effectCaller: 'configured',
  mutations: {
    patchViz: rj.mutation.single({
      effect: (t) => (id, data) => api.auth(t).patch(`/api/viz/${id}`, data),
      updater: 'updateData',
    }),
    deleteViz: rj.mutation.single({
      effect: (t) => (id) => api.auth(t).delete(`/api/viz/${id}`),
      updater: (s) => s,
    }),
    refreshViz: rj.mutation.single({
      effect: (t) => (id) => api.auth(t).post(`/api/viz/${id}/refresh`),
      updater: 'updateData',
    }),
  },
  effect: (t) => (id) => api.auth(t).get(`/api/viz/${id}`),
  computed: {
    data: 'getData',
    error: 'getError',
    loading: 'isPending',
    updating: (s) => s.mutations.patchViz.pending,
    refreshing: (s) => s.mutations.refreshViz.pending,
    deleting: (s) => s.mutations.deleteViz.pending,
  },
})

export function useCreateViz() {
  return useRj(CreateVizState)[1].run
}

export function useViz(id) {
  return useRunRj(VizState, [id])
}

export const VizListState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    name: 'VizList',
    effectCaller: 'configured',
    mutations: {
      duplicateViz: {
        effect: (t) => (id) => api.auth(t).post(`/api/viz/${id}/duplicate/`),
        updater: (s) => s,
      },
      makePreset: {
        effect: (t) => (id) =>
          api.auth(t).patch(`/api/viz/${id}/`, {
            is_preset: true,
          }),
        updater: 'deleteItem',
      },
      deleteViz: {
        effect: (t) => (id) =>
          api
            .auth(t)
            .mapResponse(() => ({ id }))
            .delete(`/api/viz/${id}/`),
        updater: 'deleteItem',
      },
    },
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/viz/', params),
    computed: {
      error: 'getError',
      list: 'getList',
      pagination: 'getPagination',
    },
  }
)

export function useVizList(params) {
  return useRunRj(VizListState, [params], false)
}

export const VizPresetsListState = rj(rjPlainList(), {
  name: 'VizPresetsList',
  effectCaller: 'configured',
  effect: (t) => () => api.auth(t).get('/api/viz/presets/'),
  mutations: {
    removeFromPreset: {
      effect: (t) => (id) =>
        api.auth(t).patch(`/api/viz/${id}/`, {
          is_preset: false,
        }),
      updater: 'deleteItem',
    },
    createFromPreset: {
      effect: (t) => (id) => api.auth(t).post(`/api/viz/${id}/from-preset/`),
      updater: (a) => a,
    },
  },
})

export function useVizPresetsList() {
  return useRunRj(VizPresetsListState, [])
}
