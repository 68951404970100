import Layout from '../../components/Layout'

export default function PageNotFound() {
  return (
    <Layout>
      <div className="d-flex justify-content-center mt-4">
        <div className="text-center text-primary">
          <h1>
            4 0 4 <br /> Page Not Found
          </h1>
        </div>
      </div>
    </Layout>
  )
}
